import React from "react"
import {
  Card,
  IconButton,
  CardHeader,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core"
import {
  Edit,
  Slash,
  CheckCircleIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  TextFieldIcon,
  SubjectIcon,
  BorderAllIcon,
  ArrowDropDownIcon,
  ArrowDropDownCircleIcon,
} from "@iconsGallery"
import * as Utils from "@utils"
import * as Adm from "@adm"
import styled from "styled-components"

const StyledTextField = styled(TextField)`
  &.disabled {
    input {
      background: #e4e4e4;
    }
  }
`
const HeaderRightWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`
const ContainerWrapper = styled(Grid)`
  display: flex;
  min-height: 200px;
`
const EmptyWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const CardHeaderWrapper = styled(CardHeader)`
  display: flex;
  border-bottom: 1px solid #aeaeae;
  .base-dropdown-menu {
    margin-right: 12px;
  }
  .MuiCardHeader-action {
    display: flex;
    align-items: center;
  }
`

const customRenderHeader = (props) => {
  const {
    theme,
    classes,
    edit,
    setEdit,
    collapseOpen,
    setCollapseOpen,
    expanded,
    setExpanded,
    handleExpandClick,
    updateData = () => {},
    resetData = () => "",
    isEditMode = true,
    label = "",
  } = props || {}

  const dropdownMenuAttr = {
    buttonText: "INSERT",
    buttonIcon: ArrowDropDownIcon,
    list: ["Attribute", "Text Editor", "Table", "Dropdown"],
    iconList: [
      TextFieldIcon,
      SubjectIcon,
      BorderAllIcon,
      ArrowDropDownCircleIcon,
    ],
  }
  return (
    <CardHeaderWrapper
      className={`${
        expanded === false ? classes.dividerBottom : ""
      } base-header-card`}
      title={
        <Typography gutterBottom variant="h5" component="h2">
          {props.edit ? (
            label
          ) : (
            <StyledTextField
              variant="outlined"
              size="small"
              style={{ width: "300px" }}
              defaultValue={label}
            />
          )}
        </Typography>
      }
      action={
        <>
          {props.edit ? (
            <>
              {expanded === true && isEditMode === true && (
                <IconButton
                  onClick={() => {
                    setEdit(false)
                  }}
                  style={{ marginRight: "13px" }}
                >
                  <Edit className={classes.basicIconProperty} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {expanded === true ? (
                <HeaderRightWrapper>
                  <Adm.DropdownMenu {...dropdownMenuAttr} />
                  <IconButton
                    onClick={() => {
                      resetData()
                      setEdit(true)
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    <Slash
                      style={{ color: "red" }}
                      className={classes.basicIconProperty}
                    />
                  </IconButton>
                  <IconButton
                    style={{ marginRight: "18px" }}
                    onClick={() => {
                      updateData(props)
                      setEdit(true)
                    }}
                  >
                    <CheckCircleIcon style={{ color: "#4CAF50" }} />
                  </IconButton>
                </HeaderRightWrapper>
              ) : (
                ""
              )}
            </>
          )}
          {expanded === true ? (
            <IconButton onClick={handleExpandClick}>
              <ExpandLessIcon className={classes.iconUpDown} />
            </IconButton>
          ) : (
            <IconButton onClick={handleExpandClick}>
              <ExpandMoreIcon className={classes.iconUpDown} />
            </IconButton>
          )}
        </>
      }
    ></CardHeaderWrapper>
  )
}

const customRenderContainer = (props) => {
  const { group } = props || {}
  return (
    <ContainerWrapper>
      {!Utils.JSUtils.isEmpty(group) &&
        group.map((layerItem, index) => {
          return <>{index}</>
        })}
      {Utils.JSUtils.isEmpty(group) && (
        <EmptyWrapper>
          <span>EMPTY</span>
        </EmptyWrapper>
      )}
    </ContainerWrapper>
  )
}

function View(props) {
  const { item } = props || {}
  const { title: label = "", group = [] } = item || {}

  const accordionAttr = {
    label,
    isEditView: false,
    isOpen: true,
    renderHeader: customRenderHeader,
    renderContainer: (containerProps) =>
      customRenderContainer({ ...containerProps, group }),
  }
  return <Adm.AccordianPanel {...accordionAttr} />
}

export { View }
export default View
