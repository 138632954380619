import React from "react"
import {
  Box,
  Card,
  Grid,
  Divider,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core"
import {
  Typography,
  CardContent,
  GreenText,
  RedText,
  useStyles,
  BlackText,
  ColoredLine,
  StyledMainWrapperCardView,
} from "./styles"
import _ from "lodash"
import ImageIcon from "@material-ui/icons/Image"

function getRandomColor() {
  return (
    "rgb(" +
    (Math.floor(Math.random() * 40) + 190) +
    ", " +
    (Math.floor(Math.random() * 40) + 190) +
    ", " +
    (Math.floor(Math.random() * 40) + 190) +
    ")"
  )
}

function CardView(props) {
  const classes = useStyles()
  const { list = {}, handleListView = () => "", index = 0 } = props
  const { image = "https://via.placeholder.com/68.png?text=placeholder" } = list
  return (
    <StyledMainWrapperCardView
      className={classes.cardOuter}
      onClick={() => {
        handleListView(list)
      }}
    >
      <Grid className={classes.cls_sub_container}>
        <Grid
          container
          style={{ marginBottom: 10, display: "flex", flexDirection: "row" }}
        >
          <Grid item>
            <>
              {image.includes("placeholder") ? (
                <Grid
                  style={{
                    display: "flex",
                    minWidth: 68,
                    minHeight: 68,
                    border: "1px solid #FFF",
                    borderRadius: 4,
                    overflow: "hidden",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 30,
                    textTransform: "uppercase",
                    backgroundColor: getRandomColor(),
                    color: "#000",
                  }}
                >
                  {list.title.length > 0 ? <span>{list.title[0]}</span> : ""}
                </Grid>
              ) : (
                <div style={{ width: "68px", height: "68px", overflow: "hidden", display: "flex", alignItems: "center" }}>
                  {list?.image.length > 0 && (
                    <img
                      alt={image}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        width: '100%',
                        border: "1px solid #E0E0E0",
                        borderRadius: 4,
                        overflow: "hidden",
                      }}
                      src={image}
                    />
                  )}
                </div>
              )}
            </>
          </Grid>
          <Grid item xs={8} md={8} lg={8} style={{ marginLeft: 20 }}>
            <Typography
              variant="h4"
              style={{
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 800,
                fontSize: 16,
              }}
            >
              {list.title}
            </Typography>
            <Typography
              style={{ fontWeight: 500, fontSize: 14 }}
              variant="body1"
            >
              {list.address}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ borderTop: "1px solid #E0E0E0", paddingTop: 8 }}
        >
          <span
            className={
              list.status === "Active" ? classes.active : classes.inactive
            }
          ></span>
          {list.status}
        </Grid>
      </Grid>
    </StyledMainWrapperCardView>
  )
}
export default CardView
export { CardView }
