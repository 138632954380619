import * as Yup from "yup"
import * as React from "react"
import * as Adm from "@adm"
import * as Features from "@features"
import * as Utils from "@utils"

export const defaultformInput = [
  {
    label: "Name",
    defaultValue: "",
    inputType: "text",
    identifer: "firstName",
    icon: "Settings",
    validationSchema: Yup.string().required("in valid"),
  },
  {
    label: "dropdown label",
    defaultValue: "",
    placeholder: "Select dropdown",
    list: ["Mr.", "Mrs.", "Ms."],
    inputType: "dropdown",
    icon: "Settings",
    identifer: "dropdownid",
    validationSchema: Yup.string().required("in valid"),
  },
  {
    label: "number",
    defaultValue: "",
    inputType: "number",
    identifer: "numberid",
    icon: "Settings",
    validationSchema: Yup.string().required(`Zip Code 5 is required`),
  },

  {
    label: "fruits label",
    defaultValue: "",
    list: ["apple", "orange", "tomato"],
    inputType: "dropdown",
    identifer: "fruitsid",
  },
  {
    label: "Billing",
    defaultChecked: true,
    inputType: "checkBox",
    identifer: "billing",
  },
  {
    label: "Shipping",
    defaultChecked: false,
    inputType: "checkBox",
    identifer: "shipping",
  },
  {
    label: "fieldset title",
    inputType: "fieldset",
    identifer: "fieldsetid",
    children: [
      {
        label: "Quantity",
        defaultValue: 9,
        inputType: "quantity",
        identifer: "quantity",
        infoMessage:
          "Quantity, which will need to be met before the promotion gets triggered or executed",
      },
      {
        label: "marketing",
        defaultValue: true,
        inputType: "toggle",
        renderTextTrue: "Yes",
        renderTextFalse: "No",
        identifer: "marketing",
      },
      {
        label: "Discount",
        defaultValue: {
          value: "11",
          type: Utils?.JSUtils?.getLSCurrencyCode().currency_symbol || "$",
        },
        inputType: "tabtextfield",
        list: [Utils?.JSUtils?.getLSCurrencyCode().currency_symbol || "$", "%"],
        identifer: "discount",
      },
      {
        label: "discount dropdown",
        defaultValue: "11",
        activeValue: Utils?.JSUtils?.getLSCurrencyCode().currency_symbol || "$",
        inputType: "textFieldDropdown",
        list: [Utils?.JSUtils?.getLSCurrencyCode().currency_symbol || "$", "%"],
        identifer: "discountdropdown",
      },
      {
        label: "calender set",
        defaultValue: { date: "2021-05-30", time: "08:00" },
        inputType: "calender",
        identifer: "calender",
      },
      {
        label: "Tab Dropdown",
        defaultValue: {
          tabName: "non veg",
          list: ["chicken", "mutton", "beef"],
          value: "mutton",
        },
        list: [
          {
            tabName: "fruits",
            list: ["Apple", "Orange", "Grapes"],
            value: "Apple",
          },
          {
            tabName: "veg",
            list: ["Cabbage", "Tomato", "Onion"],
            value: "Cabbage",
          },
          {
            tabName: "non veg",
            list: ["chicken", "mutton", "beef"],
            value: "mutton",
          },
        ],
        inputType: "tabDropdown",
        identifer: "tabDropdownTest",
      },
      {
        label: "supername",
        defaultValue: "SK",
        inputType: "text",
        identifer: "supername",
        validationSchema: Yup.string().required("in valid"),
      },
      {
        label: "supername1",
        defaultValue: "SK",
        inputType: "text",
        identifer: "supername1",
        validationSchema: Yup.string().required("in valid"),
      },
    ],
  },
  {
    label: "dynamic",
    defaultValue: [
      { id: "73649846", title: "monkey" },
      { id: "23239846", title: "donkey" },
      { id: "23424234", title: "anty" },
      { id: "73649846", title: "test" },
    ],
    renderDynamic: ({ fieldItem, formUIProps }) => {
      const {
        defaultValue,
        defaultChecked = false,
        identifer,
        inputType,
        label,
        placeholder,
        children = [],
        list = [],
      } = fieldItem
      const customOnSearchUpdate = (props) => {}
      return (
        <Adm.SearchListUI
          list={defaultValue}
          onSearchUpdate={customOnSearchUpdate}
        />
      )
    },
    inputType: "dynamic",
    identifer: "dynamic1",
  },
  {
    label: "dynamic",
    defaultValue: [
      { id: "73649846", title: "monkey", family: "mammal" },
      { id: "23239846", title: "donkey", family: "mammal" },
      { id: "23424234", title: "anty", family: "insect" },
      { id: "736498435", title: "test", family: "test" },
    ],
    renderDynamic: ({ fieldItem, formUIProps }) => {
      const {
        defaultValue,
        defaultChecked = false,
        identifer,
        inputType,
        label,
        placeholder,
        children = [],
        list = [],
      } = fieldItem
      return (
        <Features.MultiSearchListForm
          list={defaultValue}
          buttonLabel="ADD ANIMALS"
          edit={true}
          idName="id"
          displayTabs={["id", "title"]}
          tableHeadings={["Animal ID", "Animal Name"]}
          tabs={[
            { name: "Name", attr: "title" },
            { name: "Family", attr: "family" },
          ]}
        />
      )
    },
    inputType: "dynamic",
    identifer: "dynamic2",
  },
]
