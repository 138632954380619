import React, { useEffect, useState, useContext, useRef } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import {
  IconButton,
  Grid,
  Card,
  Typography,
  Divider,
  Tooltip,
  Checkbox,
  InputAdornment,
} from "@material-ui/core"

import {
  CheckCircleIcon,
  Hash,
  Phone,
  MapPin,
  Mail,
  Edit,
  Slash,
  PercentageIcon,
  UserIcon,
  StoreIcon,
  PlaceIcon,
} from "@iconsGallery"
import * as Utils from "@utils"
import _, { functions, isUndefined, keys } from "lodash"
import * as ServiceAPI from "@services"
import * as Features from "@features"
import { OperatingHours } from "@features"
import * as Adm from "@adm"
import { useTranslation } from "react-i18next"
import * as constantsAPI from "@constants"
import { ConfirmationDialog, EditLocationHierarchy } from "@features"
import { InfoOutlined } from "@material-ui/icons"
import userApi from "../../services/user/api"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { object } from "@storybook/addon-knobs"
import { operatingHrs } from "./fixture"
import { DashboardContext } from "@utils"
import { useStyles, FormMainWrapper, TextField } from "./styles"

const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

function LocationAddress(props) {
  const {
    data = {},
    setAllowPrompt = () => { },
    isWriteAllowed = false,
    isTablet = false,
    locationType = {},
    setLocationType = () => { },
    storageUnitList = [],
    survCameraList = [],
    locationTypeList = [],
    timeZoneList = [],
    paymentType = {},
    paymentGatewayList = [],
    accountType = {},
    storeId = "",
    setData = [],
    isView = "create",
  } = props

  let copyPaymentType = paymentType
  const classes = useStyles()
  const { locationId } = data || {}
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const { t } = useTranslation()
  const [isAnyChanges, setAnyChanges] = React.useState(false)
  const [countryOpen, setCountryOpen] = React.useState(false)
  const [stateOpen, setStateOpen] = React.useState(false)
  const [isPhoneField, setPhoneField] = useState({ phoneCountryCode: "" })
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [updateData, setUpdateData] = useState({})
  const [selectedSurvCamera, setSelectedSurvCamera] = useState("")
  const [isCameraChanged, setIsCameraChanged] = useState(false)
  const [locationTypeError, setLocationTypeError] = useState(false)
  const [paymentTypeError, setPaymentTypeError] = useState(false)
  const [accountTypeError, setAccountTypeError] = useState(false)
  const [locationSizeError, setLocationSizeError] = useState(false)
  const [cameraOpen, setCameraOpen] = useState(false)
  const [storageOpen, setStorageOpen] = useState(false)

  const [getStorageAddress, setStorageAddress] = useState({})

  let history = useHistory()

  const userProfileDetails =
    useSelector((state) => state.adminReducer.userProfileDetails) || {}

  const [closeDateError, setCloseDateError] = useState(false)

  const [daysArrState, setDaysArrState] = useState([])
  const [daysArrError, setDaysArrError] = useState([])
  const [timeArrState, setTimeArrState] = useState([])
  const [daysError, setDaysError] = useState([])
  const { countryList, setCountryList } = useContext(DashboardContext)
  const [getUserCityList, setUserCityList] = useState([])
  const [userCityOpen, setUserCityOpen] = useState(false)


  const [getSelectedCamera, setSelectedCamera] = useState("")
  let defaultCountry = "USA"
  let defaultCountryCode = "US"

  useEffect(() => {
    if (isView === "create") {
      setEdit(true)
    } else {
      setEdit(false)
    }
  }, [locationId])

  useEffect(() => {
    if (data) {
      let zipCode = data?.locationAddress?.postalcode
      let countryCode = data?.locationAddress?.country_code
      fetchCityList(zipCode, countryCode)
    }
  }, [data])

  const fetchCityList = (zipCode, countryCode) => {
    zipCode &&
      countryCode &&
      ServiceAPI.fetchZipcodeDetails(zipCode, countryCode)
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            const cityList = response.data.map((option) => {
              return { id: option.city, name: option.city }
            })
            setUserCityList(cityList)
          } else {
            setUserCityList([])
          }
        })
        .catch((error) => {
          setUserCityList([])
        })
  }

  const onGlobalUpdate = () => {
    setAnyChanges(true)
  }

  const updateLocationDetails = async (body) => {
    setAlert(false)
    let response = await ServiceAPI.fetchStoreCommonData(
      locationId?.length > 0
        ? API_END_POINTS.updateLocation
        : API_END_POINTS.addLocation,
      body
    )
    if (response?.data?.status === "success") {
      await setAlertType(_.get(response, "data.status", "error"))
      await setAlertMessage(_.get(response, "data.message", "Try Again Later"))
      setEdit(false)
      await setAlert(true)
      await setLoading(false)
      setAllowPrompt(false)
      setTimeout(() => {
        history.push(`/${userProfileDetails?.roleType}/master-data/locations`)
      }, 2000)
    } else {
      setLoading(false)
      await setAlert(true)
      await setAlertType("error")
      await setAlertMessage(response?.response?.data?.message)
    }
  }

  /**
   * @Get_Business_Hours_data
   */
  const getBusinessHrs = (data) => {
    let errData = { errFrom: "", errTo: "" }
    if (_.isEmpty(data)) {
      return operatingHrs
    } else {
      return _.forEach(data.business_hrs, function (item) {
        if (item.times.length > 0) {
          let errorItem = []
          _.forEach(item.times, function (DD) {
            errorItem.push(errData)
          })
          return (item.errorsArr = errorItem)
        } else {
          return (item.errorsArr = [])
        }
      })
    }
  }

  let basicInitialValues = {
    location: _.get(data, "locationId", ""),
    locationName: _.get(data, "locationName", ""),
    phoneNumber:
      _.get(data, "phoneNumber.countryCode", "") +
      (_.get(data, "phoneNumber.number", "") === 0
        ? ""
        : _.get(data, "phoneNumber.number", "")),
    emailAddress: _.get(data, "locationSupportEmail", ""),
    locationManager: _.get(data, "locationManager", ""),
    assistantManager: _.get(data, "assistantManager", ""),
    cvThreshold: _.get(data, "cv_least_threshold", ""),
    maxStoreOccupancy: _.get(data, "max_occupancy_limit_of_store", ""),
    surveillanceCamera: _.get(data, "surveillance_camera", ""),
    line1: _.get(data, "locationAddress.line1", ""),
    line2: _.get(data, "locationAddress.line2", ""),
    postalcode: _.get(data, "locationAddress.postalcode", ""),
    state: _.get(data, "locationAddress.region", ""),
    country: _.get(
      data,
      "locationAddress.country",
      JSON.parse(localStorage.getItem("tenantAddress"))?.country || "US"
    ),
    addressCountryCode: _.get(
      data,
      "locationAddress.country_code",
      JSON.parse(localStorage.getItem("tenantAddress"))?.country_code || "US"
    ),
    city: _.get(data, "locationAddress.city", ""),
    countryCode: _.get(data, "phoneNumber.countryCode", ""),
    latitude: _.get(data, "locationGeoPoint.coordinates[1]", ""),
    longitude: _.get(data, "locationGeoPoint.coordinates[0]", ""),
    returnPolicy: _.get(data, "returnPolicy", ""),
    locationType: _.get(data, "locationType", ""),
    locationSize: _.get(data, "locationSize", {}),
    locationSizeLength: _.get(data, "locationSize.lengthValue", ""),
    locationSizeWidth: _.get(data, "locationSize.widthValue", ""),
    locationSizeHeight: _.get(data, "locationSize.heightValue", ""),
    storageUnitId: _.get(data, "storageunit_ids[0]", ""),
    isStorageunitAddress: _.get(data, "isStorageunitAddress", false),
    isActive: _.get(data, "isActive", true),
    openDate: _.get(data, "openDate", ""),
    closeDate: _.get(data, "closeDate", ""),
    locationTimezone: _.get(data, "locationTimezone", ""),
    business_hrs: getBusinessHrs(data),
  }

  let initialValues = { ...basicInitialValues }

  if (localStorage.getItem("tenantPaymentConfigEnable") === "true") {
    let paymentInitialValues = {
      paymentAccountName: !_.isEmpty(data)
        ? {
          id: _.get(data, "paymentAccountName", ""),
          name: _.get(data, "paymentAccountName", ""),
          payment_provider: _.get(data, "payment_provider", ""),
          mode: _.get(data, "paymentMode", ""),
        }
        : "",
      preAuthAmountRequired: !_.isEmpty(data?.pre_auth_amount),
      order_expiry_in_days: _.get(data, "order_expiry_in_days", ""),
      refund_window_in_days: _.get(data, "refund_window_in_days", ""),
    }

    if (paymentInitialValues?.preAuthAmountRequired) {
      paymentInitialValues["preAuthAmount"] = _.get(data, "pre_auth_amount", "")
    }

    initialValues = { ...initialValues, ...paymentInitialValues }
  }

  if (basicInitialValues.addressCountryCode === "IN") {
    initialValues["GSTNumber"] = _.get(data, "gst_number", "")
  }

  let mandatoryValidation = {
    location: Yup.string()
      .required("Enter Store Number")
      .matches("^[A-Za-z0-9_@./#+-]*$", "Enter valid Store Number")
      .min(1, "Store Number can be of min-1 max-255 characters")
      .max(255, "Store Number can be of min-1 max-255 characters")
      .strict(true),
    latitude: Yup.number()
      .min(-90, "latitude can be min(-90) max(90) value")
      .max(90, "latitude can be min(-90) max(90) value"),
    longitude: Yup.number()
      .min(-180, "longitude can be min(-180) max(180) value")
      .max(180, "longitude can be min(-180) max(180) value"),
    locationName: Yup.string()
      .required(`Enter Store Name`)
      .matches("^[a-zA-Z0-9_-\\s]+$", "Enter valid Store Name")
      .min(1, "Store Name can be of min-1 max-255 characters")
      .max(255, "Store Name can be of min-1 max-255 characters")
      .strict(true),
    phoneNumber: Yup.string()
      .required("Select phoneNumber")
      .min(10)
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      ),
    locationManager: Yup.string()
      .required(`Enter Store Manager Name`)
      .min(1, "Store Manager Name can be of min-1 max-255 characters")
      .max(255, "Store Manager Name can be of min-1 max-255 characters")
      .matches(
        /^[aA-zZ\s]+$/,
        "Only alphabets are allowed for Store Manager Name"
      )
      .strict(true),
    assistantManager: Yup.string()
      .min(1, "Assistant Manager Name can be of min-1 max-255 characters")
      .max(255, "Assistant Manager Name can be of min-1 max-255 characters")
      .matches(
        /^[aA-zZ\s]+$/,
        "Only alphabets are allowed for Assistant Manager"
      )
      .strict(true),
    emailAddress: Yup.string()
      .email("Enter valid Email")
      .min(1, "Email can be of min-1 max-255 characters")
      .max(255, "Email can be of min-1 max-255 characters")
      .required("Enter Email Id")
      .strict(true),
    cvThreshold: Yup.number()
      .required("Enter CV Threshold Number")
      .integer("Enter a valid integer")
      .min(1, "Value must be between 1 and 100")
      .max(100, "Value must be between 1 and 100"),
    maxStoreOccupancy: Yup.number()
      .required("Enter Max Store Occupancy Number")
      .integer("Enter a valid integer")
      .min(1, "Value must be between 1 and 100")
      .max(100, "Value must be between 1 and 100"),
    surveillanceCamera: Yup.object().required(`Survillence Camera is required`),
    line1: Yup.string()
      .required(`Enter Address line 1`)
      .min(1, "Address line 1 can be of min-1 max-255 characters")
      .max(255, "Address line 1 can be of min-1 max-255 characters")
      .strict(true),
    state: Yup.string().required(`Select State from Zipcode`).strict(true),
    city: Yup.string().required(`Select City from Zipcode`).strict(true),
    postalcode: Yup.string()
      .required(`Enter Zipcode`)
      .matches("^[0-9]+$", "Enter valid Zipcode")
      .min(4, "Zipcode can be of min-5 max-10 characters")
      .max(10, "Zipcode can be of min-5 max-10 characters")
      .strict(true),
    returnPolicy: Yup.string()
      .required(`Enter Return Policy`)
      .min(1, "Return Policy can be of min-1 max-200 characters")
      .max(200, "Return Policy can be of min-1 max-200 characters")
      .strict(true),
    locationSizeLength: Yup.string().required("Enter Length"),
    locationSizeWidth: Yup.string().required("Enter Width"),
    locationSizeHeight: Yup.string().required("Enter Height"),
    openDate: Yup.string().required("Select Open Date"),
    locationTimezone: Yup.string().required("Select Time Zone"),
    business_hrs: Yup.array()
      .test("Required", "Atleast Add one date", function (item) {
        let dateFlag = true
        let data = _.filter(item, "selected")
        if (data.length === 0) {
          dateFlag = false
        }
        return dateFlag
      })
      .test("", "", function (item) {
        let dateFlag = true
        _.forEach(item, function (dataItem) {
          dataItem.errorsArr.length > 0 &&
            _.forEach(dataItem.errorsArr, function (errItem) {
              if (
                errItem.errFrom !== undefined &&
                errItem.errFrom !== "" &&
                errItem.errFrom !== undefined &&
                errItem.errFrom !== ""
              ) {
                dateFlag = false
              }
            })
        })
        return dateFlag
      }),
  }

  let paymentValidation = {}
  if (localStorage.getItem("tenantPaymentConfigEnable") === "true") {
    paymentValidation = {
      paymentAccountName: Yup.object().required("Select Account Name"),
      preAuthAmount: Yup.number().when(
        "paymentAccountName.is_pre_auth_available",
        {
          is: true,
          then: Yup.number()
            .required("Enter PreAuth Amount")
            .integer("Enter a valid integer")
            .min(1, "Value must be between 1 and 100")
            .max(100, "Value must be between 1 and 100"),
          otherwise: Yup.number().notRequired(),
        }
      ),
    }
  }

  if (basicInitialValues.addressCountryCode === "IN") {
    mandatoryValidation["GSTNumber"] = Yup.string()
      .required("Enter GST-Number")
      .matches(/^[A-Z0-9]+$/, "Enter capital letters and numbers only")
      .min(1, "GST-Number must be at least 1 character long")
      .max(10, "GST-Number must be at most 10 characters long")
  }

  let validationSchema = { ...mandatoryValidation, ...paymentValidation }

  return (
    <div>
      <Card
        style={{
          width: "100%",
          boxShadow:
            "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <Adm.BackdropOverlay open={loading} />

        <>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={async (values, { resetForm }) => {
              setAlert(false)
              setLoading(true)
              let phoneNumberOnly = values.phoneNumber.slice(
                values.countryCode.length
              )
              phoneNumberOnly = phoneNumberOnly === "" ? "" : phoneNumberOnly

              let countryCodeOnly =
                phoneNumberOnly === "" ? "" : values.countryCode

              let newArr = []
              _.forEach(values.business_hrs, function (dd) {
                let GGData = {
                  day: dd.day,
                  selected: dd.selected,
                  times: dd.times,
                }
                newArr.push(GGData)
              })
              let body = {
                locationId: values.location,
                locationName: _.trim(values.locationName),
                locationManager: _.trim(values.locationManager),
                assistantManager: _.trim(values.assistantManager),
                locationSupportEmail: values.emailAddress,
                phoneNumber: {
                  countryCode: countryCodeOnly,
                  number: phoneNumberOnly,
                },
                returnPolicy: _.trim(values.returnPolicy),
                locationHierarchy: "",
                locationSize: {
                  lengthValue: values?.locationSizeLength || 0,
                  widthValue: values?.locationSizeWidth || 0,
                  heightValue: values?.locationSizeHeight || 0,
                },
                locationAddress: {
                  line1: _.trim(values.line1),
                  line2: _.trim(values.line2),
                  postalcode: values.postalcode,
                  city: values.city.trim(),
                  region: values.state,
                  country: values.country,
                  country_code: values?.addressCountryCode,
                },
                locationGeoPoint: {
                  type: "Point",
                  coordinates: [
                    parseFloat(values.longitude) || 0,
                    parseFloat(values.latitude) || 0,
                  ],
                },
                locationType: {
                  id: locationTypeList[1]?.id,
                  name: locationTypeList[1]?.name,
                  code: locationTypeList[1]?.code,
                },
                storeType: {
                  id: "AS",
                  name: "Autonomous Store",
                },

                storageunit_ids:
                  locationId?.length > 0
                    ? undefined
                    : values?.storageUnitId?.length > 0
                      ? [values?.storageUnitId]
                      : [],
                cv_least_threshold: values?.cvThreshold,
                max_occupancy_limit_of_store: values?.maxStoreOccupancy,
                surveillance_camera: values?.surveillanceCamera,
                openDate: values?.openDate,
                closeDate: values?.closeDate ? values?.closeDate : "",
                locationTimezone: values?.locationTimezone,
                business_hrs: newArr,
                isAddressVerified: false,
                isActive: values?.isActive,
              }
              let paymentPayLoad = {}
              if (
                localStorage.getItem("tenantPaymentConfigEnable") === "true"
              ) {
                paymentPayLoad = {
                  paymentAccountName: values?.paymentAccountName?.name,
                  payment_provider:
                    values?.paymentAccountName?.payment_provider,
                  paymentMode: values?.paymentAccountName?.mode,
                  order_expiry_in_days: values?.order_expiry_in_days,
                  refund_window_in_days: values?.refund_window_in_days,
                }
                if (values?.country === "IN") {
                  paymentPayLoad["gst_number"] = values?.GSTNumber
                }
                if (values?.paymentAccountName?.is_pre_auth_available) {
                  paymentPayLoad["pre_auth_amount"] = values?.preAuthAmount
                }
              }

              if (values?.addressCountryCode === "IN") {
                paymentPayLoad["gst_number"] = values?.GSTNumber
              }

              delete body["storageUnitId"]
              delete body["alcoholTradingHrs"]
              delete body["businessHrs"]
              delete body["paymentMethodType"]

              await setLoading(true)
              setOpenConfirmationDialog(false)
              let payLoad = { ...body, ...paymentPayLoad }

              updateLocationDetails(payLoad)
            }}
          >
            {({
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
              setFieldError,
            }) => {
              const displayPhoneNumber =
                values.phoneNumber.slice(values.countryCode.length).length === 0
                  ? "-"
                  : values?.phoneNumber
              const handleKeyPress = (e, type) => {
                if (type === "number") {
                  if (e.which === 32) {
                    e.preventDefault()
                  }
                } else {
                  if (e.which === 32 && e.target.value === "") {
                    e.preventDefault()
                  }
                }
              }

              const handleCountry = (e, value) => {
                if (value !== null) {
                  setFieldValue("state", "")
                  onGlobalUpdate()
                } else {
                  setFieldValue("state", "")
                }
              }

              const handleState = (e, value) => {
                if (value !== null) {
                  setAllowPrompt(true)
                }
                setFieldValue("state", value)
                onGlobalUpdate()
              }

              const countryInputChange = () => {
                setFieldValue(
                  "country",
                  values?.country === ""
                    ? props?.defaultCountry
                    : values?.country
                )
              }

              const ZipCodeOnVerifyCB = (args) => {
                const {
                  status,
                  zipcode,
                  state,
                  cityList,
                  byDefaultValue,
                } = args

                setFieldValue("postalcode", zipcode)

                if (byDefaultValue) {
                  setUserCityList(cityList)
                } else {
                  if (status === "success") {
                    setUserCityList(cityList)
                    setFieldValue("state", state, "")
                    let [city] = cityList
                    setFieldValue("city", city, "")
                    setFieldError("state", "")
                    setFieldError("city", "")
                  } else {
                    setFieldValue("city", "")
                    setFieldValue("state", "")
                  }
                }
              }

              const ZipCodeOnChangeCB = ({ zipcode, byDefaultValue }) => {
                if (!byDefaultValue) {
                  setAnyChanges(true)
                  setFieldValue("city", "")
                  setFieldValue("state", "")
                  setUserCityList([])
                  setFieldValue("postalcode", zipcode)
                }
              }

              const handleDateChange = (date, type) => {
                type === "openDate"
                  ? setFieldValue("openDate", date)
                  : setFieldValue("closeDate", date)
              }

              const handleTimeZoneChange = (e, value) => {
                setFieldValue("locationTimezone", value)
              }

              const stateInputChange = () => {
                setFieldValue(
                  "state",
                  values?.state === "" ? props?.defaultState : values?.state
                )
              }

              const onChangeZipcode = async (props) => {
                setLoading(false)
                var zipCode = props?.target?.value
                let countryName = values?.country || defaultCountry
                const [_list] = countryList.filter(
                  (itm) => itm?.name === countryName
                )
                if (zipCode?.length >= 5) {
                  await setLoading(true)
                  let response = await ServiceAPI.fetchZipcodeDetails(
                    zipCode,
                    _list?.id
                  )
                  if (response?.data && response?.data.length > 0) {
                    const cityList =
                      response?.data?.map((option) => {
                        return { id: option?.city, name: option?.city }
                      }) || []
                    setUserCityList(cityList)
                    setFieldValue("city", _.get(response, "data[0].city", ""))
                    setFieldValue("state", _.get(response, "data[0].state", ""))
                  } else {
                    setFieldValue("city", "")
                    setFieldValue("state", "")
                    setUserCityList([])
                  }
                  await setLoading(false)
                } else {
                  setFieldValue("city", "")
                  setFieldValue("state", "")
                  setUserCityList([])
                }
              }

              const fetchStorageUnitById = async (storeageId) => {
                await setLoading(true)
                let response = await userApi.getStorageUnitById(`${storeageId}`)
                if (response?.status === 200) {
                  await setLoading(false)
                }
              }

              const handleCustomCloseRedirect = () => {
                setOpenConfirmationDialog(false)
                setFieldValue("surveillanceCamera", getSelectedCamera)
                setAnyChanges(true)
              }

              return (
                <>
                  <ConfirmationDialog
                    openConfirmationDialog={openConfirmationDialog}
                    setOpenConfirmationDialog={setOpenConfirmationDialog}
                    handleCustomCloseRedirect={handleCustomCloseRedirect}
                    popupTitle="Are you Sure?"
                    deleteContent={
                      getSelectedCamera?.name !== "None"
                        ? <span><h3>You are about to change the surveillance camera for this store</h3> Existing camera configuration will remain same,<br /> You will need to set up new configurations</span>
                        : <span> <h3>You are about to remove the surveillance camera for this store</h3> Existing camera configuration will remain the same</span>
                    }
                  />
                  <FormMainWrapper
                    onSubmit={handleSubmit}
                    style={{ paddingBottom: "10px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "30px",
                        position: "relative",
                        marginBottom: "20px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: "20px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          paddingLeft: "10px",
                          fontWeight: "700",
                          fontSize: "15px",
                        }}
                      >
                        <IconButton>
                          <StoreIcon />
                        </IconButton>
                        {`STORE INFORMATION`}
                      </Typography>
                      {edit ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="Cancel" placement="top">
                            <IconButton
                              onClick={() => {
                                storeId && setEdit(false)
                                setAnyChanges(false)
                                resetForm()
                                setAllowPrompt(false)
                              }}
                              style={{ padding: "9px" }}
                            >
                              <Slash style={{ height: "20px", color: "red" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Save" placement="top">
                            <IconButton
                              type="submit"
                              onClick={() => {
                                setAnyChanges(false)
                              }}
                              style={{
                                padding: "9px",
                                pointerEvents: `${isAnyChanges ? "unset" : "none"
                                  }`,
                              }}
                            >
                              <CheckCircleIcon
                                style={{
                                  color: `${isAnyChanges ? "#4CAF50" : "#CCC"}`,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            disabled={isWriteAllowed === true ? false : true}
                            aria-label="delete"
                            onClick={() => {
                              setEdit(true)
                            }}
                            size="small"
                            style={{
                              padding: "9px",
                            }}
                          >
                            <Edit style={{ height: "20px" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>

                    <>
                      <Grid
                        container
                        direction="row"
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        xl={12}
                      >
                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className={classes.divider}
                        >
                          <Typography
                            variant="h5"
                            className={classes.textHeader1}
                          >
                            Store Number{" "}
                            <span style={{ color: "red" }}>
                              {edit ? "*" : ""}{" "}
                            </span>
                          </Typography>
                          {edit ? (
                            <TextField
                              type="text"
                              defaultValue={values.location}
                              value={values.location}
                              variant="outlined"
                              name="location"
                              placeholder="Store Number"
                              error={Boolean(
                                touched.location && errors.location
                              )}
                              helperText={touched.location && errors.location}
                              onBlur={handleBlur}
                              onChange={(props) => {
                                if (values.location !== null) {
                                  setAllowPrompt(true)
                                }
                                props.persist()
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              onKeyPress={(e) => handleKeyPress(e, "number")}
                              size="small"
                              className={classes.textField}
                              disabled={locationId?.length > 0 ? true : false}
                              style={{
                                backgroundColor:
                                  locationId?.length > 0 ? "#E0E0E0" : null,
                              }}
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values.location || "-"}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className={`${isTablet ? classes.noDivider : classes.divider
                            }`}
                        >
                          <Typography
                            variant="h5"
                            className={classes.textHeader1}
                          >
                            Store Name{" "}
                            <span style={{ color: "red" }}>
                              {edit ? "*" : ""}{" "}
                            </span>
                          </Typography>
                          {edit ? (
                            <TextField
                              defaultValue={values.locationName}
                              value={values.locationName}
                              variant="outlined"
                              name="locationName"
                              autoComplete="off"
                              placeholder="Store Name"
                              error={Boolean(
                                touched.locationName && errors.locationName
                              )}
                              helperText={
                                touched.locationName && errors.locationName
                              }
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              onBlur={handleBlur}
                              onChange={(props) => {
                                if (values.locationName !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              className={[classes.textField]}
                              size="small"
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values.locationName || "-"}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={6}
                          xs={6}
                          className={classes.divider}
                        >
                          <Typography
                            variant="h5"
                            className={classes.textHeader1}
                          >
                            Store Manager{" "}
                            <span style={{ color: "red" }}>
                              {edit ? "*" : ""}{" "}
                            </span>
                          </Typography>
                          {edit ? (
                            <TextField
                              defaultValue={values.locationManager}
                              value={values.locationManager}
                              variant="outlined"
                              autoComplete="off"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              name="locationManager"
                              placeholder="Store Manager"
                              className={classes.textField}
                              error={Boolean(
                                touched.locationManager &&
                                errors.locationManager
                              )}
                              helperText={
                                touched.locationManager &&
                                errors.locationManager
                              }
                              onBlur={handleBlur}
                              onChange={(props) => {
                                if (values.locationManager !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              size="small"
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values.locationManager
                                ? values.locationManager
                                : "-"}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={6}
                          xs={6}
                          className={`${isTablet ? classes.noDivider : classes.divider
                            }`}
                        >
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Assistant Manager{" "}
                            </Typography>
                            {edit ? (
                              <Typography
                                variant="h5"
                                style={{
                                  color: "#9E9E9E",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  paddingLeft: "5px",
                                  marginTop: "3px",
                                }}
                              >
                                (optional)
                              </Typography>
                            ) : null}
                          </Grid>

                          {edit ? (
                            <TextField
                              defaultValue={values.assistantManager}
                              value={values.assistantManager}
                              variant="outlined"
                              autoComplete="off"
                              name="assistantManager"
                              placeholder="Assistant Manager"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              error={Boolean(
                                touched.assistantManager &&
                                errors.assistantManager
                              )}
                              helperText={
                                touched.assistantManager &&
                                errors.assistantManager
                              }
                              onBlur={handleBlur}
                              className={classes.textField}
                              onChange={(props) => {
                                if (values.assistantManager !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              size="small"
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values.assistantManager
                                ? values.assistantManager
                                : "-"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Divider
                        variant="middle"
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                      />

                      <Grid container direction="row">
                        <Grid
                          container
                          item
                          lg={3}
                          xl={3}
                          md={3}
                          sm={6}
                          xs={12}
                          direction="row"
                          className={`${isTablet ? classes.noDivider : classes.divider
                            }`}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Geographic Position{" "}
                            </Typography>
                          </Grid>
                          {edit ? (
                            <>
                              <Grid item lg={6} md={6} sm={6} xs={6}>
                                <TextField
                                  type="number"
                                  defaultValue={values.latitude}
                                  value={values.latitude}
                                  variant="outlined"
                                  name="latitude"
                                  label="Latitude"
                                  placeholder="Latitude"
                                  onKeyPress={(e) => handleKeyPress(e, "text")}
                                  error={Boolean(
                                    touched.latitude && errors.latitude
                                  )}
                                  helperText={
                                    touched.latitude && errors.latitude
                                  }
                                  onBlur={handleBlur}
                                  className={classes.textField}
                                  onChange={(props) => {
                                    if (values.latitude !== null) {
                                      setAllowPrompt(true)
                                    }
                                    handleChange(props)
                                    onGlobalUpdate()
                                  }}
                                  size="small"
                                />
                              </Grid>
                              <Grid item lg={6} md={6} sm={6} xs={6}>
                                <TextField
                                  type="number"
                                  defaultValue={values.longitude}
                                  value={values.longitude}
                                  variant="outlined"
                                  name="longitude"
                                  label="Longitude"
                                  placeholder="Longitude"
                                  onKeyPress={(e) => handleKeyPress(e, "text")}
                                  error={Boolean(
                                    touched.longitude && errors.longitude
                                  )}
                                  helperText={
                                    touched.longitude && errors.longitude
                                  }
                                  onBlur={handleBlur}
                                  className={classes.textField}
                                  onChange={(props) => {
                                    if (values.longitude !== null) {
                                      setAllowPrompt(true)
                                    }
                                    handleChange(props)
                                    onGlobalUpdate()
                                  }}
                                  size="small"
                                />
                              </Grid>
                            </>
                          ) : (
                            <Typography className={classes.text}>
                              {(values.latitude &&
                                values.longitude &&
                                `${values.latitude},${values.longitude}`) ||
                                "0, 0"}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className={classes.divider}
                        >
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Support Phone Number{" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                          </Grid>

                          {edit ? (
                            <div
                              style={{
                                marginLeft: "15px",
                                marginBottom: "5px",
                              }}
                            >
                              <Adm.PhoneField
                                name="phoneNumber"
                                autoComplete="off"
                                entity="Location"
                                onKeyPress={(e) => handleKeyPress(e, "number")}
                                defaultValue={
                                  values.phoneNumber !== "0"
                                    ? values.phoneNumber.toString()
                                    : ""
                                }
                                className="location-base-phone-field"
                                onUpdate={({
                                  value,
                                  dialCode,
                                  format,
                                  countryCode,
                                }) => {
                                  const formatText =
                                    format !== undefined &&
                                    format
                                      .replace("+", "")
                                      .replace("-", "")
                                      .replaceAll(" ", "")
                                  setPhoneField({
                                    phoneCountryCode: countryCode,
                                    phoneCount: formatText.length,
                                    phoneCode: dialCode,
                                  })
                                  setFieldValue("phoneNumber", value)
                                  setFieldValue("countryCode", dialCode)
                                  onGlobalUpdate()
                                }}
                                defaultCountry={Utils?.JSUtils?.getPhoneCode(
                                  countryList,
                                  values?.country
                                )}
                                defaultErrorValue={Boolean(
                                  touched.phoneNumber && errors.phoneNumber
                                )}
                              />
                            </div>
                          ) : (
                            <Typography className={classes.text}>
                              {"+"}
                              {displayPhoneNumber}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className={`${classes.divider}`}
                        >
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Support Email Address{" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                          </Grid>

                          {edit ? (
                            <TextField
                              defaultValue={values.emailAddress}
                              value={values.emailAddress}
                              variant="outlined"
                              name="emailAddress"
                              autoComplete="off"
                              placeholder="Email"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              error={Boolean(
                                touched.emailAddress && errors.emailAddress
                              )}
                              helperText={
                                touched.emailAddress && errors.emailAddress
                              }
                              onBlur={handleBlur}
                              onChange={(props) => {
                                if (values.emailAddress !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              className={classes.textField}
                              size="small"
                            />
                          ) : (
                            <Typography className={classes.textemail}>
                              {values.emailAddress ? values.emailAddress : "-"}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={6}
                          xs={6}
                          className={`${isTablet ? classes.noDivider : classes.divider
                            }`}
                        >
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Return Policy{" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                          </Grid>

                          {edit ? (
                            <TextField
                              defaultValue={values.returnPolicy}
                              value={values.returnPolicy}
                              variant="outlined"
                              autoComplete="off"
                              name="returnPolicy"
                              placeholder="Return Policy"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              error={Boolean(
                                touched.returnPolicy && errors.returnPolicy
                              )}
                              helperText={
                                touched.returnPolicy && errors.returnPolicy
                              }
                              onBlur={handleBlur}
                              className={classes.textField}
                              onChange={(props) => {
                                if (values.returnPolicy !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              size="small"
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values.returnPolicy ? values.returnPolicy : "-"}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Divider
                        variant="middle"
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                      />

                      <Grid container direction="row">
                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className={`${classes.divider}`}
                        >
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Storage Unit{" "}
                            </Typography>
                            {edit ? (
                              <Typography
                                variant="h5"
                                style={{
                                  color: "#9E9E9E",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  paddingLeft: "5px",
                                  marginTop: "3px",
                                }}
                              >
                                (optional)
                              </Typography>
                            ) : null}
                          </Grid>

                          {edit ? (
                            <Adm.DropdownFilter
                              list={storageUnitList || []}
                              defaultValue={values?.storageUnitId}
                              selectedValue={values?.storageUnitId}
                              onChangeEvent={(e, val) => {
                                setAnyChanges(true)
                                setStorageAddress(val?.address || {})
                                setFieldValue("storageUnitId", val.id)
                                setFieldValue("isStorageunitAddress", false)
                                if (values?.isStorageunitAddress) {
                                  setFieldValue("line1", "")
                                  setFieldValue("line2", "")
                                  setFieldValue("postalcode", "")
                                  setFieldValue("state", "")
                                  setFieldValue("city", "")
                                }
                              }}
                              onBlur={handleBlur}
                              popupOpen={storageOpen}
                              setPopupOpen={setStorageOpen}
                              disableClearable={true}
                              classNameTextField={classes.autoCompleteHeight}
                              placeholder={"Select Storage"}
                              style={{
                                width: "95%",
                                paddingLeft: "15px",
                                backgroundColor:
                                  locationId?.length > 0 ? "#f6f6f6" : null,
                              }}
                              disabled={locationId?.length > 0 ? true : false}
                            />
                          ) : (
                            <Typography className={classes.textemail}>
                              {values.storageUnitId
                                ? values.storageUnitId
                                : "-"}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className={`${classes.divider}`}
                        >
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              CV Confidence Threshold{" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                          </Grid>

                          {edit ? (
                            <TextField
                              defaultValue={values.cvThreshold}
                              value={values.cvThreshold}
                              variant="outlined"
                              name="cvThreshold"
                              autoComplete="off"
                              placeholder="CV Threshold"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              error={Boolean(
                                touched.cvThreshold && errors.cvThreshold
                              )}
                              helperText={
                                touched.cvThreshold && errors.cvThreshold
                              }
                              onBlur={handleBlur}
                              onChange={(props) => {
                                if (values.cvThreshold !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              className={classes.textField}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    className={[
                                      classes.adornmentBackground,
                                      classes.adornmentEnd,
                                    ]}
                                    position="end"
                                  >
                                    <PercentageIcon />{" "}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : (
                            <Typography className={classes.textemail}>
                              {values.cvThreshold ? values.cvThreshold : "0"}
                              {`%`}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className={`${classes.divider}`}
                        >
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Max Store Occupancy{" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                          </Grid>

                          {edit ? (
                            <TextField
                              defaultValue={values.maxStoreOccupancy}
                              value={values.maxStoreOccupancy}
                              variant="outlined"
                              name="maxStoreOccupancy"
                              autoComplete="off"
                              placeholder="Max Store Occupancy"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              error={Boolean(
                                touched.maxStoreOccupancy &&
                                errors.maxStoreOccupancy
                              )}
                              helperText={
                                touched.maxStoreOccupancy &&
                                errors.maxStoreOccupancy
                              }
                              onBlur={handleBlur}
                              onChange={(props) => {
                                if (values.maxStoreOccupancy !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              className={[
                                classes.textField,
                                classes.textFieldLeft45,
                              ]}
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    className={[
                                      classes.adornmentBackground,
                                      classes.adornmentStart,
                                    ]}
                                    position="start"
                                  >
                                    <UserIcon />{" "}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : (
                            <Typography className={classes.textemail}>
                              {values.maxStoreOccupancy
                                ? values.maxStoreOccupancy
                                : "0"}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className={`${classes.divider}`}
                        >
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Store Size (In Feet){" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                          </Grid>

                          {edit ? (
                            <>
                              <Grid container direction="row">
                                <TextField
                                  type="number"
                                  placeholder="Length"
                                  variant="outlined"
                                  label="Length"
                                  inputProps={{
                                    minLength: 1,
                                  }}
                                  style={{ width: "70px", marginLeft: "10px" }}
                                  defaultValue={values.locationSizeLength}
                                  value={values.locationSizeLength}
                                  error={Boolean(
                                    touched.locationSizeLength &&
                                    errors.locationSizeLength
                                  )}
                                  helperText={
                                    touched.locationSizeLength &&
                                    errors.locationSizeLength
                                  }
                                  onChange={(event) => {
                                    event.target.value =
                                      event.target.value === ""
                                        ? 0
                                        : parseFloat(event.target.value)
                                    if (
                                      event.target.value.length < 0 ||
                                      event.target.value.length > 10
                                    ) {
                                      return 0
                                    }
                                    setFieldValue(
                                      "locationSizeLength",
                                      event.target.value
                                    )
                                    setAnyChanges(true)
                                  }}
                                  size="small"
                                />
                                <TextField
                                  type="number"
                                  placeholder="Width"
                                  label="Width"
                                  variant="outlined"
                                  inputProps={{
                                    minLength: 1,
                                  }}
                                  error={Boolean(
                                    touched.locationSizeWidth &&
                                    errors.locationSizeWidth
                                  )}
                                  helperText={
                                    touched.locationSizeWidth &&
                                    errors.locationSizeWidth
                                  }
                                  style={{ width: "70px", marginLeft: "10px" }}
                                  defaultValue={values.locationSizeWidth}
                                  value={values.locationSizeWidth}
                                  onChange={(event) => {
                                    event.target.value =
                                      event.target.value === ""
                                        ? 0
                                        : parseFloat(event.target.value)
                                    if (
                                      event.target.value.length < 0 ||
                                      event.target.value.length > 10
                                    ) {
                                      return 0
                                    }

                                    setFieldValue(
                                      "locationSizeWidth",
                                      event.target.value
                                    )
                                    setAnyChanges(true)
                                  }}
                                  size="small"
                                />
                                <TextField
                                  type="number"
                                  placeholder="Height"
                                  label="Height"
                                  variant="outlined"
                                  inputProps={{
                                    minLength: 1,
                                  }}
                                  style={{ width: "70px", marginLeft: "10px" }}
                                  defaultValue={values.locationSizeHeight}
                                  value={values.locationSizeHeight}
                                  error={Boolean(
                                    touched.locationSizeHeight &&
                                    errors.locationSizeHeight
                                  )}
                                  helperText={
                                    touched.locationSizeHeight &&
                                    errors.locationSizeHeight
                                  }
                                  onChange={(event) => {
                                    event.target.value =
                                      event.target.value === ""
                                        ? 0
                                        : parseFloat(event.target.value)
                                    if (
                                      event.target.value.length < 0 ||
                                      event.target.value.length > 10
                                    ) {
                                      return 0
                                    }

                                    setFieldValue(
                                      "locationSizeHeight",
                                      event.target.value
                                    )
                                    setAnyChanges(true)
                                  }}
                                  size="small"
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Typography className={classes.textemail}>
                                {values?.locationSizeLength
                                  ? values?.locationSizeLength
                                  : "0"}
                                ,
                                {values?.locationSizeWidth
                                  ? values?.locationSizeWidth
                                  : "0"}
                                ,
                                {values?.locationSizeHeight
                                  ? values?.locationSizeHeight
                                  : "0"}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>

                      <Divider
                        variant="middle"
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                      />

                      <Grid container direction="row">
                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={6}
                          xs={6}
                          className={classes.divider}
                        >
                          <Typography
                            variant="h5"
                            className={classes.textHeader1}
                          >
                            Survillence Camera{" "}
                            <span style={{ color: "red" }}>
                              {edit ? "*" : ""}{" "}
                            </span>
                          </Typography>
                          {edit ? (
                            <>
                              <Adm.DropdownFilter
                                list={survCameraList || []}
                                defaultValue={values?.surveillanceCamera}
                                selectedValue={values?.surveillanceCamera}
                                onChangeEvent={(e, val) => {
                                  setAnyChanges(true)
                                  if (storeId?.length > 0) {
                                    setOpenConfirmationDialog(true)
                                    setSelectedCamera(val)
                                  } else {
                                    setFieldValue("surveillanceCamera", val)
                                    setSelectedCamera(val)
                                  }
                                }}
                                onBlur={handleBlur}
                                popupOpen={cameraOpen}
                                setPopupOpen={setCameraOpen}
                                disableClearable={true}
                                classNameTextField={classes.autoCompleteHeight}
                                placeholder={"Select Survillence  camera"}
                                touchedControl={touched?.surveillanceCamera}
                                errorControl={errors?.surveillanceCamera}
                                style={{
                                  width: "250px",
                                  paddingLeft: "15px",
                                }}
                              />
                            </>
                          ) : (
                            <Typography className={classes.textemail}>
                              {values.surveillanceCamera?.name || "-"}
                            </Typography>
                          )}
                        </Grid>

                        {values.addressCountryCode === "IN" && (
                          <Grid
                            item
                            lg={3}
                            md={3}
                            sm={6}
                            xs={6}
                            className={classes.divider}
                          >
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              GSTIN Number{" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                            {edit ? (
                              <>
                                <TextField
                                  defaultValue={values.GSTNumber}
                                  value={values.GSTNumber}
                                  variant="outlined"
                                  name="GSTNumber"
                                  autoComplete="off"
                                  placeholder="GSTIN Number"
                                  error={Boolean(
                                    touched.GSTNumber && errors.GSTNumber
                                  )}
                                  helperText={
                                    touched.GSTNumber && errors.GSTNumber
                                  }
                                  onKeyPress={(e) => handleKeyPress(e, "text")}
                                  onBlur={handleBlur}
                                  onChange={(props) => {
                                    if (values.GSTNumber !== null) {
                                      setAllowPrompt(true)
                                    }
                                    setFieldValue(
                                      "GSTNumber",
                                      props?.target?.value
                                    )
                                    setAnyChanges(true)
                                  }}
                                  className={[
                                    classes.textField,
                                    classes.gstNum,
                                  ]}
                                  size="small"
                                />
                              </>
                            ) : (
                              <>
                                <Typography
                                  variant="body1"
                                  style={{
                                    padding: "5px 20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {values?.GSTNumber || "-"}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        )}

                      </Grid>

                      <Divider className={classes.dottedDivider} />

                      <Typography
                        variant="h5"
                        style={{
                          paddingLeft: "10px",
                          fontWeight: "700",
                          fontSize: "15px",
                        }}
                      >
                        <IconButton>
                          <PlaceIcon />
                        </IconButton>
                        STORE ADDRESS INFORMATION
                      </Typography>

                      <Grid
                        container
                        direction="row"
                        style={{ padding: "10px 5px", margin: "0px" }}
                        spacing={4}
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                          <Typography
                            variant="h5"
                            className={classes.textHeader1}
                          >
                            Address Line 1{" "}
                            <span style={{ color: "red" }}>
                              {edit ? "*" : ""}{" "}
                            </span>
                          </Typography>
                          {edit ? (
                            <TextField
                              disabled={values?.isStorageunitAddress || false}
                              style={{
                                backgroundColor: values?.isStorageunitAddress
                                  ? "#f6f6f6"
                                  : null,
                              }}
                              defaultValue={values?.line1}
                              value={values?.line1}
                              variant="outlined"
                              autoComplete="off"
                              name="line1"
                              placeholder="Address Line 1"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              className={classes.textField}
                              error={Boolean(touched.line1 && errors.line1)}
                              helperText={touched.line1 && errors.line1}
                              onBlur={handleBlur}
                              onChange={(props) => {
                                if (values?.line1 !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              size="small"
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values?.line1 || "-"}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              Address Line 2
                            </Typography>
                            {edit ? (
                              <Typography
                                variant="h5"
                                style={{
                                  color: "#9E9E9E",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  paddingLeft: "5px",
                                  marginTop: "3px",
                                }}
                              >
                                (optional)
                              </Typography>
                            ) : null}
                          </Grid>

                          {edit ? (
                            <TextField
                              disabled={values?.isStorageunitAddress || false}
                              style={{
                                backgroundColor: values?.isStorageunitAddress
                                  ? "#f6f6f6"
                                  : null,
                              }}
                              defaultValue={values?.line2}
                              value={values?.line2}
                              variant="outlined"
                              name="line2"
                              autoComplete="off"
                              placeholder="Address Line 2"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              className={classes.textField}
                              error={Boolean(touched.line2 && errors.line2)}
                              helperText={touched.line2 && errors.line2}
                              onBlur={handleBlur}
                              onChange={(props) => {
                                if (values?.line2 != null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              size="small"
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {_.get(values, "line2", "-") || "-"}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                          <Typography
                            variant="h5"
                            className={classes.textHeader1}
                          >
                            Country
                            <span style={{ color: "red" }}>
                              {edit ? "*" : ""}{" "}
                            </span>
                          </Typography>
                          {edit ? (
                            <TextField
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              type="text"
                              autoComplete="off"
                              name="country"
                              placeholder="Country"
                              value={values?.country}
                              disabled={true}
                              style={{ backgroundColor: "#f6f6f6" }}
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values?.country}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        style={{ padding: "10px 5px", margin: "0px" }}
                        spacing={4}
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              State{" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                            {edit ? (
                              <Typography
                                variant="h5"
                                style={{
                                  color: "#9E9E9E",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  paddingLeft: "4px",
                                  marginTop: "1px",
                                }}
                              >
                                State is auto-populated from Zipcode
                              </Typography>
                            ) : null}
                          </Grid>
                          {edit ? (
                            <>
                              <TextField
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                                type="text"
                                autoComplete="off"
                                name="state"
                                placeholder="Populate from Zipcode"
                                value={values?.state || ""}
                                disabled={true}
                                error={Boolean(touched.state && errors.state)}
                                helperText={touched.state && errors.state}
                                style={{ backgroundColor: "#f6f6f6" }}
                              />
                            </>
                          ) : (
                            <Typography className={classes.text}>
                              {values?.state || ""}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                          <Grid container direction="row">
                            <Typography
                              variant="h5"
                              className={classes.textHeader1}
                            >
                              City{" "}
                              <span style={{ color: "red" }}>
                                {edit ? "*" : ""}{" "}
                              </span>
                            </Typography>
                            {edit ? (
                              <Typography
                                variant="h5"
                                style={{
                                  color: "#9E9E9E",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  paddingLeft: "5px",
                                  marginTop: "3px",
                                }}
                              >
                                City is auto-populated from Zipcode
                              </Typography>
                            ) : null}
                          </Grid>

                          {edit ? (
                            <>
                              <Adm.DropdownFilter
                                list={getUserCityList}
                                defaultValue={values.city}
                                selectedValue={values.city}
                                onChangeEvent={(e, val) => {
                                  setFieldValue("city", val)
                                  setAnyChanges(true)
                                }}
                                popupOpen={userCityOpen}
                                setPopupOpen={setUserCityOpen}
                                disableClearable={true}
                                classNameTextField={classes.autoCompleteHeight}
                                touchedControl={touched?.city}
                                errorControl={errors?.city}
                                placeholder={"Select City"}
                                className={[classes.textCity]}
                              />
                            </>
                          ) : (
                            <Typography className={classes.text}>
                              {values?.city}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                          <Typography
                            variant="h5"
                            className={[classes.textHeader1, classes.textCode]}
                            style={{ paddingLeft: "22px" }}
                          >
                            Zip Code{" "}
                            <span style={{ color: "red" }}>
                              {edit ? "*" : ""}{" "}
                            </span>
                          </Typography>
                          {edit ? (
                            <Adm.ZipCodeBox
                              renderLabel={() => ""}
                              onVerify={ZipCodeOnVerifyCB}
                              onChanged={ZipCodeOnChangeCB}
                              name={"postalcode"}
                              defaultErrorMessage={
                                touched.postalcode && errors.postalcode
                              }
                              defaultErrorFlag={
                                touched.postalcode && errors.postalcode
                              }
                              onBlur={handleBlur}
                              defaultIsRequired={true}
                              defaultZipCode={values?.postalcode || ""}
                              defaultCountryCode={values?.addressCountryCode}
                              borderRadius={"5"}
                              height={"38"}
                              width={"226"}
                              classes={[classes.textCode]}
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values?.postalcode}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Divider className={classes.dottedDivider} />
                      {localStorage.getItem("tenantPaymentConfigEnable") ===
                        "true" && (
                          <EditLocationHierarchy
                            {...props}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                            touched={touched}
                            edit={edit}
                            setEdit={setEdit}
                            locationType={locationType}
                            setLocationType={setLocationType}
                            isAnyChanges={isAnyChanges}
                            setAnyChanges={setAnyChanges}
                            locationTypeError={locationTypeError}
                            setLocationTypeError={setLocationTypeError}
                            paymentTypeError={paymentTypeError}
                            setPaymentTypeError={setPaymentTypeError}
                            accountTypeError={accountTypeError}
                            setAccountTypeError={setAccountTypeError}
                            locationSizeError={locationSizeError}
                            setLocationSizeError={setLocationSizeError}
                            locationId={locationId}
                            parentUseStyles={useStyles}
                            storeId={storeId}
                            handleBlur={handleBlur}
                          />
                        )}

                      <Adm.OpenDate
                        {...props}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        data={data}
                        setData={setData}
                        storeId={storeId}
                        isWriteAllowed={isWriteAllowed}
                        setAllowPrompt={setAllowPrompt}
                        isTablet={isTablet}
                        edit={edit}
                        handleBlur={handleBlur}
                        values={values}
                        timeZoneList={timeZoneList}
                        handleDateChange={handleDateChange}
                        handleTimeZoneChange={handleTimeZoneChange}
                        onGlobalUpdate={onGlobalUpdate}
                        title={"STORE DATE AND TIME INFORMATION"}
                      />
                      <Features.OperationHoursKit
                        defaultList={[values.business_hrs]}
                        edit={edit}
                        errors={errors}
                        touched={touched}
                        onGlobalUpdate={onGlobalUpdate}
                        onUpdate={(e) => {
                          onGlobalUpdate()
                          setFieldValue("business_hrs", e[0])
                        }}
                      />
                    </>
                  </FormMainWrapper>
                </>
              )
            }}
          </Formik>
        </>
      </Card>
      <>
        {alert ? (
          <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
        ) : null}
      </>
    </div>
  )
}
export { LocationAddress }
export default LocationAddress
