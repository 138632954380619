import React, { useState } from "react"
import SnackbarMaterial from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"
import { minWidth } from "@material-ui/system"
import styled from "styled-components/macro"
import { CheckCircleIcon } from "@iconsGallery"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  alertBox: {
    minWidth: "320px",
    fontSize: "14px",
    fontWeight: "400",
    borderRadius: "4px",
  },
}))

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

function SnackBar(props) {
  const {
    open: defaultOpen = false,
    type,
    makeValuesToDefault,
    onCloseHandlerToMakeDefaultValues,
    message = "Test",
    autoCloseTrigger = () => { },
    autoHideDuration = 4000,
    handleHide = () => "",
  } = props || {}
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  React.useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    if (_.isFunction(_.get(props, "handleClose"))) {
      props.handleClose()
    }
    setOpen(false)
    autoCloseTrigger(false)
    if (makeValuesToDefault && makeValuesToDefault) {
      onCloseHandlerToMakeDefaultValues()
    }
  }
  return (
    <div className={classes.root}>
      <SnackbarMaterial
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          elevation={6}
          className={classes.alertBox}
          variant="filled"
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" />,
          }}
          style={{
            backgroundColor: type === "success" ? "#43A047" : null,
          }}
          {...(props?.icon ? { icon: props?.icon } : {})}
        >
          {message}
        </Alert>
      </SnackbarMaterial>
    </div>
  )
}

export { SnackBar }
export default SnackBar
