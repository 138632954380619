import * as IconsGallery from "@iconsGallery"
import { Hash, Command, Menu, AlignJustify, Home } from "react-feather"

export const EndPointAPI = {
  getVendorAPI: `/vendor/api/vendor/count/of/products/storagewise/by/id`,
  getStorageUnitMappings: `/product/api/storages/getStorageUnitMappings`,
}

let selectConfig = {
  field: "",
  title: "",
  editable: false,
  connect: true,
  editor: "text",
  border: true,
  filter: false,
  show: true,
  min_width: 1,
  sortable: false,
}

export const defaultDigitTableColumnData = [
  selectConfig,
  {
    field: "storage_unit_id",
    title: "Storage Name",
    editable: false,
    editor: "text",
    border: false,
    filter: true,
    show: true,
    min_width: 3.5,
    sortable: false,
  },
  {
    field: "product_count",
    title: "Products Count",
    editable: false,
    editor: "text",
    border: false,
    filter: false,
    show: true,
    min_width: 2.5,
    sortable: false,
  },
]
export const defaultDigitImageTableColumnData = [
  selectConfig,
  {
    field: "item_images",
    title: "Storage Name",
    imageContent: true,
    editable: false,
    editor: "text",
    border: false,
    filter: true,
    show: true,
    min_width: 2,
    sortable: false,
  },
]
