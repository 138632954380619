import { withStyles } from "@material-ui/core/styles"
import {
  Dialog,
  IconButton,
  TableContainer,
  TableCell,
} from "@material-ui/core"

export const StyledDialog = withStyles((theme) => ({
  paper: {
    maxHeight: "calc(100vh - 70px) !important",
    minWidth: "90vw !important",
    maxWidth: "98vw !important",
    padding: "0px",
  },
}))(Dialog)

export const tableWrapper = {
  padding: "14px",
}

export const buttonWrapper = { margin: "10px" }
export const wrapperButton = {
  width: "100%",
  borderTop: "0.1em solid rgba(0, 0, 0, 0.1)",
  paddingLeft: "5px",
}

export const StyledIconButton = withStyles((theme) => ({
  root: {
    padding: "0px !important",
  },
}))(IconButton)

export const TableWrapper = withStyles((theme) => ({
  root: {
    maxHeight: 300,
  },
}))(TableContainer)

export const TableCellWrapper = withStyles((theme) => ({
  stickyHeader: {
    backgroundColor: "rgba(224, 224, 224, 0.46)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
}))(TableCell)

export const TableDataCellWrapper = withStyles((theme) => ({
  root: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    width: "180px",
  },
}))(TableCell)

export const TableDataCellWrapper2 = withStyles((theme) => ({
  root: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
}))(TableCell)
