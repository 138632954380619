import React from "react"
import { Grid } from "@material-ui/core"
import _ from 'lodash'

const UseRadioDays = ({ clickHandler, daysArr, disabledDays = [], disableDateSelection = false, className={}, }) => {
  return (
    <Grid
      className={className}
      style={{

      }}
    >
      {daysArr &&
        daysArr.map((valObj, index) => (
          <div
            onClick={((_.includes(disabledDays, valObj.day) && !valObj.selected) || disableDateSelection) ? () => { } : () => clickHandler && clickHandler(index)}
            key={index}
            style={{
              paddingTop: "10px",
              cursor: ((_.includes(disabledDays, valObj.day) && !valObj.selected) || disableDateSelection) ? "not-allowed" : "pointer",
            }}
          >
            {valObj.selected ? (
              <div
                style={{
                  height: "40px",
                  width: "40px",
                  backgroundColor: "var(--primaryColor)",
                  borderRadius: "50%",
                  display: "inline-block",
                  color: "white",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "auto",
                    width: "inherit",
                    top: "25%",
                    textAlign: "center",
                  }}
                >
                  {valObj && valObj.label && valObj.label}
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "40px",
                  width: "40px",
                  display: "inline-block",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "auto",
                    width: "inherit",
                    top: "25%",
                      color: _.includes(disabledDays, valObj.day) ? "var(--primaryColor)" : "var(--primaryColor)",
                    textAlign: "center",
                  }}
                >
                  {valObj && valObj.label}
                </div>
              </div>
            )}
          </div>
        ))}
    </Grid>
  )
}

export default UseRadioDays
