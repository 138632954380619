import React, { useState, useContext } from "react"
import { CheckCircleIcon, Edit, Slash, PlusCircle } from "@iconsGallery"
import { Add } from "@material-ui/icons"
import {
  CardHeader,
  IconButton,
  Tooltip,
  Button,
  Modal,
  Grid,
} from "@material-ui/core"
import {
  ExpandLessIcon,
  Trash2,
  ExpandMoreIcon,
} from "../../../../IconsGallery/IconsGallery"
import * as Utils from "@utils"
import { useTranslation } from "react-i18next"
import SharedTable from "../../sharedComponents/Table"
import columns from "../ProductAttribute/column"
import dummyData from "../ProductAttribute/attributeData.json"

const CustomHeaderContainer = (props) => {
  const [flag, setFlag] = useState(false)
  const {
    resetForm = () => {},
    showDeleteIcon = false,
    isAnyChanges = false,
    setFieldValue = () => {},
    locationIds,
    updateData = () => {},
    setAllowPrompt = () => {},
    containerProps,
    values,
    setAnyChanges = () => {},
    edit = false,
    setEdit = () => {},
    handleSpecificationDelete = () => {},
    openModal,
    setopenModal = () => {},
    handleOpen = () => {},
    handleClose = () => {},
    dataFetchMethod = () => {},
    setOpenConfirmationDialog = () => {},
    handledeleteAttributeItems = () => {},
    data,
    attributes,
    pageMode = "edit",
    clearVal,
    setClearVal = () => {},
  } = props

  const {
    expanded,
    isEditMode = true,
    classes,
    fetchData,
    label,
    setCollapseOpen,
    handleExpandClick,
    resetData = () => {},
    isWriteAllowed = false,
    iconDisplay = true,
  } = containerProps

  const isSelected = isAnyChanges
  const { t } = useTranslation()
  return (
    <CardHeader
      style={{ borderBottom: "1px solid #EEEEEE" }}
      title={<>{label}</>}
      action={
        <>
          {props.edit ? (
            <>
              {expanded === true && isEditMode === true && iconDisplay && (
                <Tooltip title="Edit" placement="top">
                  <IconButton
                    onClick={() => {
                      setEdit(false)
                    }}
                    style={{ marginRight: "13px" }}
                    disabled={isWriteAllowed ? false : true}
                  >
                    <Edit className={classes.basicIconProperty} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              {expanded === true ? (
                <>
                  {containerProps.label === "Attributes" ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: "25px",
                          color: "#fff",
                          backgroundColor: "var(--primaryColor)",
                        }}
                        startIcon={
                          <PlusCircle
                            style={{ color: "#fff", width: "16px" }}
                          />
                        }
                        onClick={() => handleOpen()}
                        disabled={pageMode === "create" ? true : false}
                      >
                        {window?.globalTranslation("Attribute List")}
                      </Button>

                      {showDeleteIcon ? (
                        ""
                      ) : (
                        <>
                          <Tooltip title="Archive" placement="top">
                            <IconButton
                              style={{ marginRight: "20px" }}
                              disabled={props.attributes?.length === 0}
                              onClick={() => {
                                setOpenConfirmationDialog(true)
                                setAnyChanges(true)
                              }}
                            >
                              <Trash2 />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </>
                  ) : null}

                  {iconDisplay && (
                    <>
                      <Tooltip title="Cancel" placement="top">
                        <IconButton
                          onClick={() => {
                            setClearVal(true)
                            resetData()
                            setEdit(true)
                            setAnyChanges(false)
                            setAllowPrompt(false)
                          }}
                          style={{ marginRight: "20px" }}
                        >
                          <Slash
                            style={{ color: "red" }}
                            className={classes.basicIconProperty}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Save" placement="top">
                        <IconButton
                          style={{
                            marginRight: "18px",
                            pointerEvents: `${isSelected ? "unset" : "none"}`,
                          }}
                          onClick={() => {
                            if (isSelected) {
                              updateData(fetchData)
                              setEdit(true)
                              setAnyChanges(false)
                              setAllowPrompt(true)
                            }
                          }}
                        >
                          <CheckCircleIcon
                            style={{
                              color: `${isSelected ? "#4CAF50" : "#CCC"}`,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}
          {expanded === true ? (
            <IconButton
              onClick={() => {
                setEdit(true)
                handleExpandClick()
                setAllowPrompt(false)
              }}
            >
              <ExpandLessIcon className={classes.iconUpDown} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                resetData()
                handleExpandClick()
              }}
            >
              <ExpandMoreIcon className={classes.iconUpDown} />
            </IconButton>
          )}
        </>
      }
    ></CardHeader>
  )
}
export default CustomHeaderContainer
