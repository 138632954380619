import { makeStyles } from '@material-ui/core';

const HEADER_HEIGHT = 62;
const APPBAR_HEIGHT = HEADER_HEIGHT * 0.8

const useStyles = makeStyles(theme => ({
    appbar: {
        width: '100%',
        display: 'flex',
        justifyContent: "space-between",
        boxShadow: `0px 0px 4px 0px rgba(120,120,120,0.7)`,
        padding: theme.spacing(0, 0, 0, 3),
        alignItems: "center",
        height: APPBAR_HEIGHT,
    },
    drawer: {
        '& .MuiDrawer-paperAnchorBottom': {
            height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        }
    },
    content: {
        width: '100%',
        maxHeight: `calc(100vh - ${APPBAR_HEIGHT}px)`,
        height: '100%',
        overflowY: 'auto',
    }
}));

export default useStyles;