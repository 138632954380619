import { useState, useEffect } from "react"
function useFullFillmentStatus(props) {
  const {
    defaultList = [],
    height = "48px",
    width = "450px",
    onlyOneExpand = false,
  } = props

  const [trackerList, setTrackerList] = useState([])

  const handleAccordionChange = (index, isExpanded) => {
    if (onlyOneExpand) {
      setTrackerList((ps) => {
        return ps.map((stepObj, idx) => {
          if (idx === index) {
            return { ...stepObj, isOpen: isExpanded }
          } else {
            return { ...stepObj, isOpen: false }
          }
        })
      })
    } else {
      setTrackerList((ps) => {
        return ps.map((stepObj, idx) => {
          if (idx === index) {
            return { ...stepObj, isOpen: isExpanded }
          } else {
            return stepObj
          }
        })
      })
    }
  }

  useEffect(() => {
    if (defaultList) {
      setTrackerList(defaultList)
    }
  }, [defaultList])

  return {
    defaultList,
    height,
    width,

    trackerList,
    setTrackerList,

    handleAccordionChange,
  }
}

export default useFullFillmentStatus
