import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green, red } from "@material-ui/core/colors"
import { spacing } from "@material-ui/system"
import styled from "styled-components/macro"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
} from "@material-ui/core"

export const Typography = styled(MuiTypography)(spacing)

export const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`

export const BlackText = styled.span`
  color: #000;
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`
export const GreenText = styled.span`
  color: ${() => green[400]};
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`

export const RedText = styled.span`
  color: ${() => red[400]};
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`

export const SmallButton = styled(Button)`
  padding: 8px;
  min-width: 0;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`

export const useStyles = makeStyles((theme) => ({
  cardSelected: {
    backgroundColor: "#1565c0",
  },
  fontSelected: {
    color: "white",
  },
  headerValue: {
    fontWeight: "800",
    fontSize: "15px",
  },
  textColorstyle: {
    padding: "5px",
  },
  values: {
    fontSize: "24px",
    padding: "5px",
  },
  cardStyles: {
    boxShadow: "0px 0px 14px rgba(53, 64, 82, 0.05)",
    background: "#FFFFFF",
    minHeight: 144,
    height: "fit-content",
    width: "100%",
  },
  parentres: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media(max-width: 768px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  boxStyle: {
    display: "flex",
    padding: "5px",
  },
}))
