import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green, red } from "@material-ui/core/colors"
import { spacing, width } from "@material-ui/system"
import styled from "styled-components/macro"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
} from "@material-ui/core"
import { Flex } from "@aws-amplify/ui-react"

export const Typography = styled(MuiTypography)(spacing)

export const CardContent = styled(MuiCardContent)`
  position: relative;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`

export const BlackText = styled.span`
  color: #000;
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`
export const GreenText = styled.span`
  color: ${() => green[400]};
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`

export const RedText = styled.span`
  color: ${() => red[400]};
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`

export const SmallButton = styled(Button)`
  padding: 8px;
  min-width: 0;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`




export const useStyles = makeStyles((theme) => ({
  pieChartOuter: {
    display: "flex",
    width: "100%",
    "@media(max-width: 1400px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  pieChartOutersaas: {
    display: "flex",
    width: "100%",
    "@media(max-width: 1300px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  cardSelected: {
    backgroundColor: "#1565c0",
  },
  fontSelected: {
    color: "white",
  },
  headerValue: {
    fontWeight: "800",
    fontSize: "15px",
  },
  textColorstyle: {
    padding: "5px",
  },
  values: {
    fontSize: "24px",
    padding: "5px",
  },
  cardStyles: {
    boxShadow: "0px 0px 14px rgba(53, 64, 82, 0.05)",
    minHeight: 144,
    width: "100%",
  },
  pieChartRight: {
    width: "100%",
    position: "relative",
  },
  pieChartRightsaas: {
    width: "100%",
    position: "relative",
    right: "80px",
    bottom: "5px"
  },
  parentres: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media(max-width: 768px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  boxStyle: {
    display: "flex",
    padding: "5px",
  },
  pieChartListCritical: {
    border: "1px solid #cadced",
    borderRadius: "20px",
    padding: "20px !important",
    margin: "20px 0 0 0",
    position: "relative",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    '& pieChartListHead': {
      color: "#f00 !important",
    }
  },
  pieChartListHigh: {
    backgroundColor: "#fef0f5",
    border: "1px solid #f785a2",
    borderRadius: "20px",
    padding: "20px !important",
    '& pieChartListHead': {
      color: "#f00 !important",
    }
  },
  pieChartListMedium: {
    backgroundColor: "#f8f0d5",
    border: "1px solid #e7c66b",
    borderRadius: "20px",
    padding: "20px !important",
  },
  pieChartListLow: {
    backgroundColor: "#f0f6cf",
    border: "1px solid #b9c37d",
    borderRadius: "20px",
    padding: "20px !important",
  },
  pieChartListbox: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "232px",
    overflow: "auto",
  },
  pieChartListHeadTop: {
    color: "#f00",
  },
  pieChartListHeadCritical: {
    margin: 0,
    padding: "10px 10px 10px 18px",
    listStyle: "none",
    fontSize: "15px",
    color: "#fff !important",
    backgroundColor: "#ef5359 !important",
    position: "absolute",
    top: "-46px",
    width: "100%",
    left: 0,
    borderRadius: "6px",
  },
  pieChartListHeadHigh: {
    margin: 0,
    padding: "10px 10px 10px 18px",
    listStyle: "none",
    fontSize: "15px",
    color: "#fff !important",
    backgroundColor: "#ff7043 !important",
    position: "absolute",
    top: "-46px",
    width: "100%",
    left: 0,
    borderRadius: "6px",
  },
  pieChartListHeadMedium: {
    margin: 0,
    padding: "10px 10px 10px 18px",
    listStyle: "none",
    fontSize: "15px",
    color: "#fff !important",
    backgroundColor: "#e7c66b !important",
    position: "absolute",
    top: "-46px",
    width: "100%",
    left: 0,
    borderRadius: "6px",
  },
  pieChartListHeadLow: {
    margin: 0,
    padding: "10px 10px 10px 18px",
    listStyle: "none",
    fontSize: "15px",
    color: "#fff !important",
    backgroundColor: "#b9c37d !important",
    position: "absolute",
    top: "-46px",
    width: "100%",
    left: 0,
    borderRadius: "6px",
  },
  pieChartList: {
    fontSize: "12px",
    padding: "3px 0 !important",
  },
  pieChartArrow: {
    width: 0,
    height: 0,
    borderTop: "20px solid transparent",
    borderLeft: "30px solid #aaa",
    borderBottom: "20px solid transparent",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "-30px",
    margin: "auto"
  }
}))
