import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Chip,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Switch,
  Checkbox,
} from "@material-ui/core"
import { grey, blue } from "@material-ui/core/colors"
import * as Utils from "@utils"
import * as Adm from "@adm"
import _ from "lodash"
import { withStyles } from "@material-ui/core/styles"

const PurpleSwitch = withStyles({
  switchBase: {
    color: grey[400],
    "&$checked": {
      color: blue[500],
    },
    "&$checked + $track": {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

export const defaultRenderInputField = (props) => {
  const {
    item,
    updateData,
    setTextField,
    getTextField,
    isAnyChanges,
    isError,
    setAllowPrompt = () => { },
    value
  } = props;
  switch (item.inputType) {
    case "number":
      return (
        <input
          value={item.value}
          type="number"
          name={item.identifer}
          onChange={(event) => {
            updateData({ value: event.target.value, data: item })
          }}
        />
      )
    case "text":
      let isEmpty =
        getTextField &&
        getTextField[item.identifer] &&
        getTextField[item.identifer] === "empty"

      return (
        <div>
          <input
            validateOnChange={true}
            value={item.value}
            type="text"
            name={item.identifer}
            onChange={(event) => {
              const inputValue = event.target.value
              const obj = {}
              obj[item.identifer] = inputValue || "empty"
              setTextField({ ...getTextField, ...obj })
              updateData({ value: inputValue, data: item })
            }}
          />
          {isEmpty && <div style={{ color: "red" }}>{"In Valid"}</div>}
        </div>
      )
    case "select":
      let selectedValue = _.isPlainObject(_.get(item, 'value')) ? _.find(_.get(item, 'selectItems', []), o => _.get(item, 'value.id', '-_-') === _.get(o, 'id', '*_*')) : item.value
      return (
        <>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Select Customer Communication"
            name={item.identifer}
            style={{ minWidth: "320px" }}
            id="select title"
            value={selectedValue}
            error={isError && item.value === '-'}
            helperText={isError && item.value === '-' && item.defaultValue}
            onChange={(event) => {
              if (selectedValue !== null) {
                setAllowPrompt(false)
              }
              updateData({ value: event.target.value, data: item })
            }}

            select
          >
            {item?.defaultValue &&
              <MenuItem value='-' key={item} disabled>
                {item?.defaultValue}
              </MenuItem>}
            {item?.selectItems?.map((item, i) => (
              <MenuItem value={item} key={item}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </>
      )
    case "dropdown":
      return (
        <Adm.DropdownKit list={item} defaultValue={item.defaultValue} label="name" />
      )
    case "toggle":
      return (
        <PurpleSwitch
          checked={item.value}
          value={item.value}
          name={item.identifer}
          onChange={() => {
            updateData({ value: !item.value, data: item })
          }}
        />
      )
    case "tabtextfield":
      const attr = {
        activeMenuIdx: item.selectItems.indexOf(item.tabTypevalue),
        defaultValue: item.value,
        list: item.selectItems || [],
        onUpdate: (tabtextfieldProps) => {
          setTextField({ type: tabtextfieldProps.type })
          updateData({
            tabTypevalue: tabtextfieldProps.type,
            value: tabtextfieldProps.value,
            data: item,
          })
        },
      }
      return <Adm.TabTextField {...attr} />
    case "checkBox":
      return (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Checkbox
            checked={item.value}
            onChange={() => {
              updateData({ value: !item.value, data: item })
            }}
            name="checkedB"
            color="primary"
            style={{ paddingLeft: "0px" }}
          />
          <Typography variant="body1" style={{ marginRight: "30px" }}>
            {item.checkBoxLabel}
          </Typography>
        </Grid>
      )
    case "quantity":
      const { value } = item
      return (
        <Adm.Quantity
          minLimt={value}
          onUpdate={({ isQuantity = 1 }) => {
            updateData({
              value: isQuantity,
              data: item,
            })
          }}
        />
      )

    case "calendar":
      return <Typography style={{ width: "200px" }}>{item.value}</Typography>

    default:
      return item.value
  }
}

export const defaultRenderText = (item) => {
  switch (item.textType) {
    case "box":
      return (
        <Box
          style={{
            color: item.value ? "white" : "black",
            backgroundColor: item.value ? "#4CAF50" : "#E0E0E0",
            padding: "4px 6px",
            borderRadius: "4px",
          }}
        >
          <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
            {item.value ? item.renderTextTrue : item.renderTextFalse}
          </Typography>
        </Box>
      )
    case "addOnChip":
      return (
        <Grid container direction="row">
          <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
            {item.value}
          </Typography>
          <Chip
            label={item.addOnText}
            style={{
              marginLeft: "35px",
              backgroundColor: "#1565C0",
              color: "white",
            }}
          />
        </Grid>
      )
    case "tabtextfield":
      return (
        <Typography
          style={{ width: "200px", fontSize: "14px", fontWeight: "400" }}
        >
          {`${item.tabTypevalue} ${item.value}`}
        </Typography>
      )
    default:
      return (
        <Typography
          style={{ width: "200px", fontSize: "14px", fontWeight: "400" }}
        >
          {_.isPlainObject(_.get(item, 'value')) ? _.get(item, 'value.name', '') : item.value}
        </Typography>
      )
  }
}
