import styled from 'styled-components'

export const Wrapper = styled('div')`
  display: flex;
  padding: 6px 8px 4px;
  ${props => props?.width ? `width: ${props.width}` : ''}

  ${props => props.border ? `
    border-radius: 5px;
    border: 1px solid black;
  `: ''}
`

export const QueryPara = styled('div')`
  font-size: 17px;
  font-weight: 600;
`
export const IconDiv = styled('div')`
  margin-left: 4px;
`
export const SpaceDiv = styled('div')`
  margin: 6px;
`
export const List = styled('ul')`
  list-style-type: none;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding: 0;
  li {
    margin: 7px;
  }
`
