/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLatest, cancelled, select, takeEvery, all } from 'redux-saga/effects'
import Axios from 'axios'
import _ from 'lodash'
import api from '../api';
import { ReduxConstants } from '@constants';
const { LOC_ACTION_TYPES, LOC_SAGA_ACTIONS: SAGAS } = ReduxConstants.REDUX_CONSTANTS;



function* fetchAllHierarchy(action) {
    yield put({ type: LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_PENDING });
    const cancelSource = Axios.CancelToken.source();

    try {
        const resp = yield call(api.getLocationHierarchy, cancelSource);
        yield put({ type: LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_FULFILLED, payload: _.get(resp, 'data.data', []) });
    } catch (err) {
        yield put({ type: LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_REJECTED, payload: err })
    } finally {
        if (yield cancelled()) {
            cancelSource.cancel('Cancelled');
        }
    }
}
function* watchFetchAllHierarchy() {
    yield takeLatest(SAGAS.FETCH_LOC_HIERARCHY, fetchAllHierarchy)
}

export default [
    watchFetchAllHierarchy(),
]
