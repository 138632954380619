import axios from "axios"
import { headerAxiosziptastic } from "../../../services/common"

const getZipcodeDetails = async (zipcode, countryCode = "US") => {
  let url = "https://zip.getziptastic.com"
  let urlVersion = "v3"

  try {
    let response = await headerAxiosziptastic.get(
      `${url}/${urlVersion}/${countryCode}/${zipcode}`
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getVerifyAPI = (zipcode, countryCode) => {
  return new Promise((resolve, reject) => {
    getZipcodeDetails(zipcode, countryCode).then(response => {

      if (response.hasOwnProperty('data')) {
        resolve(response.data)
      } else {
        reject('Not found')
      }
    }).catch(error => {
      reject(error)
    })
  })
}