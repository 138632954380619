import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
import { CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { Helmet } from "react-helmet"
import { Grid, IconButton, Card, Collapse, TextField, Card as MuiCard, CardMedia } from "@material-ui/core"
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone"
import { spacing } from "@material-ui/system"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import { CheckCircleIcon, ChevronUp, ChevronDown, Save, Slash, Edit } from "@iconsGallery"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "@utils"
import * as ServiceAPI from "@services"
import { useDispatch, useSelector } from "react-redux"
import * as constantsAPI from "@constants"
import { getTreeFromFlatData, getFlatDataFromTree } from "react-sortable-tree"
import _, { set } from "lodash"
import * as Adm from "@adm"
import * as Features from "@features"
import { APIUrl } from "@utils"
import DraggableTreeView from "../../components/DraggableTreeView"
import { CategoryForm } from "./CategoryForm"
import productsAPI, { productDataApiMethods } from '../../services/masterData/products/api';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { getProcessedFlatData, getNewFlatDataWithOldExpandedStatus, getNodeId, getNodeKey, getParentNodeId, getTreeData, getFlatData, getAllChildrenIds } from '../../components/DraggableTreeView/treeDataUtils'
import { deleteCategory, fetchAllCategories } from "../../services/masterData/products/actions"

const { API_URL_STORES } = APIUrl || {}
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {};
const getTreeDataFxn = (x, idPath, parentIdPath) => getTreeData(x, n => _.get(n, idPath, _.get(n, `node.${idPath}`, undefined)), n => _.get(n, parentIdPath, ''), '')
const initialFormProps = { isOpen: false, data: {}, childNodeAvailable: false };
const initSnackbarProps = { isOpen: false, message: '', type: 'info', autoHideDuration: 2500 };

const PageHeaderBreadcrumbKit = styled(Grid)`
 button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 24px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 15px;
`
function ProductCategoryDetail(props) {
  const { isWriteAllowed, isViewMode = false } = props;
  const dispatch = useDispatch()
  const { categories } = useSelector(state => _.get(state, 'productsReducer', {}))
  const [formProps, setFormProps] = useState({ ...initialFormProps });
  const [flatTreeData, setFlatTreeData] = useState(() => { return categories?.list || [] });
  const [treeData, setTreeData] = useState(getTreeDataFxn(categories?.list || [], 'categoryId', 'mapped_parent_categories'))
  const [processedFlatData, setProcessedFlatData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [snackbarProps, setSnackbarProps] = useState(initSnackbarProps)
  const [height, width] = useWindowSize()
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [data, setData] = useState([])
  const formData = useSelector((state) => state.addLocationReducer)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [allowAlcoholUpdate, setAllowAlcoholUpdate] = useState(false)
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [open, setOpen] = useState(false)
  const [selectedNodeId, setSelectedNodeId] = useState()
  const nodeToBeKeptInExpandedStatus = useRef(undefined);
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleFormClose = () => {
    setFormProps({ ...initialFormProps });
  }
  const BreadcrumbList = [
    {
      name: "Master Data",
      disable: true,
    },
    {
      name: "Products",
    },
    {
      name: "Hierarchy Editor"

    }
  ]

  const handleFormOpen = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    setFormProps(c => ({
      ...c, isOpen: true, mode: 'create', data: {},
    }))
  }

  const handleViewOpen = (categoryData, parentNode) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    if (props.isComInProductMapping) {
      setSelectedNodeId(categoryData._id);
      props.setCategory(categoryData)
    }
    else {
      let responseData = {};
      productDataApiMethods.getProductCategoryById(categoryData.categoryId).then(resp => {
        responseData = _.get(resp, 'data.data.productCategory', {})
        const parentCategoryName = treeData.find(({ categoryId }) => categoryId === categoryData.mapped_parent_categories);
        let attributesArrFormData = []
        !_.isEmpty(responseData?.attributes) && _.map(responseData?.attributes, (x) => {
          _.map(responseData.category.attributes, (o) => {
            if (x._id === o.attributeId) {
              attributesArrFormData.push({
                attribute_name: x.attribute_name,
                attribute_field_type: x.attribute_field_type,
                attribute_values: x.attribute_values,
                values: (x.attribute_field_type === "Multi Choice") ? o?.attributeValue :
                  (x.attribute_field_type === "List" || x.attribute_field_type === "Single Choice") ? _.filter(x.attribute_values, _.matches({ "_id": o.attributeValue[0] }))?.[0] :
                    x.attribute_field_type === "Boolean" ? o?.attributeValue[0] === "true" ? true : false : (x.attribute_field_type === "Text Box" || "Text Area" || "Date") ? o?.attributeValue[0] : o?.attributeValue,
                attributeId: x._id,
                _id: x._id,
                isDeleted: o.isDeleted,
              })
            }
          })
        })
        setFormProps({
          isOpen: true, mode: 'view',
          data: {
            ...responseData.category,
            locationIds: responseData.locations?.data?.docs || [],
            attributes: attributesArrFormData || [],
            parent_name: _.isObject(parentNode) ? _.get(parentNode, 'category_name') : parentNode,
            childNodeAvailable: categoryData.children ? true : false
          },
          openNewNode: {}
        })
      }).catch(err => {
      })
    }
  }

  const handleAddNode = (parent) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    nodeToBeKeptInExpandedStatus.current = _.get(parent, 'id');
    setFormProps({ isOpen: true, mode: 'create', data: { mapped_parent_categories: _.get(parent, 'id'), parent_name: _.get(parent, 'name'), parent_obj: parent, cancelCallback: () => { nodeToBeKeptInExpandedStatus.current = undefined } } })
  }

  const handleNodeDelete = (id) => {
    productDataApiMethods.deleteProductCategory(id.id).then(resp => {
      fetchNewFlatTreeData();
      handleFormClose();
      setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: `Product category: "${_.get(id, 'name', '')}" archived successfully`, type: 'success' })
    }).catch(err => {
      setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: _.get(err, 'data.message', `Sorry! Delete operation failed.`), type: 'error' })
    })
  }

  const fetchNewFlatTreeData = (oldFlatData = getFlatData(treeData)) => {
    dispatch(fetchAllCategories())
  }

  const adopterFunction = (data) => {
    let formData = new FormData();
    formData.append('payload', JSON.stringify(data))
    return formData
  }

  const handleNodeMove = (newParentData, nodeData) => {
    let tempParentID = _.compact([newParentData?.categoryId, ...(newParentData?.mapped_parent_categories || [])]); 
    nodeData.mapped_parent_categories = tempParentID[0];
    let tempData = adopterFunction(nodeData);
    setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: 'Hold On! Updating...', type: 'info', icon: <CircularProgress color="secondary" /> })
    productDataApiMethods.updateCategory(nodeData?.categoryId, tempData).then(resp => {
      fetchNewFlatTreeData(getFlatData(treeData))
      setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: 'Updated Successfully!', type: 'success' })
    }).catch(err => {
      setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: _.get(err, 'data.message', `Sorry! Update operation failed.`), type: 'error' })
    })
  }

  const handleCheckboxClick = (node, parentNode) => {
    let exstIdx = _.findIndex(selectedCategories, o => o === node?.categoryId);
    let childrenIds = getAllChildrenIds(node) || [];
    let allIdsOfNode = [node?.categoryId, ...childrenIds];
    let temp;
    if (exstIdx === -1) {
    } else {
      temp = _.without(selectedCategories, ...(allIdsOfNode || []))
    }
    setSelectedCategories(temp);
    setProcessedFlatData(getProcessedFlatData({ data: getFlatData(treeData), checkedList: temp }))

  }



  useEffect(fetchNewFlatTreeData, []);

  useEffect(() => {
    setProcessedFlatData(getProcessedFlatData({ data: flatTreeData, checkedList: selectedCategories }));
  }, [flatTreeData]);

  useEffect(() => {
    setFlatTreeData(getNewFlatDataWithOldExpandedStatus(categories?.list || [], getFlatData(treeData), "categoryId", nodeToBeKeptInExpandedStatus.current, () => { nodeToBeKeptInExpandedStatus.current = undefined }))
  }, [categories.list])

  useEffect(() => {
    let temp = getTreeDataFxn(processedFlatData, 'categoryId', 'mapped_parent_categories')
    setTreeData(temp)
  }, [processedFlatData])

  return (
    <>
      <PageHeaderBreadcrumbKit
        container
        justify="space-between"
        alignContent="center"
        alignItems="center"
        className="PageHeaderBreadcrumbKit"
      >
        <Grid>
          <Adm.Breadcrumb list={BreadcrumbList} />
        </Grid>
      </PageHeaderBreadcrumbKit>
      <Helmet title="Hierarchy Editor" />
      <Grid container item xs={12} lg={12} md={12} sm={12} style={{ height: `100%`, }}>
        <Adm.BackdropOverlay open={loading} />
        <div style={{ borderBottom: "1px solid #E0E0E0", width: '100%' }}>
          <Typography style={{ fontWeight: 600, fontSize: '20px', padding: '10px 10px', background: "#fff" }}>Hierarchy Editor</Typography>
        </div>
        <Grid container style={{ background: '#ffff', height: `100%`, width: '100%', }}>
          <Grid item xs={formProps?.isOpen ? 5 : 12} sm={formProps?.isOpen ? 5 : 12} md={formProps?.isOpen ? 5 : 12} lg={formProps?.isOpen ? 5 : 12} style={{ display: 'flex', flexDirection: isMobile ? 'row' : "", overflowX: 'auto', }}>
            <DraggableTreeView
              BreadcrumbList={BreadcrumbList[2].name}
              isWriteAllowed={isWriteAllowed}
              isViewMode={isViewMode}
              treeData={treeData}
              onChangeInTreeData={setTreeData}
              handleCheckboxClick={handleCheckboxClick}
              onAddClick={handleFormOpen}
              flatTreeData={processedFlatData}
              lookupType={'Product'}
              handleAddNode={handleAddNode}
              handleNodeMove={handleNodeMove}
              handleNodeDelete={handleNodeDelete}
              onEditClick={(node) => {
                handleViewOpen(_.get(node, 'node'), _.get(node, 'parentNode'))
              }}
              selectedNodeId={selectedNodeId}
              isComInProductMapping={props.isComInProductMapping && props.isComInProductMapping}
              canDragPropInProductMapping={props.canDragPropInProductMapping && props.canDragPropInProductMapping}
              handlerParamsMaker={n => ({ id: _.get(n, 'node.categoryId'), name: _.get(n, 'node.category_name'), obj: _.get(n, 'node'), parentObj: _.get(n, 'parentNode') })}
              showAddRootNodeButton={props.showIcon === false ? false : true} />
          </Grid>
          {
            formProps?.isOpen &&
            <>
              <Grid item xs={7} sm={7} lg={7} md={7} xl={7} style={{ borderLeft: `1px solid #e0e0e0`, overflowX: "auto", width: "auto" }}>
                <CategoryForm formProps={formProps} setFormProps={setFormProps} onClose={handleFormClose} data={formProps?.data || {}} fetchNewFlatTreeData={fetchNewFlatTreeData} handleViewOpen={handleViewOpen} isWriteAllowed={isWriteAllowed} />
              </Grid>
              <Grid style={{ width: '100%', padding: '10px' }}>
              </Grid>
            </>
          }

        </Grid>
      </Grid>
      {snackbarProps?.isOpen && <Adm.SnackBar open={snackbarProps?.isOpen} message={snackbarProps?.message || 'Uh! May be wait!?'} type={snackbarProps?.type || 'info'} icon={snackbarProps?.icon || undefined} handleClose={() => setSnackbarProps(initSnackbarProps)} />}
    </>
  )
}
export { ProductCategoryDetail }
export default ProductCategoryDetail
