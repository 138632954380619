import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { Button } from '@material-ui/core'

const textEllipsis = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SZipWrapper = styled(Grid)``

export const Label = styled('div')`
  margin: 5px 7px;
  font-size: ${(p) => Number(p.fontSize)}px;
  font-weight: 800;
`

export const RequiredStar = styled('span')`
  color: #ff1414;
`

export const VerifyButton = styled(Button)`
  height: ${p => p.height}px;
  width: ${p => (Number(p.width) * 1 / 4)}px;
  min-width: 75px;
  font-size: ${p => p.fontSize}px;
  ${textEllipsis}
`

export const ErrorMsg = styled('span')`
  margin: 5px 12px;
  color: #f44336;
  width: ${p => ((Number(p.width) * 3 / 4) - 15)}px;
  min-width: 135px;
  font-size: ${(p) => Number(p.fontSize)}px;
  ${textEllipsis}
`
