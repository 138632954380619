//customized components are imported like <Cusotmized.componentName/> from styles folder
import React from "react"
import { withTheme } from "styled-components/macro"

import { Grid, Hidden, Toolbar, Badge } from "@material-ui/core"

import * as Customized from "../../styles/components/AppBar/styles"

import { MenuIcon, SearchOutlined, Inbox } from "@iconsGallery"

import LanguagesDropdown from "../LanguagesDropdown"
import UserDropdown from "../UserDropDown/UserDropdown"

import { useTranslation } from "react-i18next"

const AppBarComponent = ({ onDrawerToggle }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Customized.AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <Customized.IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </Customized.IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <Customized.Search>

              </Customized.Search>
            </Grid>
            <Grid item>
              <Customized.IconButton onClick={() => { }} color="inherit">
              </Customized.IconButton>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </Customized.AppBar>
    </React.Fragment>
  )
}

export default withTheme(AppBarComponent)
