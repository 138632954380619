import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
import {
  Grid, Typography as MuiTypography, IconButton, Card, Collapse, Tooltip
} from "@material-ui/core"
import { spacing } from "@material-ui/system"
import Divider from "@material-ui/core/Divider"
import {
  CheckCircleIcon, ChevronUp, ChevronDown, Slash, Edit,
} from "@iconsGallery"
import { AlcholTradingHours } from "./AlcholTradingHours"
import { useTranslation } from "react-i18next"
import * as ServiceAPI from "@services"
import { useSelector } from "react-redux"
import * as constantsAPI from "@constants"
import _ from "lodash"
import * as Adm from "@adm"
import * as Features from "@features"
import { BusinessHours } from "./BusinessHours"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const AccordionInnerMainWrapper = styled(Grid)`
  padding-left: 16px;
  padding-right: 16px;
  align-items: normal;
  alignitems: center;
  flex-direction: column;
  .cls-businesshours,
  .cls-alcholtradinghours {
    padding: 0px;
  }
  @media (min-width: 1024px) and (max-width: 1600px) {
    padding: 16px;
    flex-direction: column;
    .cls-businesshours{
    }
    .cls-businesshours,
    .cls-alcholtradinghours {
      padding: 8px;
    }
  }
`
const Typography = styled(MuiTypography)(spacing)
function OperatingHours(props) {
  const { isWriteAllowed, setAllowPrompt = () => { }, fetchData = () => { }, editMode = false, setFieldValue = () => { },
    values = {}, errors = {}, touched = {}, setAnyChanges = () => { }, setDaysArrState = () => { }, daysArrState = [],
    setTimeArrState = () => { }, timeArrState = [], daysError = [], setDaysError = () => { }
  } = props;
  const { t } = useTranslation()
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [data, setData] = useState([])
  const formData = useSelector((state) => state.addLocationReducer)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [allowAlcoholUpdate, setAllowAlcoholUpdate] = useState(false)
  const [allowBusinessUpdate, setAllowBusinessUpdate] = useState(false)
  const [allowUpdate, setAllowUpdate] = useState(false)
  const isAllOkay = useRef(true)
  const [clearVal, setClearVal] = useState(false)
  const isBusinessHoursTimeOkay = useRef(true)
  const isAlcoholHoursTimeOkay = useRef(true)
  const saveHandler = () => {
    if (
      isAllOkay.current &&
      isBusinessHoursTimeOkay.current &&
      isAlcoholHoursTimeOkay.current
    ) {
      setAllowUpdate(true)
      sendData()
    } else {
      setAlertType("error")
      setAlertMessage("Issue highlighted in operating hours set, recheck")
      setAlert(true)
    }
  }
  const sendData = async () => {
    setLoading(true)
    setAlert(false)
    let body = {
      ...props.sendBackData,
      businessHrs: formData.operatingHoursBusiness,
      alcoholTradingHrs: formData.operatingHoursAlchol,
      openDate: ""
    }
    delete body["storageunit_ids"]

    let response = await ServiceAPI.fetchStoreCommonData(
      API_END_POINTS.updateLocation,
      body
    )
    await setAlertType(_.get(response, "data.status", "error"))
    await setAlertMessage(_.get(response, "data.message", "Try Again Later"))
    await setAllowPrompt(false)
    await setAlert(true)
    await setLoading(false)
  }

  useEffect(() => {
    setData(props.data)
  }, [props])

  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Adm.BackdropOverlay open={loading} />
      <Card>
        <AccordionInnerMainWrapper container direction="row">
          <Grid
            item
            xl={8}
            lg={8}
            md={12}
            sm={12}
            xs={12}
            className="cls-businesshours"
          >
            <BusinessHours
              editMode={editMode}
              setUpdate={setAllowBusinessUpdate}
              creatingTheBusinnessHours={true}
              data={data}
              setAllowUpdate={setAllowUpdate}
              isAllOkayRef={isAllOkay}
              isTimeArrOkayRef={isBusinessHoursTimeOkay}
              setAllowPrompt={setAllowPrompt}
              clearVal={clearVal}
              setClearVal={setClearVal}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              touched={touched}
              setAnyChanges={setAnyChanges}
              setDaysArrState={setDaysArrState}
              daysArrState={daysArrState}
              setDaysError={setDaysError}
              daysError={daysError}
              setTimeArrState={setTimeArrState}
              timeArrState={timeArrState}
            />
          </Grid>
        </AccordionInnerMainWrapper>
      </Card>
      {alert ? (
        <Adm.SnackBar
          open={true}
          message={alertMessage}
          type={alertType}
          handleClose={() =>
            setTimeout(() => {
              setAlert(false)
            }, 1000)
          }
        />
      ) : null}
    </Grid>
  )
}
export { OperatingHours, BusinessHours, AlcholTradingHours }
export default OperatingHours
