import React, { useState, useEffect, useCallback, useRef } from 'react'
import { makeStyles, Typography, ButtonGroup, Button, ClickAwayListener, Icon, FormControl } from '@material-ui/core'
import { format, isSameDay, isValid } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import _ from 'lodash'
import { defaultStaticRanges, defaultInputRanges } from './../../FiltersDrawer/defaultDateRanges'

const useStyles = makeStyles((theme) => ({

    calendarWrapper: {
        position: 'absolute',
        top: 'calc(100% + 4px)',
        left: '0%',
        zIndex: 5,
        padding: theme.spacing(2),
        background: theme.palette.almostBlack[100],
        boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[600]}`,
        borderRadius: '10px',

        '& .rdrDefinedRangesWrapper': {
            borderRadius: '10px 0px 0px 10px',
            '& .rdrStaticRanges': {
                borderRadius: '10px 0px 0px 0px',
                '& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
                    borderRadius: '10px 0px 0px 0px',
                    '& .rdrStaticRangeLabel': {
                        borderRadius: '10px 0px 0px 0px',
                        color: "var(--primaryColor)"
                    },
                },
                '& .rdrStaticRangeSelected': {
                    color: "var(--primaryColor) !important"
                },
                '& .rdrStaticRangeLabel:hover': {
                    backgroundColor: "var(--lightColor)"
                },
            },
        },
        '& .rdrCalendarWrapper': {
            borderRadius: '0px 10px 10px 0px',
            '& .rdrDateDisplayWrapper': {
                borderRadius: '0px 10px 0px 0px',
                '& .rdrDateDisplayItemActive': {
                    borderColor: "var(--primaryColor)"
                }
            },
            '& .rdrDay.rdrDayToday': {
                color: "var(--primaryColor) !important",
                '& .rdrStartEdge.rdrEndEdge': {
                    color: "var(--primaryColor) !important",
                }
            },

            '& .rdrStartEdge': {
                color: "var(--primaryColor) !important",
            },
            '& .rdrInRange': {
                color: "var(--primaryColor) !important",
            },
            '& .rdrEndEdge': {
                color: "var(--primaryColor) !important",
            },
            '& .rdrDayHovered': {
                outline: "var(--primaryColor) !important",
                borderColor: "var(--primaryColor) !important",

            },
        },
    },
    dateButtonText: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        fontSize: 14,
        fontWeight: 400
    },
}))

var clickedTimes = 0

const getProperDate = (actualDate, fallback) => {
    if (isValid(new Date(actualDate))) {
        return new Date(actualDate)
    }
    return fallback;
}

const DateRangeInput = ({ initialDates, handleDateChange }) => {
    const classes = useStyles()

    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    const [dates, setDates] = useState(() => {
        if (isValid(new Date(_.get(initialDates, 'startDate'))) && isValid(new Date(_.get(initialDates, 'endDate')))) {
            return [
                {
                    startDate: getProperDate(_.get(initialDates, 'startDate'), new Date()),
                    endDate: getProperDate(_.get(initialDates, 'endDate'), new Date()),
                    key: 'dates',
                },
            ]
        }
        return [{ key: 'dates', startDate: undefined, endDate: undefined }]
    })
    const cancelUpdate = useRef();

    useEffect(() => {
        if (isValid(new Date(_.get(initialDates, 'startDate'))) && isValid(new Date(_.get(initialDates, 'endDate')))) {
            if (!isSameDay(new Date(_.get(dates, '[0].startDate', '')), new Date(_.get(initialDates, 'startDate', ''))) || !isSameDay(new Date(_.get(dates, '[0].endDate', '')), new Date(_.get(initialDates, 'endDate', '')))) {
                cancelUpdate.current = true;
                setDates([
                    {
                        key: 'dates',
                        startDate: new Date(initialDates?.startDate),
                        endDate: new Date(initialDates?.endDate),
                    },
                ])
            }
        }
        else {
            setDates([
                {
                    key: 'dates',
                    startDate: undefined,
                    endDate: undefined,
                },
            ])
        }
    }, [initialDates])

    const handleChange = (dateObj) => {
        clickedTimes = clickedTimes + 1
        const hasStartDateChanged = isValid(_.get(dates, '[0].startDate')) ? format(dateObj.dates.startDate, 'dd-MM-yyyy') !== format(dates[0].startDate, 'dd-MM-yyyy') : true
        const hasEndDateChanged = isValid(_.get(dates, '[0].endDate')) ? format(dateObj.dates.endDate, 'dd-MM-yyyy') !== format(dates[0].endDate, 'dd-MM-yyyy') : true

        if (hasStartDateChanged || hasEndDateChanged) {
            setDates([
                {
                    key: 'dates',
                    startDate: Object.values(dateObj)[0].startDate,
                    endDate: Object.values(dateObj)[0].endDate,
                },
            ])
        }

        if (
            dateObj.dates.key !== 'dates' ||
            (hasStartDateChanged && hasEndDateChanged && clickedTimes >= 2) ||
            (!hasStartDateChanged && clickedTimes >= 2) ||
            (!hasEndDateChanged && clickedTimes >= 2)
        ) {
            if (dateObj.dates.key !== 'custom range') {
                clickedTimes = 0
                setIsCalendarOpen(false)
            }
        }
    }

    useEffect(() => {
        if (!cancelUpdate.current && handleDateChange) {
            handleDateChange(dates[0])
        } else {
            cancelUpdate.current = false
        }
    }, [dates])

    useEffect(() => {
        if (!isCalendarOpen) {
            clickedTimes = 0
        }
    }, [isCalendarOpen, clickedTimes])

    function formatDateForLabel(dateString, isReturnAsDate) {
        let dt = new Date(dateString)
        let today = new Date()
        let todayStr = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
        let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
        let yesterdayStr = yesterday.getDate() + '/' + (yesterday.getMonth() + 1) + '/' + yesterday.getFullYear()
        let dtStr = dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()
        if (_.isEqual(todayStr, dtStr) && !isReturnAsDate) {
            return 'Today'
        } else if (_.isEqual(yesterdayStr, dtStr) && !isReturnAsDate) {
            return 'Yesterday'
        } else {
            return dtStr
        }
    }

    function dateLabel(dateObj) {
        if (!isValid(_.get(dateObj, 'startDate'))) {
            return (<Typography variant='subtitle2' className={classes.dateButtonText}>No Dates Selected</Typography>)
        }
        else if (formatDateForLabel(dateObj.startDate) === formatDateForLabel(dateObj.endDate)) {
            return (
                <Typography variant='subtitle2' className={classes.dateButtonText}>
                    {formatDateForLabel(dateObj.endDate)}
                </Typography>
            )
        } else {
            return (
                <>
                    <Typography variant='subtitle2' className={classes.dateButtonText}>
                        {formatDateForLabel(dateObj.startDate, true)}
                    </Typography>
                    -
                    <Typography variant='subtitle2' className={classes.dateButtonText}>
                        {formatDateForLabel(dateObj.endDate, true)}
                    </Typography>
                </>
            )
        }
    }

    return (
        <FormControl margin='dense' style={{ position: 'relative' }} variant='outlined' fullWidth>
            <ButtonGroup variant='outlined' size='medium' color='default' className={classes.buttonGroup} style={{ height: 36 }} fullWidth>
                <Button onClick={() => setIsCalendarOpen(!isCalendarOpen)}>{dateLabel(dates[0])}</Button>
                <Button style={{ width: 36 }} onClick={() => setIsCalendarOpen(!isCalendarOpen)} size='small'>
                    <Icon fontSize='small' style={{ fontSize: 20 }}>{isCalendarOpen ? 'close' : 'date_range'}</Icon>
                </Button>
            </ButtonGroup>
            {isCalendarOpen ? (
                <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)}>
                    <DateRangePicker
                        className={classes.calendarWrapper}
                        onChange={(item) => {
                            handleChange(item)
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={dates}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        maxDate={new Date()}
                        direction='horizontal'
                    />
                </ClickAwayListener>
            ) : null}
        </FormControl>
    )
}

export default DateRangeInput
export { DateRangeInput }
