import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import * as Adm from "@adm"

import {
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  TablePagination,
  IconButton,
  TextField,
  Box,
  TableContainer,
  Typography,
  Popover,
  ListItem,
  InputAdornment,
} from "@material-ui/core"

import { spacing } from "@material-ui/system"
import {
  Eye,
  Trash,
  MoreVertical,
  Save,
  Archive,
  Filter,
  Slash,
  Edit,
  ChevronUp,
  ChevronDown,
} from "@iconsGallery"
import EnhancedTableHead from "./tableHead"
import EnhancedTableToolbar from "./tableToolBar"
import customTableStyles from "../../styles/components/customTable/styles"

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)

const Paper = styled(MuiPaper)`
  border-radius: 0px;
`

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function CustomTable(props) {
  const classes = customTableStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const Title = props.title
  const header = props.header
  const dataCell = props.data
  const tableType = props.tableType
  const filter = props.filter
  const searchData = props.searchData
  const [edited, setEdited] = useState(0)
  const [expandOpen, setExpandOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [InputData, setInputData] = useState({})
  const [selected, setSelected] = React.useState([])
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("location")

  //it is used to close the inline textfield after updating correctly
  useEffect(() => {
    setEdited(0)
    setInputData({})
  }, [props.resetEdit])

  //it is used to clear the selected ones after deleting multiple
  useEffect(() => {
    setSelected([])
  }, [props.deSelect])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataCell.map((n) => n._id)

      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const isSelected = (_id) => {
    return selected.indexOf(_id) !== -1
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6} className={classes.card}>
            <CardContent pb={1}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                selected={selected}
                actionDelete={props.deleteMultiple}
                title={Title}
                filter={filter}
              />
            </CardContent>
            <Paper>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  aria-labelledby={Title}
                  aria-label={Title}
                  size="small"
                >
                  {/* header part starts here */}
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={dataCell.length}
                    header={header}
                    tableType={tableType}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  {/* table data starts here */}
                  {/* collpase table renders here */}
                  {tableType === "collapse" ? (
                    <TableBody data-testid="Record">
                      <TableRow>
                        {header.map((item, i) => (
                          <>
                            {/* this for not allowing header name of action with table type inLineUpdate */}
                            {item.label === "Action" ||
                              item.label === "Collapse" ||
                              item.label === "CheckBox" ? (
                              <TableCell align="left" key={i}></TableCell>
                            ) : (
                              <TableCell
                                align="left"
                                key={i}
                                style={{
                                  borderLeft: "1px solid #E0E0E0",
                                  borderRight: "1px solid #E0E0E0",
                                }}
                              >
                                <TextField
                                  onChange={(event) => {
                                    searchData({
                                      key: item.dataKey,
                                      value: event.target.value,
                                    })
                                  }}
                                  style={{ borderColor: "E0E0E0" }}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      borderWidth: "10px",
                                      borderColor: "yellow !important",
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Filter />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            )}
                          </>
                        ))}
                      </TableRow>
                      {stableSort(dataCell, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id)
                          const labelId = `enhanced-table-checkbox-${index}`
                          return (
                            <TableRow
                              key={row._id}
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}
                            >
                              {/* dynamic datas with input field and static data */}
                              {header.map((item, i) =>
                                item.label === "Action" ? (
                                  <TableCell
                                    align="right"
                                    key={i}
                                    style={{ width: "80px" }}
                                  >
                                    <Grid>
                                      <IconButton
                                        aria-describedby={id}
                                        key={row._id}
                                        onClick={(event) => {
                                          setAnchorEl(event.currentTarget)
                                          setInputData(row)
                                        }}
                                      >
                                        <MoreVertical
                                          size={20}
                                          style={{
                                            color: "black",
                                          }}
                                        />
                                      </IconButton>
                                      <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={() => {
                                          setAnchorEl(null)
                                        }}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        <ListItem onClick={() => { }}>
                                          <Edit
                                            size={20}
                                            style={{
                                              color: "rgba(158, 158, 158, 1)",
                                              marginRight: "10px",
                                            }}
                                          />
                                          Edit
                                        </ListItem>
                                        <ListItem
                                          onClick={() => {
                                            props.deleteOne(InputData)
                                          }}
                                        >
                                          <Archive
                                            size={20}
                                            style={{
                                              color: "rgba(158, 158, 158, 1)",
                                              marginRight: "10px",
                                            }}
                                          />
                                          Archive
                                        </ListItem>
                                      </Popover>
                                    </Grid>
                                  </TableCell>
                                ) : item.label === "CheckBox" ? (
                                  <TableCell align="left" key={i}>
                                    <Checkbox
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      onClick={(event) =>
                                        handleClick(event, row._id)
                                      }
                                    />
                                  </TableCell>
                                ) : item.label === "Collapse" ? (
                                  <TableCell
                                    align="left"
                                    key={i}
                                    style={{ width: "40px" }}
                                  >
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      key={row._id}
                                      onClick={() => setExpandOpen(!expandOpen)}
                                    >
                                      {expandOpen ? (
                                        <ChevronUp />
                                      ) : (
                                        <ChevronDown />
                                      )}
                                    </IconButton>
                                  </TableCell>
                                ) : item.label === "Status" ? (
                                  <TableCell
                                    align="left"
                                    key={i}
                                    className={classes.tableCell}
                                  >
                                    {row[item.dataKey] ? (
                                      <Box className={classes.enabledBox}>
                                        Active
                                      </Box>
                                    ) : (
                                      <Box className={classes.disabledBox}>
                                        Inactive
                                      </Box>
                                    )}
                                  </TableCell>
                                ) : item.label === "Make It Default" ? (
                                  <TableCell
                                    align="left"
                                    key={i}
                                    className={classes.tableCell}
                                  >
                                    {row[item.dataKey]
                                      ? "Default"
                                      : "No Default"}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    key={i}
                                    className={classes.tableCell}
                                  >
                                    {row[item.dataKey]}
                                  </TableCell>
                                )
                              )}
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  ) : (
                    <TableBody data-testid="Record">
                      {dataCell
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id)
                          const labelId = `enhanced-table-checkbox-${index}`
                          return (
                            <TableRow
                              key={row._id}
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}
                            >
                              {/* dynamic datas with input field and static data */}
                              {header.map((item, i) =>
                                edited === row._id ? (
                                  //this will render when edit is on
                                  item.type === "input" ? (
                                    <TableCell
                                      align="left"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      <TextField
                                        variant="outlined"
                                        size="small"
                                        value={InputData[item.dataKey]}
                                        onChange={(e) => {
                                          setInputData({
                                            ...InputData,
                                            [item.dataKey]: e.target.value,
                                          })
                                        }}
                                      />
                                    </TableCell>
                                  ) : item.type === "radio" ? (
                                    <TableCell
                                      align="left"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      {/* <RadioGroup
                                        row
                                        aria-label="status"
                                        name="status"
                                        value={InputData[item.dataKey]}
                                        onChange={(e) => {
                                          setInputData({
                                            ...InputData,
                                            [item.dataKey]: !InputData[
                                              item.dataKey
                                            ],
                                          });
                                        }}
                                      >
                                        <FormControlLabel
                                          value={true}
                                          control={<Radio />}
                                          label="Enabled"
                                        />
                                        <FormControlLabel
                                          value={false}
                                          control={<Radio />}
                                          label="Disabled"
                                        />
                                      </RadioGroup> */}
                                      <Adm.ToggleButton
                                        name="Enable"
                                        checked={InputData[item.datakey]}
                                        value={InputData[item.datakey]}
                                        handleChange={(e) => {
                                          setInputData({
                                            ...InputData,
                                            [item.dataKey]: !InputData[
                                              item.dataKey
                                            ],
                                          })
                                        }}
                                        option_1="Disable"
                                        option_2="Enable"
                                      />
                                    </TableCell>
                                  ) : item.label === "Make It Default" ? (
                                    <TableCell
                                      align="left"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      <FormControlLabel
                                        value={InputData[item.dataKey]}
                                        control={
                                          <Checkbox
                                            checked={InputData[item.dataKey]}
                                            onChange={(e) => {
                                              setInputData({
                                                ...InputData,
                                                [item.dataKey]: !InputData[
                                                  item.dataKey
                                                ],
                                              })
                                            }}
                                          />
                                        }
                                        label="Default"
                                      />
                                    </TableCell>
                                  ) : item.type === "checkbox" ? (
                                    <TableCell align="left" key={i}>
                                      <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                        onClick={(event) =>
                                          handleClick(event, row._id)
                                        }
                                      />
                                    </TableCell>
                                  ) : item.type === "action" ? (
                                    <TableCell
                                      align="right"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      <Grid>
                                        <IconButton
                                          onClick={() =>
                                            props.updateOne(InputData)
                                          }
                                        >
                                          <Save
                                            size={20}
                                            style={{
                                              color: "rgba(158, 158, 158, 1)",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            setEdited(0)
                                            setInputData({})
                                          }}
                                        >
                                          <Slash
                                            size={20}
                                            style={{
                                              color: "rgba(158, 158, 158, 1)",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            props.deleteOne(row)
                                          }}
                                        >
                                          <Trash
                                            size={20}
                                            style={{
                                              color: "rgba(158, 158, 158, 1)",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="left"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      {row[item.dataKey]}
                                    </TableCell>
                                  )
                                ) : // this will render when there is no edit
                                  item.label === "Action" ? (
                                    <TableCell
                                      align="right"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      <Grid>
                                        <IconButton
                                          onClick={() => {
                                            setEdited(row._id)
                                            setInputData(row)
                                          }}
                                        >
                                          <Edit
                                            size={20}
                                            style={{
                                              color: "rgba(158, 158, 158, 1)",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            props.deleteOne(row)
                                          }}
                                        >
                                          <Trash
                                            Edit
                                            size={20}
                                            style={{
                                              color: "rgba(158, 158, 158, 1)",
                                            }}
                                          />
                                        </IconButton>
                                        {props.View && (
                                          <IconButton
                                            onClick={() => {
                                              props.viewHandler(row)
                                            }}
                                          >
                                            <Eye
                                              Edit
                                              size={20}
                                              style={{
                                                color: "rgba(158, 158, 158, 1)",
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </Grid>
                                    </TableCell>
                                  ) : item.label === "CheckBox" ? (
                                    <TableCell align="left" key={i}>
                                      <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                        onClick={(event) =>
                                          handleClick(event, row._id)
                                        }
                                      />
                                    </TableCell>
                                  ) : item.label === "Status" ? (
                                    <TableCell
                                      align="left"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      {row[item.dataKey] ? (
                                        <Box className={classes.enabledBox}>
                                          Enabled
                                        </Box>
                                      ) : (
                                        <Box className={classes.disabledBox}>
                                          Disabled
                                        </Box>
                                      )}
                                    </TableCell>
                                  ) : item.label === "Make It Default" ? (
                                    <TableCell
                                      align="left"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      {row[item.dataKey]
                                        ? "Default"
                                        : "No Default"}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="left"
                                      key={i}
                                      className={classes.tableCell}
                                    >
                                      {row[item.dataKey]}
                                    </TableCell>
                                  )
                              )}
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {/* no record will render if there is no data */}
              {dataCell.length === 0 ? (
                <Box textAlign="center">
                  <Typography
                    className={classes.headFont}
                    id="tableTitle"
                    data-testid="noRecord"
                    style={{ padding: "10px" }}
                  >
                    No records to show
                  </Typography>
                </Box>
              ) : null}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                component="div"
                count={dataCell.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export { CustomTable }
export default CustomTable
