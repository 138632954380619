import * as React from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from "@material-ui/core"
import { CloseIcon } from "@iconsGallery"
import { Formik } from "formik"
import * as Yup from "yup"
import { Edit, Slash } from "@iconsGallery"
import _ from "lodash"
import { useFacetUIContentStyles } from "./styles"
import { useFacetUIViewState } from "./state"
import * as Utils from "@utils"
import styled from "styled-components"
import FormUI from "./FormUI"
import { facetUIYupSchema } from "./yupSchema"
import { transformValidationSchema, initialValues } from "./transformModal"

const ListWrapper = styled(List)`
  &.list-split {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
  }
`
const ListItemWrapper = styled(ListItem)`
  &.list-split-sub {
    width: 50%;
  }
`

const View = (props) => {
  const {
    listColumnSplit = false,
    edit: facetEdit = false,
    isAnyChanges,
    setAnyChanges,
    isError,
    setError,
    initialCount,
    setInitialCount,
    facetData = [],
    facetSetData = () => "",
    formSubmit = false,
    setAllowPrompt = () => { },
    dummy = false,
    updateFormSubmit = () => "",
    headingTitle,
  } = props || {}
  const classes = useFacetUIContentStyles()
  const { edit, setEdit, data, setData } = useFacetUIViewState({
    defaultData: facetData,
  })

  React.useEffect(() => {
    setData(facetData)
  }, [facetData])

  const doSingleDataUpdate = (item) => {
    if (item?.value !== null) {
      setAllowPrompt(true)
    }
    setData([...data])
    facetSetData([...facetData])
    setAnyChanges(true)
    setInitialCount(initialCount + 1)
  }

  const renderDynamicEditMode = () => {
    const getSchema = transformValidationSchema(data) || {}
    const getValues = initialValues(data) || {}

    const formSetAttr = {
      list: data,
      listColumnSplit,
      facetEdit,
      setAllowPrompt,
      doDataUpdate: doSingleDataUpdate,
      headingTitle,
      isAnyChanges,
      isError,
      setError,
      value: _.get(props, 'value', '')
    }

    return (
      <div className={classes.listProperty} style={{ overflow: "hidden" }}>
        <FormUI {...formSetAttr} setAllowPrompt={setAllowPrompt} />
      </div>
    )
  }

  return <div>{renderDynamicEditMode()}</div>
}
export { View }
export default View
