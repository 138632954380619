export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
}
export const REDUX_CONSTANTS = {
  THEME_SET: "THEME_SET",
  AUTH_SIGN_IN_REQUEST: "AUTH_SIGN_IN_REQUEST",
  AUTH_SIGN_IN_SUCCESS: "AUTH_SIGN_IN_SUCCESS",
  AUTH_SIGN_IN_FAILURE: "AUTH_SIGN_IN_FAILURE",
  AUTH_SIGN_OUT: "AUTH_SIGN_OUT",
  AUTH_SIGN_UP_REQUEST: "AUTH_SIGN_UP_REQUEST",
  AUTH_SIGN_UP_SUCCESS: "AUTH_SIGN_UP_SUCCESS",
  AUTH_SIGN_UP_FAILURE: "AUTH_SIGN_UP_FAILURE",
  AUTH_RESET_PASSWORD_REQUEST: "AUTH_RESET_PASSWORD_REQUEST",
  AUTH_RESET_PASSWORD_SUCCESS: "AUTH_RESET_PASSWORD_SUCCESS",
  AUTH_RESET_PASSWORD_FAILURE: "AUTH_RESET_PASSWORD_FAILURE",
  //Example Data
  GET_EXAMPLE_REQUEST: "GET_EXAMPLE_REQUEST",
  GET_EXAMPLE_STARTED: "GET_EXAMPLE_STARTED",
  GET_EXAMPLE_SUCCESS: "GET_EXAMPLE_SUCCESS",
  GET_EXAMPLE_FAILURE: "GET_EXAMPLE_FAILURE",
  GET_EXAMPLE_RESET: "GET_EXAMPLE_RESET",
  //DateConstants
  UPDATEOPENDATE: "UPDATEOPENDATE",
  UPDATECLOSEDATE: "UPDATECLOSEDATE",
  //add location
  ADD_LOCATION_INFO: "ADD_LOCATION_INFO",
  ADD_LOCATION_OPEN_DATE: "ADD_LOCATION_OPEN_DATE",
  ADD_LOCATION_OPERATING_HOURS_ALCHOL: "ADD_LOCATION_OPERATING_HOURS_ALCHOL",
  ADD_LOCATION_OPERATING_HOURS_BUSINESS:
    "ADD_LOCATION_OPERATING_HOURS_BUSINESS",
  ADD_LOCATION_TYPE_HIERARCHY: "ADD_LOCATION_TYPE_HIERARCHY",
  ADD_LOCATION_SERVICES: "ADD_LOCATION_SERVICES",
  ADD_LOCATION_ATTRIBUTES: "ADD_LOCATION_ATTRIBUTES",
  ADD_LOCATION_RESET: "ADD_LOCATION_RESET",

  //CREATE POS FOR LOCATION
  ADD_POS_SUCCESS: "ADD_POS_SUCCESS",
  GET_DETAILS_FOR_POS: "GET_DETAILS_FOR_POS",
  ERROR_DETAILS_FOR_POS: "ERROR FOR POSID",

  //DEVICE MANAGEMENT
  //CREATE POS FOR LOCATION
  ADD_DEVICE_MGMT_SUCCESS: "ADD_DEVICE_MGMT_SUCCESS",
  GET_DETAILS_FOR_DEVICE_MGMT: "GET_DETAILS_FOR_DEVICE_MGMT",
  ERROR_DETAILS_FOR_DEVICE_MGMT: "ERROR FOR DEVICE_ID_MGMT",

  LOC_SAGA_ACTIONS: {
    // LOC_HIERARCHY
    FETCH_LOC_HIERARCHY: 'FETCH_LOC_HIERARCHY',
    DELETE_HIERARCHY: 'DELETE_HIERARCHY',
    ADD_HIERARCHY: 'ADD_HIERARCHY',
  },

  LOC_ACTION_TYPES: {
    // FETCH_PRODUCT_CATEGORIES
    FETCH_LOC_HIERARCHY_PENDING: 'FETCH_LOC_HIERARCHY_PENDING',
    FETCH_LOC_HIERARCHY_FULFILLED: 'FETCH_LOC_HIERARCHY_FULFILLED',
    FETCH_LOC_HIERARCHY_REJECTED: 'FETCH_LOC_HIERARCHY_REJECTED',

    // DELETE_LOCATION
    DELETE_LOCATION_PENDING: 'DELETE_LOCATION_PENDING',
    DELETE_LOCATION_FULFILLED: 'DELETE_LOCATION_FULFILLED',
    DELETE_LOCATION_REJECTED: 'DELETE_LOCATION_REJECTED',

    // ADD_LOCATION
    ADD_LOCATION_PENDING: 'ADD_LOCATION_PENDING',
    ADD_LOCATION_FULFILLED: 'ADD_LOCATION_FULFILLED',
    ADD_LOCATION_REJECTED: 'ADD_LOCATION_REJECTED',
  },
  //steper active step
  STEPPER_ACTIVE_STEP: "STEPPER_ACTIVE_STEP",
  //updae country
  UPDATE_COUNTRY: "UPDATE_COUNTRY",
  //LOginFlag
  LOGIN_FLAG: "LOGIN_FLAG",
  PROFILE_DATA: "PROFILE_DATA",
  // tree data
  UPDATE_TREE: "UPDATE_TREE",
  RESET_TREE: "RESET_TREE",
  //add customer
  ADD_CUSTOMER_INFO: "ADD_CUSTOMER_INFO",
  ADD_CUSTOMER_COMMUNICATION: "ADD_CUSTOMER_COMMUNICATION",
  ADD_CUSTOMER_ATTRIBUTES: "ADD_CUSTOMER_ATTRIBUTES",
  ADD_CUSTOMER_RESET: "ADD_CUSTOMER_RESET",

  ACTION_TYPES: {
    // FETCH_PRODUCT_CATEGORIES
    FETCH_PRODUCT_CATEGORIES_PENDING: 'FETCH_PRODUCT_CATEGORIES_PENDING',
    FETCH_PRODUCT_CATEGORIES_FULFILLED: 'FETCH_PRODUCT_CATEGORIES_FULFILLED',
    FETCH_PRODUCT_CATEGORIES_REJECTED: 'FETCH_PRODUCT_CATEGORIES_REJECTED',

    // DELETE_CATEGORY
    DELETE_CATEGORY_PENDING: 'DELETE_CATEGORY_PENDING',
    DELETE_CATEGORY_FULFILLED: 'DELETE_CATEGORY_FULFILLED',
    DELETE_CATEGORY_REJECTED: 'DELETE_CATEGORY_REJECTED',

    // ADD_CATEGORY
    ADD_CATEGORY_PENDING: 'ADD_CATEGORY_PENDING',
    ADD_CATEGORY_FULFILLED: 'ADD_CATEGORY_FULFILLED',
    ADD_CATEGORY_REJECTED: 'ADD_CATEGORY_REJECTED',
  },

  SAGA_ACTIONS: {
    // PRODUCT_CATEGORY
    FETCH_PRODUCT_CATEGORIES: 'FETCH_PRODUCT_CATEGORIES',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    ADD_CATEGORY: 'ADD_CATEGORY',
  }
}
