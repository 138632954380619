import React from "react"
import {
  Box,
  Card,
  Grid,
  Divider,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core"
import { StyledMainWrapperCardView } from "./styles"
import _ from "lodash"
import { GoodStockIcon, CheckIcon } from "@iconsGallery"

function InventoryCardView(props) {
  const {
    icon = GoodStockIcon,
    title = "Title",
    count = 0,
    subTitle = "slogon",
    color = "#E3ECE1",
    iconCircleColor = "#85B968",
    selected = false,
    filterText = "",
    onUpdate = () => { },
  } = props || {}
  let DynamicIcon = icon
  return (
    <StyledMainWrapperCardView
      background={color}
      selected={selected}
      iconCircleColor={iconCircleColor}
      onClick={(e) => {
        onUpdate({ filterText, selected })
      }}
    >
      <div className="cls-icon-left" style={{ background: iconCircleColor }}>
        <DynamicIcon />
      </div>
      <div style={{ marginLeft: 20 }}>
        <div
          style={{
            opacity: selected ? "1" : "0.1",
            right: 5,
            position: "absolute",
            top: 5,
            display: "flex",
            color: "white",
            width: 30,
            height: 30,
            background: "green",
            borderRadius: "50%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CheckIcon />
        </div>
        <div
          style={{ color: iconCircleColor, fontSize: 15, fontWeight: "bold" }}
        >
          {title}
        </div>
        <div style={{ color: "#000", fontSize: 28, fontWeight: "bold" }}>
          <div>
            {count}
            <span
              style={{
                marginLeft: 10,
                color: "#9E9E9E",
                fontSize: 12,
                fontWeight: "normal",
              }}
            >
              {subTitle}
            </span>
          </div>
        </div>
      </div>
    </StyledMainWrapperCardView>
  )
}
export default InventoryCardView
export { InventoryCardView }
