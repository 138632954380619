import * as React from "react"
function useShekelState(props) {
  const {
    isEdit = false,
    list = [],
    onUpdate = () => "",
    gondolaTheme,
    getRemainingWidth = 0,
    getPlanogramDetail = {},
    getIsMultipleProduct,
  } = props || {}

  const [getFetchShekelList, setFetchShekelList] = React.useState([])
  React.useEffect(() => {
    setFetchShekelList(list)
  }, [list])

  const onHandleClickAddCamera = (innerprops) => {
    onUpdate("ADD_CAMERA", innerprops)
  }
  const onHandleClickRemoveCamera = (innerprops) => {
    onUpdate("REMOVE_CAMERA", innerprops)
  }
  const onHandleClickAddProduct = (innerprops) => {
    onUpdate("ADD_PRODUCT", innerprops)
  }
  const onHandleClickUpdateProduct = (innerprops) => {
    onUpdate("UPDATE_PRODUCT", innerprops)
  }
  const onHandleClickRemoveProduct = (innerprops) => {
    onUpdate("REMOVE_PRODUCT", innerprops)
  }
  const onHandleClickAddCell = (innerprops) => {
    onUpdate("ADD_CELL", innerprops)
  }
  const onHandleClickAssociateCell = (innerprops) => {
    onUpdate("ASSOCIATE_CELL", innerprops)
  }
  const onHandleClickRemoveCell = (innerprops) => {
    onUpdate("REMOVE_CELL", innerprops)
  }
  const onHandleClickRemoveAllCell = (innerprops) => {
    onUpdate("REMOVE_ALL_CELL", innerprops)
  }
  const onHandleClickQTYCell = (innerprops) => {
    onUpdate("QTY_CELL", innerprops)
  }
  const onHandleClickQTYProductCell = (innerprops) => {
    onUpdate("QTY_CELL_PRODUCT", innerprops)
  }
  const onHandleClickInfoCell = (innerprops) => {
    onUpdate("INFO_CELL", innerprops)
  }
  const onHandleClickUpdateLocationPositionProduct = (innerprops) => {
    onUpdate("LOCATION_POSITION_CELL", innerprops)
  }

  return {
    gondolaTheme,
    getFetchShekelList,
    setFetchShekelList,
    onHandleClickAddCamera,
    onHandleClickAddProduct,
    onHandleClickAddCell,
    onHandleClickRemoveCell,
    onHandleClickAssociateCell,
    onHandleClickRemoveCamera,
    onHandleClickRemoveAllCell,
    onHandleClickUpdateProduct,
    onHandleClickQTYCell,
    onHandleClickRemoveProduct,
    onHandleClickInfoCell,
    onHandleClickUpdateLocationPositionProduct,
    getRemainingWidth,
    isEdit,
    getPlanogramDetail,
    getIsMultipleProduct,
    onHandleClickQTYProductCell,
  }
}

export { useShekelState }
export default useShekelState
