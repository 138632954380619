import * as React from "react"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import _ from 'lodash'
import { useEffect } from "react"
import { FormHelperText } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  dropdownStyle: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
    '& .MuiFormLabel-root.Mui-focused': {
      "color": "var(--primaryColor)",
    }
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function SearchDropdownMultiSelect(props) {
  const { list = [], defaultGetSelectedList = [], onUpdate = () => "",
    placeholder = "select", tag = "select", errorDisplay = false, helperText = "" } =
    props || {}

  const classes = useStyles();
  const [getSelectedList, setSelectedList] = React.useState(defaultGetSelectedList)
  const handleChange = (event) => {
    const {
      target: { value },
    } = event || {}
    let values = typeof value === "string" ? value.split(",") : value
    setSelectedList(values)
    onUpdate(values)
  }

  return (
    <Grid>
      <FormControl sx={{ m: 1, width: 200, mt: 3 }} className={classes.dropdownStyle}>
        <InputLabel id="demo-multiple-checkbox-label">{tag}</InputLabel>
        <Select
          style={{ minWidth: 250, maxWidth: 250, minHeight: 40, maxHeight: 40 }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={getSelectedList}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{placeholder}</em>;
            }
            return selected.join(', ');
          }}
          MenuProps={MenuProps}
        >
          {list.map((values) => (
            <MenuItem key={values} value={values}
              disabled={getSelectedList?.length > 1 && !_.includes(getSelectedList, values)}>
              <Checkbox checked={getSelectedList.indexOf(values) > -1}
                style={{ color: "var(--primaryColor)" }}
              />
              <ListItemText primary={values} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Grid>
  )
}

export { SearchDropdownMultiSelect }
export default SearchDropdownMultiSelect
