import React from "react"
import styled from "styled-components/macro"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Helmet from "react-helmet"
import { Button as MuiButton, Grid, Typography } from "@material-ui/core"
import { spacing } from "@material-ui/system"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  ErrorFourZeroFourIcon
} from "@iconsGallery"
const Button = styled(MuiButton)(spacing)

function Page404() {
  const history = useHistory()
  const userProfileDetails =
    useSelector((state) => state.adminReducer.userProfileDetails) || {}
  const returnWeb = () => {
    let getRoleName = localStorage.getItem("sourceRole")
    let getTenantId = localStorage.getItem("tenantId")
    getRoleName = getRoleName.toLocaleLowerCase()
    const isSassRole = getRoleName.includes("saas")
    let dynamicRoutes = isSassRole
      ? `/${userProfileDetails?.roleType}/dashboard?page=home`
      : `/${userProfileDetails?.roleType}/dashboard?page=autonomous&retailer=${getTenantId}&subpage=0`
    window?.location?.replace(dynamicRoutes)
  }

  const { location = {} } = window || {}
  const { search = "" } = location || {}
  let isLoad = search.includes("load")

  React.useEffect(() => {
    if (isLoad) {
      returnWeb()
    }
  }, [])

  return (
    <>
      {isLoad ? (
        <></>
      ) : (
        <div style={{ position: "relative" }}>
        <ErrorFourZeroFourIcon/>
          <div style={{ position: "absolute", top: "88%", left: "52%" }}>
            <Button
              component={Link}
              to="/"
              variant="contained"
              style={{
                background: "#8080D2",
                width: "150px",
                borderRadius: "20px",
                color: "#FFF",
              }}
              onClick={() => returnWeb()}
            >
              Go back home
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default Page404
