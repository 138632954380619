import React from 'react'

function useQuestionAnswerLayout(props) {

  const {
    query = '',
    answerList = []
  } = props

  return {
    query,
    answerList
  }
}

export { useQuestionAnswerLayout }
