import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Helmet from "react-helmet";
import styled from "styled-components/macro";
import {
  Checkbox, Grid, IconButton, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Paper as MuiPaper, Table, TableBody, SvgIcon,
  Box, TableContainer, TableCell, TableHead, TablePagination, TableRow, Typography, Button, TableSortLabel, TextField, makeStyles
} from "@material-ui/core";
import _ from 'lodash';
import update from 'immutability-helper';
import { DoneIcon } from "@iconsGallery"

import { spacing } from "@material-ui/system";
import EnhancedTableHead from "./tableHead"
import EnhancedTableToolbar from "./tableToolBar"

const useStyles = makeStyles(theme => ({
  tablePagination: {
    padding: 0,
    borderBottom: 'none'
  }
}))

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] === a[orderBy]) {
    return -1
  }
  if (b[orderBy] === a[orderBy]) {
    return 1;
  }
  return 0
}
function getComparator(a, b, order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function CustomTable(props) {
  const [productsDataMap, setProductsDataMap] = useState([]);
  const [itemID, setItemID] = React.useState("");
  const [barcode, setBarCode] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [manfacturer, setManfacturer] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState(() => (props?.list || []));
  const [page, setPage] = React.useState(() => {
    let temp = _.toNumber(_.get(props, 'data.page', 0));
    if (_.isFinite(temp) && temp > 0) {
      return temp - 1
    };
    return 0
  });
  const dispatch = useDispatch();
  const classes = useStyles()
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(props?.data?.limit || 10);


  useEffect(() => {
    setProductsDataMap(props?.data?.docs || [])
  }, [props?.data?.docs])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property)
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props?.data?.docs?.map((n) => n.item_id)
      setSelected(newSelecteds);
      return;
    }
    setSelected([])
  }

  const handleCheck = (id, title) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  }

  const handleAssociationClick = () => {
    let formattedList = { [props?.apiName || 'buy_product']: selected.map(o => ({ product_id: o.id, product_title: o.title })) }
    props?.onUpdateList(formattedList)
  }

  const handleChangePage = (e, newPage, rowLimit = rowsPerPage) => {
    setPage(newPage);
    props?.fetchDataMethod({ page: newPage + 1, limit: rowLimit })
  };
  const handleChangeRowsPerPage = (event) => {
    let temp = _.toNumber(event.target.value)
    setRowsPerPage(temp);
    setPage(0);
    handleChangePage({}, 0, temp)
  }
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, (props?.data?.docs?.length || 0) - page * rowsPerPage);

  return (
    <div style={{ height: '100%' }}>
      <EnhancedTableToolbar numSelected={selected?.length || 0} />
      <TableContainer style={{ maxHeight: `calc(100% - ${132}px)`, height: 'auto' }}>
        <Table stickyHeader aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
          <EnhancedTableHead numSelected={selected?.length || 0} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={props?.data?.docs?.length || 0} />
          <TableBody>
            {
              props?.data?.docs?.map((row, index) => {
                const isItemSelected = isSelected(row?.item_id, row?.title);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover onClick={() => handleCheck(row?.item_id)}
                    role="checkbox"
                    aria-checked={isItemSelected} tabIndex={-1}
                    key={row.title} selected={isItemSelected}>
                    <TableCell padding="checkbox"><Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} /></TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">{row.item_id}</TableCell>
                    <TableCell >{row.title}</TableCell>
                    <TableCell >{row.item_barcode_type}</TableCell>
                    <TableCell >{row.brand}</TableCell>
                    <TableCell >{row.manufacturer}</TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>

        </Table>
      </TableContainer>
      {props?.data?.docs?.length === 0 ? <Typography style={{ padding: "10px", color: 'grey', textAlign: "center" }}>Select a category from the hierarchy</Typography> : null}

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16, borderTop: `1px solid #e0e0e0` }}>
        <TablePagination className={classes.tablePagination} align='left' rowsPerPageOptions={[5, 10, 25]} count={props?.data?.totalDocs}
          rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />
        <Button onClick={handleAssociationClick} style={{ backgroundColor: "var(--primaryColor)" }} startIcon={<DoneIcon style={{ color: "#ffffff" }} />} >
          <Typography style={{ color: "#ffffff" }}>Associate Products</Typography>
        </Button>
      </div>
    </div>
  )
}
export default CustomTable;

