import { Switch, Box, Typography, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, FormControl } from "@material-ui/core"
import React from "react"

import customTableStyles from "../../styles/components/customTable/styles"
import _ from "lodash"
import { useState } from "react"
import { useEffect } from "react"
function ActionDropdown(props) {
    const Roles = _.isString(props?.dataItem?.action) ? [props?.dataItem?.action] : props?.dataItem?.action || []
    const [actionVal, setActionVal] = useState(Roles?.[0])
    const data = props?.dataItem
    const dataValue = props?.value || Roles?.[0]
    const selectedItemIndex = props?.filterData?.findIndex(a => a?.value === dataValue?.toString()) || 0;
    const classes = customTableStyles()
    const { bgColor = "", value = [] } = props

    const handleChange = (e) => {
        setActionVal(e.target.value)
        props?.handleChange(e)
    }

    return (
        <td>
            <FormControl style={{ width: "150px" }}>
                <Select
                    name="action"
                    value={actionVal}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    variant="outlined"
                    style={{ height: "30px", backgroundColor: Roles?.length <= 1 ? "#e0e0e5" : "" }}
                    className={classes.actionDropdown}
                    disabled={Roles?.length <= 1}
                >
                    {Roles?.map((name) => (
                        <MenuItem key={name} value={name}>
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </td >
    )
}

export default ActionDropdown
