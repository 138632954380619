import React, { useState, useEffect } from "react"
import CommonComponent, { validateTime, fromAndToTimeChecker } from "./CommonComponent"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { addOperatingHoursAlchol } from "@reduxHelper"
import * as Adm from "@adm"

function AlcholTradingHours({ editMode, setAllowUpdate = () => { }, setUpdate, data, isAllOkayRef = { current: true },
  isTimeArrOkayRef = { current: true }, setFieldValue = () => { }, values = {}, errors = {}, touched = {} }) {
  const customDaysArr = [
    { day: "sunday", label: "S", selected: false, },
    {
      day: "monday",
      label: "M",
      selected: false,
    },
    {
      day: "tuesday",
      label: "T",
      selected: false,
    },
    {
      day: "wednesday",
      label: "W",
      selected: false,
    },
    {
      day: "thursday",
      label: "T",
      selected: false,
    },
    {
      day: "friday",
      label: "F",
      selected: false,
    },
    {
      day: "saturday",
      label: "S",
      selected: false,
    },
  ]
  const dispatch = useDispatch()
  const timeObj = { time_from: "10:00", time_to: "23:00" }
  const [daysArrState, setDaysArrState] = useState([])
  const [timeArrState, setTimeArrState] = useState([])
  const [timeError, setTimeError] = useState([])
  const [daysError, setDaysError] = useState([])
  const reduxData = useSelector((state) => state.addLocationReducer)
  const [alertMessage, setAlertMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")

  useEffect(() => {
    dispatch(addOperatingHoursAlchol({
      days: _.get(reduxData, "operatingHoursBusiness.days", []),
      time: _.get(data, "alcoholTradingHrs.time", _.get(reduxData, "operatingHoursAlchol.time", [])),
    }))
    let errArr = _.get(data, "alcoholTradingHrs.time", _.get(reduxData, "operatingHoursAlchol.time", [])
    ).map((x, i) => _.get(x, `[${i}].time_from`, "") > _.get(x, `[${i}].time_to`, ""))
    setTimeError(errArr)
  }, [data])

  const clickHandler = (rowIdx, dayIdx) => {
    const newDaysArrState = _.cloneDeep(daysArrState)
    newDaysArrState[rowIdx][dayIdx].selected = !newDaysArrState[rowIdx][dayIdx]
      .selected
    setDaysArrState(newDaysArrState)
    setAllowUpdate(true)
    validateTime({
      daysArrState: _.cloneDeep(newDaysArrState),
      timeArrState,
      rowIdx,
      timeCheckKey: "between",
      setDaysError: setDaysArrState,
      timeLimitsArray: _.get(reduxData, "operatingHoursBusiness.time", []),
    })
  }

  const textChangeHandler = (val, type, i) => {
    const newTimeArrState = [...timeArrState]
    if (_.isEmpty(newTimeArrState[i])) {
      newTimeArrState[i] = { time_from: "", time_to: "" }
    }
    newTimeArrState[i][type] = val
    dispatch(addOperatingHoursAlchol({ days: _.get(reduxData, "operatingHoursBusiness.days", []), time: newTimeArrState, }))
    setFieldValue("alcoholTradingHrs", { days: _.get(reduxData, "operatingHoursBusiness.days", []), time: newTimeArrState, })
    setAllowUpdate(true)
  }
  const addHandler = (idx) => {
    setAlert(false)
    if ((daysArrState.length > 0 && daysArrState[idx].some((x) => x.selected === true)) || daysArrState.length === 0
    ) {
      const newDaysArrState = [...daysArrState]
      const newTimeArrState = [...timeArrState]
      newTimeArrState.push(timeObj)
      newDaysArrState.push(customDaysArr)
      dispatch(addOperatingHoursAlchol({ days: _.get(reduxData, "operatingHoursBusiness.days", []), time: newTimeArrState }))
      setFieldValue("alcoholTradingHrs", { days: _.get(reduxData, "operatingHoursBusiness.days", []), time: newTimeArrState })
    } else {
      const newDaysArrState = [...daysArrState]
      newDaysArrState[idx].error = "Kindly choose any of the days"
      setDaysError(newDaysArrState)
      setAlertType("info")
      setAlertMessage("Select atleast a day")
      setAlert(true)
    }
  }
  const closeHandler = (ind) => {
    const newTimeArrState = [...timeArrState]
    if (timeArrState[ind]) {
      let temp = _.cloneDeep(timeArrState[ind])
      temp = { time_from: "", time_to: "" }
      newTimeArrState[ind] = temp
    }
    dispatch(addOperatingHoursAlchol({ days: _.get(reduxData, "operatingHoursBusiness.days", []), time: newTimeArrState, }))
    setFieldValue("alcoholTradingHrs", { days: _.get(reduxData, "operatingHoursBusiness.days", []), time: newTimeArrState, })
  }
  useEffect(() => {
    isTimeArrOkayRef.current = timeError.filter((x) => x).length === 0
  }, [timeError])

  const alcoholHoursInRedux = useSelector((state) => _.get(state, "addLocationReducer.operatingHoursAlchol"))
  const businessHoursInRedux = useSelector((state) => _.get(state, "addLocationReducer.operatingHoursBusiness"))

  useEffect(() => {
    setTimeArrState(_.get(alcoholHoursInRedux, "time"))
  }, [alcoholHoursInRedux])

  useEffect(() => {
    if (_.get(alcoholHoursInRedux, "time", []).length === _.get(businessHoursInRedux, "time", []).length) {
      let errArr = _.get(alcoholHoursInRedux, "time", []).map((x, ind) => {
        if (_.isEmpty(_.get(x, `time_from`))) {
          return false
        }
        let t = fromAndToTimeChecker({
          fromTime: _.get(x, `time_from`),
          toTime: _.get(x, `time_to`),
          lowerLimit: _.get(businessHoursInRedux, `time[${ind}].time_from`),
          upperLimit: _.get(businessHoursInRedux, `time[${ind}].time_to`),
        })
        return !(t[0] && t[1])
      })
      setTimeError(errArr)
    }
  }, [businessHoursInRedux, alcoholHoursInRedux])

  return (
    <>
      <CommonComponent
        timeLimitsArray={_.get(reduxData, "operatingHoursBusiness.time", [])}
        daysArrState={_.get(reduxData, "operatingHoursBusiness.days", [])}
        timeArrState={timeArrState}
        timeArrErr={timeError}
        setTimeArrErr={setTimeError}
        setDaysArrErr={setDaysArrState}
        clickHandler={clickHandler}
        textChangeHandler={textChangeHandler}
        addHandler={addHandler}
        closeHandler={closeHandler}
        displayText={"Alcohol Trading Hours"}
        setUpdate={setUpdate}
        isAllOkayRef={isAllOkayRef}
        editMode={editMode}
        disableDateSelection
        disabledDays={_.difference(
          customDaysArr.map((q) => q.day),
          _.flatten(
            _.get(reduxData, "operatingHoursBusiness.days", []).reduce(
              (acc, x, i) => {
                let filteredOnlySelected = x.filter((z) => z.selected)
                let toBePushed = filteredOnlySelected.map((w) => w.day)
                acc.push(toBePushed)
                return acc
              },
              []
            )
          )
        )}
      />
      {alert ? (
        <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
      ) : null}
    </>
  )
}
export { AlcholTradingHours }
export default AlcholTradingHours
