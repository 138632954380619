import React, { useState } from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  ListItemText,
  Grid,
  Typography,
  Checkbox,
} from "@material-ui/core"
import { AddCircleOutlineIcon, CloseIcon, SearchIcon } from "@iconsGallery"
import Styled from "styled-components"
import * as Utils from "@utils"
import { SearchListUIContext } from "./context"
import * as SearchListDom from "./styles"
import AddProductForPriceEventDialog from "../../../pages/masterData/price/priceDetailedPage/AddProductForPriceEventDialog"
function SearchInput(props) {
  const {
    buttonText = "ADD",
    renderSuggesionUIList,
    placeHolderInputText = "",
    isAddBtn = false,
    isProductGroup = false,
  } = props || {}

  const [productLookupDialogProps, setProductLookupDialogProps] = useState({
    open: false,
  })
  const searchListUIContextState = React.useContext(SearchListUIContext)
  const {
    onSearchUpdate,
    searchText,
    fetchListData,
    searchInputRef,
    idName,
    titleName,
    setSearchText,
    setFetchListData,
    setButtonTrigger,
    buttonTrigger,
    isMultipleSelect,
    isSelectIndex,
    resetInput,
    isDisable,
  } = searchListUIContextState

  const placeHolderInputTextLocal =
    placeHolderInputText || `Search by ${idName} or ${titleName}`

  let textFieldWrapperAttr = {
    type: "text",
    name: "value",
    placeholder: placeHolderInputTextLocal,
    variant: "outlined",
    onBlur: () => {},
    onChange: (event) => {
      let trimmedText = event?.target?.value?.trim()
      event.persist()
      setTimeout(() => {
        setSearchText(trimmedText)
        onSearchUpdate({ event, ...searchListUIContextState })
      }, 300)
    },
    size: "small",
    autoComplete: "off",
    defaultValue: searchText.trim(),
  }

  if (isDisable === true && isMultipleSelect === false) {
    textFieldWrapperAttr = {
      ...textFieldWrapperAttr,
      className: `${isSelectIndex === -1 ? "" : "disabled"}`,
      disabled: isSelectIndex === -1 ? false : true,
    }
  }
  return (
    <>
      <SearchListDom.SearchInputMainWrapper
        ref={searchInputRef}
        className="cls-search-input"
      >
        <SearchListDom.SearchInputSubWrapper>
          <SearchListDom.TextFieldWrapper {...textFieldWrapperAttr} />
          {Utils.JSUtils.isEmpty(searchText) === false ? (
            <SearchListDom.SearchInputCloseBttonWrapper
              className=""
              onClick={() => {
                resetInput()
              }}
            >
              <CloseIcon />
            </SearchListDom.SearchInputCloseBttonWrapper>
          ) : (
            <SearchListDom.SearchInputCloseBttonWrapper
              className="icon-search-kit"
              onClick={() => {
                searchInputRef.current.querySelector("input").focus()
              }}
            >
              <SearchIcon />
            </SearchListDom.SearchInputCloseBttonWrapper>
          )}
          {Utils.JSUtils.isEmpty(searchText) === false && (
            <SearchListDom.SearchSuggesionListWrapper>
              {renderSuggesionUIList(searchListUIContextState)}
            </SearchListDom.SearchSuggesionListWrapper>
          )}
        </SearchListDom.SearchInputSubWrapper>
        {isMultipleSelect === true && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setFetchListData(fetchListData)
              resetInput()
              setButtonTrigger(!buttonTrigger)
            }}
            startIcon={<AddCircleOutlineIcon />}
          >
            {buttonText}
          </Button>
        )}
        {isAddBtn ? (
          <AddCircleOutlineIcon
            onClick={() =>
              setProductLookupDialogProps({
                open: true,                
              })
            }
          />
        ) : null}
      </SearchListDom.SearchInputMainWrapper>
      <AddProductForPriceEventDialog
      isProductGroup={isProductGroup}
        {...productLookupDialogProps}
        handleClose={() => setProductLookupDialogProps({ open: false })}
      />
    </>
  )
}

export { SearchInput }
