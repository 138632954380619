import Switch from "@material-ui/core/Switch"
import React from "react"
import { FormControlLabel } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"

const StyledSwitch = withStyles({
  switchBase: {
    color: "var(--primaryColor) !important",
  },
  track: {
    backgroundColor: "var(--primaryColor) !important",
    width: "34px",
    height: "14px",
  },
  thumb: {
    width: "20px",
    height: "20px",
  },
})(Switch)

function ToggleButton(props) {
  const { handleChange, checked, value, name, labelPlacement, disabled = false, className = "", isNoLabel = false, style = {} } =
    props || {}
  return (
    <FormControlLabel
      style={style}
      className={className}
      control={
        <StyledSwitch
          className={className}
          onChange={handleChange}
          checked={checked}
          defaultValue={value}
          name={name}
          disabled={disabled}
        />
      }
      label={isNoLabel ? undefined : name}
      labelPlacement={labelPlacement}
    />
  )
}

export { ToggleButton }
export default ToggleButton
