import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepConnector from "@material-ui/core/StepConnector"
import { Grid, Button, Paper, Typography } from "@material-ui/core"
import commonStyles from "../../styles/Pages/common/styles"
import { ChevronLeft, ChevronRight } from "@iconsGallery"
import { withTheme } from "styled-components/macro"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import * as ReduxHelper from "@reduxHelper"
import { ConfirmationDialog } from "@features"
import { Prompt } from "react-router-dom"
import { CheckCircleIcon } from "../../IconsGallery/IconsGallery"
import { InfoOutlined } from "@material-ui/icons"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    "@media(max-width:750px)": {
      justifyContent: "center",
    },
  },
  stepperWrapper: {
    padding: "20px",
    "@media(max-width:600px)": {
      padding: "20px 5px",
      "& .MuiStep-horizontal": {
        padding: "0px 2px",
      },
    },
    "@media(max-width:750px)": {
      "& .MuiStep-horizontal": {
        padding: "0px 2px",
      },
    },
  },
  stepLabel: {
    width: "100px",
    "@media(max-width:750px)": {
      width: "60px",
    },
    "@media(max-width:425px)": {
      width: "40px",
    },
  },
  bodyContentWrap: {
    margin: "10px 64px",
    "@media(max-width:700px)": {
      margin: "26px",
    },
  },
  bodyContentWrapNew: {
    margin: "10px 24px",
    "@media(max-width:700px)": {
      margin: "26px",
    },
  },
}))

function CustomizedSteppers(props) {
  const {
    preventRerender,
    setPreventRerender = () => { },
    isLegacy = true,
  } = props
  const commonClasses = commonStyles()
  const activeStep = useSelector((state) => state.stepperReducer.step)
  const {
    icons,
    items,
    getStepContent,
    allowPrompt,
    setAllowPrompt = () => { },
    loationIconCheck,
    label = "",
  } = props
  const steps = items
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [pathName, setPathName] = useState("/")
  const [isCancel, setCancel] = useState("")
  const flag = useSelector((state) => state.loginstateReducer)
  const classes = useStyles()

  const handleBack = () => {
    setPreventRerender(true)
    dispatch(ReduxHelper.stepperSetActiveStep(isLegacy ? activeStep - 1 : 0))
  }

  useEffect(() => {
    if (flag) {
      setOpenConfirmationDialog(false)
    }
  }, [flag])

  useEffect(() => {
    if (reDirect) {
      props.handleReset(pathName)
    }
  }, [reDirect])

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
      "@media(max-width: 425px)": {
        width: "15px",
        top: 16,
        left: "calc(-50% + 15px) !important",
      },
      "& .MuiStepLabel-root": {
        "@media(max-width: 600px)": {
          width: "50px",
        },
        "@media(max-width: 425px)": {
          width: "40px",
        },
      },
    },
    active: {
      "& $line": {
        backgroundColor: "rgba(47, 72, 88, 0.15)",
      },
    },
    completed: {
      "& $line": {
        backgroundColor: "rgba(47, 72, 88, 0.15)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "rgba(47, 72, 88, 0.15)",
      borderRadius: 1,
    },
  })(StepConnector)

  const useColorlibStepIconStyles = makeStyles({
    root: {
      zIndex: 1,
      width: "48px",
      height: "48px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      color: "var(--primaryColor)",
      backgroundColor: "#f9f9fc",
      "@media(max-width: 425px)": {
        width: "30px",
        height: "30px",
      },
    },
    active: {
      color: "#fff",
      backgroundColor: "var(--primaryColor)",
    },
  })
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    )
  }
  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  }
  return (
    <Paper
      style={{
        width: "100%",
        maxWidth: isLegacy ? 1400 : "100%",
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow:
          "rgb(63 63 68 / 5%) 0px 0px 0px, rgb(63 63 68 / 15%) 0px 1px 2px",
        paddingTop: isLegacy ? 0 : 10,
      }}
    >
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        setCancel={setCancel}
      />
      <Prompt
        when={allowPrompt}
        message={(location, action) => {
          setPathName(location.pathname)
          setOpenConfirmationDialog(true)
          return reDirect
        }}
      />
      {isLegacy && (
        <Grid
          container
          direction="row"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.stepperContainer}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            className={classes.stepperWrapper}
          >
            {steps.map((item, i) => (
              <Step key={i}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  className={classes.stepLabel}
                ></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      )}
      <Paper
        className={classes[isLegacy ? "bodyContentWrap" : "bodyContentWrapNew"]}
      >
        {isLegacy && (
          <Typography
            style={{ fontSize: "24px", fontWeight: "800", display: "flex" }}
          >
            {t(steps[activeStep]?.label)}
            {label === "location" && activeStep === 4 ? (
              <span style={{ color: "red" }}>* </span>
            ) : (
              ""
            )}

            {activeStep === 0 &&
              !_.isUndefined(loationIconCheck) &&
              loationIconCheck && (
                <div style={{ display: "flex", marginLeft: "5px" }}>
                  <CheckCircleIcon
                    style={{
                      color: "#4CAF50",
                      fontSize: "20px",
                    }}
                  />
                  <Typography style={{ marginLeft: "5px" }}>
                    Address verified
                  </Typography>
                </div>
              )}
            {activeStep === 0 &&
              !_.isUndefined(loationIconCheck) &&
              !loationIconCheck && (
                <div style={{ display: "flex" }}>
                  <InfoOutlined style={{ color: "#f44336" }} />
                  <Typography style={{ marginLeft: "5px" }}>
                    {" "}
                    Address not verified
                  </Typography>
                </div>
              )}
          </Typography>
        )}
        <Paper style={{ margin: "25px 0px" }}>
          {getStepContent(activeStep)}
        </Paper>
      </Paper>
      <Paper
        className={classes.bodyContentWrap}
        style={{ marginTop: isLegacy ? "50px" : "20px" }}
      >
        <Grid
          container
          style={{ justifyContent: "space-between" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            {activeStep === 0 ? (
              <></>
            ) : (
              <>
                <Button
                  onClick={handleBack}
                  className={commonClasses.stepperPreviousBtn}
                  variant="outlined"
                  style={{ marginTop: "0px", marginBottom: "38px" }}
                >
                  <ChevronLeft style={{ marginRight: "12px" }} />
                  {window?.globalTranslation("PREVIOUS")}
                </Button>
              </>
            )}
          </Grid>
          <Grid></Grid>
          {activeStep === steps.length - 1 ? (
            <Grid>
              <Button
                variant="outlined"
                color="default"
                className={commonClasses.stepperCancelBtn}
                onClick={() => {
                  setOpenConfirmationDialog(true)
                }}
                style={{ marginBottom: "38px", marginRight: "15px" }}
              >
                {window?.globalTranslation("CANCEL")}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  props.handleNext(activeStep, "SUBMIT")
                }}
                className={commonClasses.stepperContainedButton}
                style={{ marginTop: "0px", marginBottom: "38px", width: 120 }}
              >
                {window?.globalTranslation("SUBMIT")}
              </Button>
            </Grid>
          ) : (
            <Grid>
              <Button
                variant="outlined"
                color="default"
                className={commonClasses.stepperCancelBtn}
                onClick={() => {
                  setOpenConfirmationDialog(true)
                }}
                style={{
                  marginTop: "0px",
                  marginBottom: "38px",
                  marginRight: "15px",
                }}
              >
                {window?.globalTranslation("CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  props.handleNext(activeStep)
                }}
                className={commonClasses.stepperContainedButton}
                style={{ marginTop: "0px", marginBottom: "38px" }}
              >
                {window?.globalTranslation("NEXT")}
                <ChevronRight />
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Paper>
  )
}
export { CustomizedSteppers }
export default withTheme(CustomizedSteppers)
