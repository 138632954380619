import { makeStyles } from "@material-ui/core/styles"

const commonStyles = makeStyles((theme) => ({
  containedButton: {
    color: theme.palette.filledButton.textColor,
    backgroundColor: "var(--primaryColor)",
    fontSize: "13px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "var(--primaryColor)",
    },
  },
  outlinedButton: {
    marginTop: "32px",
    marginBottom: "210px",
    color: theme.palette.outlinedButton.textColor,
    borderColor: theme.palette.outlinedButton.color,
    fontSize: "13px",
    fontWeight: "700",
    maxWidth: "93px",
    minWidth: "93px",
  },
  largeOutlinedButton: {
    marginTop: "32px",
    marginBottom: "210px",
    color: theme.palette.outlinedButton.textColor,
    borderColor: theme.palette.outlinedButton.color,
    fontSize: "13px",
    fontWeight: "700",
  },
  stepperPreviousBtn: {
    color: "var(--primaryColor)",
    borderColor: "var(--primaryColor)",
    fontSize: "13px",
    fontWeight: "700",
    '@media(max-width:425px)': {
      padding: "5px",
      '& .MuiSvgIcon-root': {
        fontSize: "15px",
      },
      '& .MuiButton-label': {
        fontSize: "10px",
      }
    }

  },
  stepperCancelBtn: {
    fontSize: "13px",
    fontWeight: "700",
    borderColor: "var(--primaryColor)",
    color: "var(--primaryColor)",
    '@media(max-width:425px)': {
      padding: "5px",
      '& .MuiSvgIcon-root': {
        fontSize: "15px",
      },
      '& .MuiButton-label': {
        fontSize: "10px",
      }
    }
  },
  stepperContainedButton: {
    color: theme.palette.filledButton.textColor,
    backgroundColor: "var(--primaryColor)",
    fontSize: "13px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "var(--primaryColor)",
    },
    '& .MuiSvgIcon-root': {
      marginLeft: "12px",
    },
    '@media(max-width:425px)': {
      padding: "5px",
      '& .MuiSvgIcon-root': {
        fontSize: "15px",
        marginLeft: "0px"
      },
      '& .MuiButton-label': {
        fontSize: "10px",
      }
    }
  },
  defaultOutlinedButton: {
    fontSize: "13px",
    fontWeight: "700",
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '70%'
    }
  },
  feildCircleStyle: {
    fontSize: '10px',
    border: '1px solid var(--primaryColor)',
    padding: '10px',
    borderRadius: '25px',
    color: "var(--primaryColor)",
    marginRight: '3px',
    paddingTop: '0px',
    paddingBottom: '0px',
    maxWidth: "80px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: "4px"
  },
  hideCheckboxAll: {
    '& .k-master-row': {
      '&:hover': {
        cursor: "pointer"
      }
    }
  },

  circleActive: {
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    backgroundColor: "green"
  },

  circleInactive: {
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    backgroundColor: "red"
  },
  numberFormat: {
    textAlign: "right !important"
  },
  enabledBox: {
    borderRadius: "4px",
    backgroundColor: "var(--primaryColor)",
    cursor: 'pointer',
    color: theme.palette.table.enabledBox.textColor,
    textAlign: "center",
    width: "63px",
    fontSize: "14px",
    fontWeight: "400px",
  },
}))

export default commonStyles
export { commonStyles }
