import React, { useState, useEffect } from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import { withTheme } from "styled-components/macro"
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Box,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import HierarchyMapping from "../../pages/masterData/HierarchyMapping"
import { PlusCircle } from "@iconsGallery"
import _ from "lodash"
import * as Features from "@features"
import * as Adm from "@adm"
import * as Utils from "@utils"
import { EnhancedTableHead, LocationHeaders } from "./tableHead/index"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import useStyles from "./styles"
import { AddIcon, BorderColorOutlinedIcon } from "@iconsGallery"
import * as ServiceAPI from "@services"
import styled from "styled-components"
import * as constantsAPI from "@constants"
import { ConfirmationDialog } from "@features"
import {
  CancelIcon,
  CloseIcon,
  Trash2,
  DeleteIcon,
  DoneIcon,
  Edit,
} from "../../IconsGallery/IconsGallery"
import { important } from "polished"
import TableFilter from "./tableHead/TableFilter"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

function AddLocationAndLocationGroupUI(props) {
  const classes = useStyles()
  let history = useHistory()
  const locationPath = history?.location?.pathname
  let disabledOtherLocation = locationPath.includes(
    "/master-data/products/hierarchy"
  )
  const historyPath = history?.location?.pathname
  var enableAvailableHand =
    historyPath.includes("/master-data/products/product-detailed-page") ||
    historyPath.includes("/master-data/products/create")
  const [filterItem, setFilterItem] = useState({
    locationId: "",
    locationAddress: "",
    locationname: "",
  })
  const {
    clearVal = false,
    setClearVal = () => { },
    isAddLocation = true,
    categoryFormdeleteFunc = true,
    isAddLocationGroup = true,
    showHeader = true,
    updateData = () => { },
    resetData = () => { },
    setLocationIds = () => { },
    setLocationGroupIds = () => { },
    locationIds = [],
    handleLocationDelete = () => { },
    locationGroupIds = [],
    isAccordianPanel = false,
    callBackLocId = () => { },
    isWriteAllowed = false,
    setAnyChanges = () => { },
    setFieldValue = () => { },
    pageMode = "edit",
    setAllowPrompt = () => { },
  } = props
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLocationLookupOpen, setIsLocationLookupOpen] = useState(false)
  const [locationId, setLocationId] = useState(locationIds)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [order, setOrder] = React.useState("asc")
  const [valuesOrder, setvaluesOrder] = React.useState("locationIds")
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const [newEdit, setNewEdit] = React.useState(false)
  useEffect(() => {
    setLocationId(locationIds)
  }, [props])

  useEffect(() => {
    setLocationId(locationId)
    if (locationId.length <= 5) {
      setPage(0)
    }
  }, [locationId])

  useEffect(() => {
    if (clearVal === true) {
      setFilterItem({
        locationId: "",
        locationname: "",
        locationAddress: "",
      })
    }

    setClearVal(false)
  }, [clearVal])

  const StyledDialog = withStyles((theme) => ({
    paper: {
      maxHeight: "calc(100vh - 70px) !important",
      minWidth: "90vw !important",
      maxWidth: "98vw !important",
      padding: "0px",
      "@media(max-width:768px)": {
        minWidth: "calc(100% - 80px) !important",
        maxWidth: "calc(100% - 80px) !important",
        maxHeight: "100% !important",
        minHeight: "100% !important",
      },
    },
  }))(Dialog)

  const onSubmit = (selectedItems, callback) => {
    if (selectedItems.length === 0) {
      setAnyChanges(false)
    } else {
      setAnyChanges(true)
      setAllowPrompt(true)
    }
    setLocationId(selectedItems)
    if (_.isFunction(callback)) callback()
    setIsLocationLookupOpen(false)
    if (!isAccordianPanel) {
      callBackLocId(selectedItems)
    }
  }
  const handleDelete = (locId) => {
    const filterIds = locationId?.filter((o) => o.locationId !== locId)
    if (filterIds.length === 0) {
      setAnyChanges(false)
    } else {
      setAnyChanges(true)
      setAllowPrompt(true)
    }
    setAnyChanges(true)
    setLocationId(filterIds)
    if (!isAccordianPanel) {
      callBackLocId(filterIds)
    }
  }

  const onUpdateData = () => {
    const body = {
      locationIds: locationId?.map((x) => _.get(x, "locationId")),
      locationGroupIds: [],
    }
    updateData(body)
  }
  function descendingComparator(a, b, valuesOrder) {
    if (b[valuesOrder] < a[valuesOrder]) {
      return -1
    }
    if (b[valuesOrder] > a[valuesOrder]) {
      return 1
    }
    return 0
  }
  function getComparator(a, b, order, valuesOrder) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, valuesOrder)
      : (a, b) => -descendingComparator(a, b, valuesOrder)
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const customRenderContainer = (props) => {
    const {
      edit = false,
      isEditMode = false,
      setEdit = () => { },
      isAccordianPanel = false,
    } = props

    const handleRequestSort = (event, property) => {
      const isAscending = valuesOrder === property && order === "asc"
      setvaluesOrder(property)
      setOrder(isAscending ? "desc" : "asc")
    }
    const sorting = (column) => {
      if (order === "asc") {
        const sorted = [...locationId].sort((a, b) =>
          a[column]?.toLowerCase() > b[column]?.toLowerCase() ? 1 : -1
        )
        setLocationId(sorted)
        setOrder("dsc")
      }

      if (order === "dsc") {
        const sorted = [...locationId].sort((a, b) =>
          a[column]?.toLowerCase() < b[column]?.toLowerCase() ? 1 : -1
        )
        setLocationId(sorted)
        setOrder("asc")
      }
    }

    const searchText = (e, i) => {
      setPage(0)
      const { value, name } = e.target
      let newValue = { ...filterItem }
      newValue[name] = value
      setFilterItem(newValue)
    }

    const locationFilter = locationId.filter((item) => {
      return (
        item?.locationId
          ?.toLowerCase()
          .includes(filterItem.locationId?.toLowerCase()) &&
        item?.locationName
          ?.toLowerCase()
          .includes(filterItem.locationname?.toLowerCase()) &&
        item?.locationAddress?.line1
          ?.toLowerCase()
          .includes(filterItem.locationAddress?.toLowerCase())
      )
    })

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    return (
      <>
        <Grid style={{ width: "100%" }}>
          {!edit ? (
            <>
              {isAccordianPanel && <Divider />}
              <Grid
                container
                direction="row"
                style={{ width: "100%", overflowX: "scroll !important" }}
              >
                {isAddLocation ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setIsLocationLookupOpen(true)
                        }}
                        startIcon={
                          <PlusCircle
                            style={{ color: "#fff", width: "16px" }}
                          />
                        }
                        style={{
                          margin: "10px",
                          backgroundColor: "var(--primaryColor)",
                        }}
                        disabled={pageMode === "create" ? true : false}
                      >
                        ADD LOCATION
                      </Button>
                      {locationId.length > 0 ? (
                        <Tooltip title="Archive" placement="top">
                          <IconButton
                            style={{
                              margin: "15px",
                              border: "1px solid grey",
                              width: "30px",
                              height: "30px",
                              padding: "5px",
                            }}
                            onClick={() => {
                              setOpenConfirmationDialog(true)
                            }}
                          >
                            <Trash2 />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Grid>
                  </>
                ) : null}

                {isAddLocationGroup ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={
                      <PlusCircle style={{ color: "#fff", width: "16px" }} />
                    }
                    style={{ margin: "10px" }}
                  >
                    ADD LOCATION GROUP
                  </Button>
                ) : null}
              </Grid>
            </>
          ) : null}

          <Grid container direction="row" className="root">
            {locationId.length > 0 ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TableContainer style={{ overflowX: "auto" }}>
                  <Table>
                    {showHeader ? (
                      <EnhancedTableHead
                        order={order}
                        edit={edit}
                        valuesOrder={valuesOrder}
                        onRequestSort={handleRequestSort}
                        showHeader
                        sorting={sorting}
                      />
                    ) : (
                      <LocationHeaders
                        edit={edit}
                        order={order}
                        valuesOrder={valuesOrder}
                        onRequestSort={handleRequestSort}
                        showHeader
                        sorting={sorting}
                        disabledOtherLocation={disabledOtherLocation}
                      />
                    )}
                    <TableBody>
                      <TableFilter
                        filterItem={filterItem}
                        setFilterItem={setFilterItem}
                        setClearVal={setClearVal}
                        clearVal={clearVal}
                        searchText={searchText}
                        disabledOtherLocation={disabledOtherLocation}
                      />
                      <>
                        {stableSort(
                          locationFilter,
                          getComparator(order, valuesOrder.length - 3)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((o, index) => {
                            return (
                              <TableRow key={o.locationId}>
                                <TableCell
                                  style={{
                                    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                                  }}
                                  className={classes.contentlocationAddress}
                                >
                                  {o?.locationId}
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                                  }}
                                  className={classes.contentlocationAddress}
                                >
                                  {o?.locationName}
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderRight: enableAvailableHand
                                      ? "1px solid rgba(0, 0, 0, 0.1)"
                                      : "1px solid rgba(0, 0, 0, 0.1)",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: enableAvailableHand
                                      ? "180px"
                                      : "50%",
                                    marginLeft: "0px",
                                    display: "inlineBlock",
                                  }}
                                  className={classes.contentlocationAddress}
                                >
                                  {o?.locationAddress?.line1}
                                </TableCell>
                                {enableAvailableHand ? (
                                  <>
                                    {edit ? (
                                      <>
                                        <TableCell
                                          style={{
                                            borderRight:
                                              "1px solid rgba(0, 0, 0, 0.1)",
                                          }}
                                          className={
                                            classes.contentlocationAddress
                                          }
                                        >
                                          {o?.inventory_on_hand}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            borderRight:
                                              "1px solid rgba(0, 0, 0, 0.1)",
                                          }}
                                          className={
                                            classes.contentlocationAddress
                                          }
                                        >
                                          $ {o?.msrp}
                                        </TableCell>
                                        <TableCell
                                          className={
                                            classes.contentlocationAddress
                                          }
                                        >
                                          $ {o?.offerPrice}
                                        </TableCell>
                                        <TableCell></TableCell>
                                      </>
                                    ) : (
                                      <>
                                        <TableCell
                                          style={{
                                            borderRight:
                                              "1px solid rgba(0, 0, 0, 0.1)",
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: "40px",
                                              width: "170px",
                                            }}
                                          >
                                            <TextField
                                              variant="outlined"
                                              size="small"
                                              className={classes.textInput}
                                              type="number"
                                              name="inventory_on_hand"
                                              value={o?.inventory_on_hand}
                                              helperText={
                                                o?.inventory_on_hand === ""
                                                  ? "Enter Inventory "
                                                  : "" ||
                                                    o?.inventory_on_hand
                                                      ?.length > 10
                                                    ? " can be of min-1 max-10 "
                                                    : ""
                                              }
                                              onChange={(event) => {
                                                setAnyChanges(true)
                                                if (
                                                  event.target.value < 0 ||
                                                  event.target.value.length > 10
                                                ) {
                                                  return 0
                                                }
                                                event.target.value =
                                                  event.target.value === ""
                                                    ? 0
                                                    : event.target.value
                                                var temp = _.cloneDeep(
                                                  locationIds
                                                )
                                                var index = _.findIndex(
                                                  locationIds,
                                                  { locationId: o?.locationId }
                                                )
                                                temp[index][
                                                  "inventory_on_hand"
                                                ] = _.trim(
                                                  parseFloat(event.target.value)
                                                )
                                                setFieldValue(
                                                  "locationIds",
                                                  temp
                                                )
                                              }}
                                            />
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            borderRight:
                                              "1px solid rgba(0, 0, 0, 0.1)",
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: "40px",
                                              width: "170px",
                                            }}
                                          >
                                            <TextField
                                              variant="outlined"
                                              size="small"
                                              className={classes.textInput}
                                              type="number"
                                              name="msrp"
                                              value={o?.msrp}
                                              helperText={
                                                o?.msrp === ""
                                                  ? "Enter List Price"
                                                  : parseFloat(o?.msrp) <
                                                    parseFloat(o?.offerPrice)
                                                    ? "List Price should be greater"
                                                    : "" || o?.msrp?.length > 10
                                                      ? "can be of min-1 max-10 "
                                                      : ""
                                              }
                                              onChange={(event) => {
                                                setAnyChanges(true)
                                                if (
                                                  event.target.value < 0 ||
                                                  event.target.value.length > 10
                                                ) {
                                                  return 0
                                                }
                                                event.target.value =
                                                  event.target.value === ""
                                                    ? 0
                                                    : event.target.value
                                                var temp = _.cloneDeep(
                                                  locationIds
                                                )
                                                var index = _.findIndex(
                                                  locationIds,
                                                  { locationId: o?.locationId }
                                                )
                                                temp[index]["msrp"] = _.trim(
                                                  parseFloat(event.target.value)
                                                )
                                                setFieldValue(
                                                  "locationIds",
                                                  temp
                                                )
                                              }}
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    {
                                                      Utils?.JSUtils?.getLSCurrencyCode()
                                                        .currency_symbol
                                                    }
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          </div>
                                        </TableCell>
                                        <TableCell>
                                          <div
                                            style={{
                                              height: "40px",
                                              width: "170px",
                                            }}
                                          >
                                            <TextField
                                              variant="outlined"
                                              size="small"
                                              className={classes.textInput}
                                              type="number"
                                              name="offerPrice"
                                              value={o?.offerPrice}
                                              helperText={
                                                o?.offerPrice === ""
                                                  ? "Enter Offer Price"
                                                  : parseFloat(o?.msrp) <
                                                    parseFloat(o?.offerPrice)
                                                    ? "List Price should be greater"
                                                    : "" ||
                                                      o?.offerPrice?.length > 10
                                                      ? "can be of min-1 max-10 "
                                                      : ""
                                              }
                                              onChange={(event) => {
                                                setAnyChanges(true)
                                                if (
                                                  event.target.value < 0 ||
                                                  event.target.value.length > 10
                                                ) {
                                                  return 0
                                                }
                                                event.target.value =
                                                  event.target.value === ""
                                                    ? 0
                                                    : event.target.value
                                                var temp = _.cloneDeep(
                                                  locationIds
                                                )
                                                var index = _.findIndex(
                                                  locationIds,
                                                  { locationId: o?.locationId }
                                                )
                                                temp[index][
                                                  "offerPrice"
                                                ] = _.trim(
                                                  parseFloat(event.target.value)
                                                )
                                                setFieldValue(
                                                  "locationIds",
                                                  temp
                                                )
                                              }}
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    {
                                                      Utils?.JSUtils?.getLSCurrencyCode()
                                                        .currency_symbol
                                                    }
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          </div>
                                        </TableCell>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                                {!edit ? (
                                  <TableCell
                                    style={{
                                      display: enableAvailableHand
                                        ? ""
                                        : "flex",
                                      justifyContent: enableAvailableHand
                                        ? ""
                                        : "flex-end",
                                    }}
                                  >
                                    {!edit ? (
                                      <CloseIcon
                                        style={{
                                          width: "18px",
                                          height: "20px",
                                          alignItems: "center",
                                          color: "gray",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDelete(o.locationId)
                                        }
                                      />
                                    ) : null}
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            )
                          })}
                      </>
                    </TableBody>
                  </Table>
                  <>
                    {locationFilter.length <= 0 ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "20px",
                        }}
                      >
                        <Typography variant="h6">No records found</Typography>
                      </div>
                    ) : null}
                  </>
                </TableContainer>
                {locationId.length >= 5 ? (
                  <TablePagination
                    style={{ display: "flex" }}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    onChangePage={handleChangePage}
                    count={locationFilter.length}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    component="div"
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                ) : null}
              </Grid>
            ) : null}
            {locationGroupIds?.map((o) => {
              return (
                <Grid style={{ padding: "5px" }} key={o._id}>
                  <Chip
                    variant="outlined"
                    icon={<LocationOnIcon />}
                    label={`${o?.locationId} - ${o?.locationName}`}
                    onDelete={!edit ? () => handleDelete(o) : null}
                    className={classes.chip}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </>
    )
  }
  const handledeleteLocation = (setFieldValue) => {
    setFieldValue("locationIds", [])
    setLocationIds([])
    setLocationId([])
  }
  const handleCustomCloseRedirect = (setFieldValue) => {
    setOpenConfirmationDialog(false)
    handledeleteLocation(setFieldValue)
    setAnyChanges(true)
    if (categoryFormdeleteFunc) {
      handleLocationDelete()
    }
  }
  return (
    <>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        handleCustomCloseRedirect={() =>
          handleCustomCloseRedirect(setFieldValue)
        }
        deleteContent="Are you sure you want to Delete ? Any unsaved changes will be lost."
      />
      <StyledDialog
        open={isLocationLookupOpen}
        onClose={(e) => setIsLocationLookupOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        disableBackdropClick
      >
        <HierarchyMapping
          isLookUp
          handleCloseLookUp={() => setIsLocationLookupOpen(false)}
          handleLookUpPrimaryAction={onSubmit}
          selectedItems={_.cloneDeep(locationId)}
          isWriteAllowed={isWriteAllowed}
        />
      </StyledDialog>
      {isAccordianPanel ? (
        <Adm.AccordianPanel
          isOpen={isOpenModal}
          isWriteAllowed={isWriteAllowed}
          renderContainer={(containerProps) =>
            customRenderContainer({
              ...containerProps,
              isAccordianPanel,
            })
          }
          label={"Location"}
          updateData={onUpdateData}
          resetData={resetData}
        />
      ) : (
        customRenderContainer(props)
      )}
    </>
  )
}

export { AddLocationAndLocationGroupUI }
export default AddLocationAndLocationGroupUI
