import * as Yup from "yup"
import * as Utils from "@utils"
export const formList = [
  {
    label: "Level",
    value: "1",
    inputType: "number",
    identifer: "level",
  },
  {
    label: "Name",
    value: "Jow",
    inputType: "text",
    identifer: "LastName",
  },
  {
    label: "Name",
    value: "James",
    inputType: "text",
    identifer: "firstName",
  },
  {
    label: "Title",
    value: "Mr.",
    inputType: "select",
    selectItems: ["Mr.", "Mrs.", "Ms."],
    identifer: "title",
  },
  {
    label: "Discount Value",
    value: "1",
    inputType: "tabtextfield",
    selectItems: [
      Utils?.JSUtils?.getLSCurrencyCode().currency_symbol || "$",
      "%",
    ],
    tabTypevalue: Utils?.JSUtils?.getLSCurrencyCode().currency_symbol || "$",
    identifer: "discount",
    textType: "tabtextfield",
  },
  {
    label: "Quantity",
    value: "9",
    inputType: "quantity",
    identifer: "quantity",
    infoMessage:
      "Quantity, which will need to be met before the promotion gets triggered or executed",
  },
  {
    label: "DOB",
    value: "20-12-1987",
    inputType: "calendar",
    identifer: "dob",
  },
  {
    label: "marketing",
    value: true,
    inputType: "toggle",
    textType: "box",
    renderTextTrue: "Yes",
    renderTextFalse: "No",
    identifer: "marketing",
  },
  {
    label: "communication",
    value: false,
    inputType: "toggle",
    textType: "box",
    renderTextTrue: "Yes",
    renderTextFalse: "No",
    identifer: "communication",
  },
  {
    label: "Prefere Contact",
    value: false,
    inputType: "checkBox",
    checkBoxLabel: "Phone",
    identifer: "preferedContact",
  },
]
