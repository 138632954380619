import React from "react"
import { irBlack } from "react-syntax-highlighter/dist/esm/styles/hljs"

function CustomLogo(props) {
  const { showMore, isImageUrl = false, largeImage = "", smallImage = "" } =
    props || {}

  if (isImageUrl) {
    return (
      <svg
        id="a"
        className="logo"
        style={showMore ? { transform: "scale(0.7) translateX(-25px)" } : {}}
        width={showMore ? "166" : "168"}
        height={showMore ? "166" : "159"}
        viewBox={showMore ? "0 0 168 159" : "0 0 168 159"}
      >
        <image
          id="segmentation"
          width={showMore ? "50" : "168"}
          height={showMore ? "166" : "159"}
          href={showMore ? smallImage : largeImage}
        />
      </svg>
    )
  }
  return (

    <>
      {showMore ? (
        <svg
          width="215"
          height="90"
          viewBox="0 0 215 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.6814 56.0927C41.6814 56.5047 41.5415 56.8544 41.2539 57.1342C40.9741 57.414 40.6244 57.5616 40.2124 57.5616C39.8005 57.5616 39.4508 57.4217 39.171 57.1342C38.8912 56.8544 38.7435 56.5047 38.7435 56.0927V34.4865C38.7435 34.1601 38.8446 33.8648 39.0544 33.6083C39.2643 33.344 39.5285 33.1653 39.8549 33.0565C40.1813 32.971 40.5 32.9943 40.8031 33.1186C41.1062 33.2508 41.3472 33.4684 41.5181 33.7715L49.8731 48.6549L58.228 33.7715C58.399 33.4684 58.6399 33.2508 58.943 33.1186C59.2461 32.9865 59.5648 32.9632 59.8912 33.0565C60.2176 33.1653 60.4819 33.3518 60.6762 33.6083C60.8705 33.8648 60.9715 34.1601 60.9715 34.4865V56.0927C60.9715 56.5047 60.8316 56.8544 60.5441 57.1342C60.2643 57.414 59.9145 57.5616 59.5026 57.5616C59.0907 57.5616 58.7409 57.4217 58.4611 57.1342C58.1814 56.8544 58.0337 56.5047 58.0337 56.0927V40.129L51.1477 52.401C50.8679 52.8984 50.4404 53.1549 49.8731 53.1549C49.3057 53.1549 48.8472 52.9062 48.5674 52.401L41.6814 40.129V56.0927V56.0927Z"
            fill="#DE127F"
          />
          <path
            d="M22.8938 33.1497C24.5881 33.1264 26.1814 33.4295 27.6736 34.0668C29.1658 34.6964 30.4637 35.5668 31.5751 36.6627C32.6865 37.7586 33.557 39.0487 34.2021 40.5332C34.8471 42.0099 35.1658 43.6031 35.1658 45.2974C35.1658 46.9917 34.8471 48.585 34.2021 50.0617C33.557 51.5383 32.6865 52.8285 31.5751 53.9166C30.4637 55.0047 29.1658 55.8674 27.6736 56.4969C26.1814 57.1264 24.5881 57.4295 22.8938 57.414H14.4689C14.057 57.414 13.7073 57.2741 13.4275 56.9865C13.1477 56.7067 13 56.357 13 55.9451V34.6264C13 34.2145 13.1399 33.8648 13.4275 33.585C13.7073 33.3052 14.057 33.1575 14.4689 33.1575H22.8938V33.1497ZM15.9378 36.0876V54.4684H22.8938C24.1762 54.4917 25.3886 54.2585 26.5155 53.7845C27.6503 53.3026 28.6373 52.6497 29.4845 51.8259C30.3316 51.0021 31 50.0228 31.4896 48.9036C31.9793 47.7845 32.228 46.5798 32.228 45.2974C32.228 44.015 31.987 42.8104 31.4896 41.6912C31 40.572 30.3316 39.5928 29.4845 38.7534C28.6373 37.914 27.6425 37.2611 26.5155 36.7793C25.3808 36.2974 24.1762 36.072 22.8938 36.0953H15.9378V36.0876Z"
            fill="#fff"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1761_2631"
              x1="49.8109"
              y1="32.5357"
              x2="49.9119"
              y2="58.4088"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stopColor="#DE127F" />
              <stop offset="1" stopColor="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1761_2631"
              x1="24.0907"
              y1="33.0409"
              x2="24.0907"
              y2="60.5228"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stopColor="white" />
              <stop offset="1" stopColor="#787878" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="200"
          height="70"
          viewBox="0 0 200 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M103.188 37.1054C103.188 37.4284 103.078 37.7026 102.853 37.9219C102.633 38.1413 102.359 38.2571 102.036 38.2571C101.713 38.2571 101.439 38.1474 101.22 37.9219C101 37.7026 100.884 37.4284 100.884 37.1054V20.1655C100.884 19.9096 100.964 19.678 101.128 19.4769C101.293 19.2697 101.5 19.1296 101.756 19.0443C102.012 18.9772 102.262 18.9955 102.499 19.093C102.737 19.1966 102.926 19.3672 103.06 19.6049L109.61 31.2739L116.161 19.6049C116.295 19.3672 116.484 19.1966 116.721 19.093C116.959 18.9894 117.209 18.9711 117.465 19.0443C117.721 19.1296 117.928 19.2758 118.08 19.4769C118.233 19.678 118.312 19.9096 118.312 20.1655V37.1054C118.312 37.4284 118.202 37.7026 117.977 37.9219C117.757 38.1413 117.483 38.2571 117.16 38.2571C116.837 38.2571 116.563 38.1474 116.344 37.9219C116.124 37.7026 116.008 37.4284 116.008 37.1054V24.5894L110.61 34.211C110.39 34.601 110.055 34.8021 109.61 34.8021C109.165 34.8021 108.806 34.6071 108.587 34.211L103.188 24.5894V37.1054V37.1054Z"
            fill="#DE127F"
          />
          <path
            d="M139.121 36.5937C139.255 36.8983 139.273 37.1969 139.17 37.4894C139.084 37.7271 138.944 37.916 138.749 38.05C138.554 38.1841 138.329 38.2572 138.073 38.2572C137.597 38.2572 137.244 38.0439 137.025 37.6174L134.746 32.8827H124.972L122.668 37.6174C122.565 37.8245 122.419 37.983 122.236 38.0927C122.047 38.2024 121.846 38.2572 121.62 38.2572C121.383 38.2572 121.157 38.1902 120.956 38.05C120.749 37.916 120.615 37.7271 120.548 37.4894C120.426 37.1665 120.444 36.8679 120.597 36.5937L128.811 19.6781C129.03 19.2333 129.383 19.0139 129.859 19.0139C130.078 19.0139 130.285 19.0749 130.474 19.1906C130.663 19.3125 130.797 19.4709 130.882 19.6781L139.121 36.5937ZM133.618 30.555L129.859 22.8284L126.099 30.555H133.625H133.618Z"
            fill="#DE127F"
          />
          <path
            d="M156.445 24.8999C156.445 25.5336 156.341 26.1369 156.14 26.7158C155.933 27.2947 155.646 27.8248 155.281 28.3001C154.915 28.7754 154.476 29.1897 153.977 29.5432C153.471 29.8905 152.923 30.1525 152.325 30.3231L155.884 36.5141C156.036 36.8066 156.073 37.0991 156 37.3977C155.921 37.6963 155.738 37.9339 155.451 38.1045C155.281 38.2081 155.092 38.2569 154.891 38.2569C154.69 38.2569 154.495 38.2081 154.312 38.1045C154.129 38.0009 153.989 37.8547 153.891 37.6719L149.772 30.5547H143.679V37.1052C143.679 37.4282 143.569 37.7024 143.344 37.9217C143.124 38.1411 142.85 38.2569 142.527 38.2569C142.204 38.2569 141.93 38.1472 141.711 37.9217C141.491 37.7024 141.375 37.4282 141.375 37.1052V20.2932C141.375 19.9703 141.485 19.6961 141.711 19.4767C141.93 19.2573 142.204 19.1416 142.527 19.1416H143.319C143.831 19.1416 144.483 19.1416 145.275 19.1294C146.067 19.1233 146.945 19.1172 147.895 19.1172H150.711C151.515 19.1172 152.264 19.2695 152.965 19.562C153.666 19.8606 154.269 20.275 154.781 20.8051C155.293 21.3352 155.695 21.9507 155.994 22.6453C156.292 23.3461 156.445 24.0956 156.445 24.8999V24.8999ZM154.141 24.8755C154.141 24.4003 154.05 23.9493 153.873 23.5289C153.696 23.1084 153.453 22.7428 153.142 22.426C152.837 22.1091 152.472 21.8654 152.051 21.6826C151.631 21.5058 151.186 21.4144 150.705 21.4144H143.667V28.2453H150.705C151.168 28.2453 151.606 28.1538 152.021 27.9771C152.441 27.8004 152.801 27.5567 153.111 27.2459C153.422 26.9351 153.666 26.5817 153.855 26.1735C154.044 25.7652 154.135 25.3265 154.135 24.8695L154.141 24.8755Z"
            fill="#DE127F"
          />
          <path
            d="M158.851 21.4205C158.528 21.4205 158.254 21.3109 158.035 21.0854C157.815 20.866 157.7 20.5918 157.7 20.2689C157.7 19.9459 157.809 19.6717 158.035 19.4523C158.254 19.233 158.528 19.1172 158.851 19.1172H172.848C173.171 19.1172 173.445 19.2269 173.665 19.4523C173.884 19.6717 174 19.9459 174 20.2689C174 20.5918 173.89 20.866 173.665 21.0854C173.439 21.3048 173.171 21.4205 172.848 21.4205H167.011V37.1113C167.011 37.4343 166.901 37.7085 166.675 37.9278C166.456 38.1472 166.182 38.263 165.859 38.263C165.536 38.263 165.262 38.1533 165.042 37.9278C164.823 37.7085 164.707 37.4343 164.707 37.1113L164.683 21.4205H158.845H158.851Z"
            fill="#DE127F"
          />
          <path
            d="M33.757 19.1174C35.0854 19.0992 36.3346 19.3368 37.5045 19.8365C38.6745 20.33 39.6921 21.0125 40.5635 21.8717C41.4348 22.7309 42.1173 23.7424 42.6231 24.9063C43.1288 26.064 43.3787 27.3132 43.3787 28.6416C43.3787 29.97 43.1288 31.2191 42.6231 32.3769C42.1173 33.5347 41.4348 34.5462 40.5635 35.3993C39.6921 36.2524 38.6745 36.9287 37.5045 37.4223C36.3346 37.9159 35.0854 38.1535 33.757 38.1413H27.1517C26.8287 38.1413 26.5545 38.0317 26.3351 37.8062C26.1158 37.5868 26 37.3126 26 36.9897V20.2752C26 19.9522 26.1097 19.678 26.3351 19.4587C26.5545 19.2393 26.8287 19.1235 27.1517 19.1235H33.757V19.1174ZM28.3033 21.4208V35.8319H33.757C34.7625 35.8502 35.713 35.6674 36.5966 35.2957C37.4863 34.9179 38.2601 34.406 38.9243 33.7601C39.5885 33.1142 40.1126 32.3464 40.4964 31.469C40.8803 30.5915 41.0753 29.647 41.0753 28.6416C41.0753 27.6361 40.8864 26.6917 40.4964 25.8142C40.1126 24.9367 39.5885 24.1689 38.9243 23.5108C38.2601 22.8527 37.4802 22.3409 36.5966 21.9631C35.707 21.5853 34.7625 21.4086 33.757 21.4269H28.3033V21.4208Z"
            fill="#fff"
          />
          <path
            d="M63.3958 29.7872C63.0728 29.7872 62.7986 29.6775 62.5793 29.4521C62.3599 29.2327 62.2441 28.9585 62.2441 28.6356C62.2441 28.3126 62.3538 28.0384 62.5793 27.819C62.8047 27.5997 63.0728 27.4839 63.3958 27.4839H70.8969C71.2199 27.4839 71.4941 27.5936 71.7134 27.819C71.9328 28.0384 72.0486 28.3126 72.0486 28.6356C72.0486 29.9639 71.7987 31.2192 71.293 32.3831C70.7872 33.553 70.1047 34.5706 69.2334 35.442C68.362 36.3134 67.3444 37.0019 66.1744 37.5016C65.0045 38.0074 63.7553 38.2572 62.4269 38.2572C61.0986 38.2572 59.8433 38.0074 58.6794 37.5016C57.5095 36.9958 56.4919 36.3134 55.6205 35.442C54.7491 34.5706 54.0606 33.553 53.5609 32.3831C53.0551 31.2131 52.8053 29.9639 52.8053 28.6356C52.8053 27.3072 53.0551 26.058 53.5609 24.8881C54.0667 23.7181 54.7491 22.7005 55.6205 21.8291C56.4919 20.9577 57.5095 20.2753 58.6794 19.7695C59.8494 19.2637 61.0986 19.0139 62.4269 19.0139C63.9259 19.0139 65.3457 19.3491 66.6863 20.0132C68.0269 20.6774 69.1542 21.6097 70.0804 22.8041C70.2876 23.0966 70.3607 23.3769 70.3119 23.6511C70.2632 23.9436 70.1352 24.1812 69.928 24.3701C69.7209 24.559 69.4649 24.6504 69.1603 24.6504C68.7825 24.6504 68.4839 24.5042 68.2645 24.2178C67.5455 23.2976 66.6802 22.5847 65.6687 22.0789C64.6511 21.5732 63.5725 21.3234 62.433 21.3234C61.4276 21.3234 60.477 21.5183 59.5935 21.9022C58.7099 22.2861 57.9299 22.8102 57.2657 23.4744C56.6015 24.1386 56.0775 24.9185 55.6936 25.8021C55.3097 26.6917 55.1147 27.6362 55.1147 28.6416C55.1147 29.6471 55.3036 30.5977 55.6936 31.4812C56.0775 32.3709 56.6015 33.1447 57.2657 33.8089C57.9299 34.4731 58.7099 34.9972 59.5935 35.3811C60.477 35.7649 61.4276 35.9599 62.433 35.9599C63.3349 35.9599 64.1941 35.8076 65.0045 35.4968C65.8149 35.1861 66.5401 34.7595 67.1799 34.205C67.8197 33.6505 68.3559 32.9985 68.7946 32.249C69.2273 31.4995 69.5137 30.6769 69.6538 29.7933H63.408L63.3958 29.7872Z"
            fill="#fff"
          />
          <path
            d="M83.7667 21.4205C83.4437 21.4205 83.1695 21.3109 82.9501 21.0854C82.7308 20.866 82.615 20.5918 82.615 20.2689C82.615 19.9459 82.7247 19.6717 82.9501 19.4523C83.1695 19.233 83.4437 19.1172 83.7667 19.1172H97.7634C98.0864 19.1172 98.3606 19.2269 98.58 19.4523C98.7993 19.6717 98.9151 19.9459 98.9151 20.2689C98.9151 20.5918 98.8054 20.866 98.58 21.0854C98.3545 21.3048 98.0864 21.4205 97.7634 21.4205H91.9259V37.1113C91.9259 37.4343 91.8162 37.7085 91.5907 37.9278C91.3713 38.1472 91.0971 38.263 90.7742 38.263C90.4512 38.263 90.177 38.1533 89.9577 37.9278C89.7383 37.7085 89.6225 37.4343 89.6225 37.1113L89.5981 21.4205H83.7606H83.7667Z"
            fill="#fff"
          />
          <path
            d="M49.2102 36.9897C49.2102 37.3126 49.1005 37.5869 48.8751 37.8062C48.6557 38.0256 48.3815 38.1414 48.0585 38.1414C47.7356 38.1414 47.4614 38.0317 47.242 37.8062C47.0226 37.5869 46.9069 37.3126 46.9069 36.9897V20.2813C46.9069 19.9584 47.0165 19.6841 47.242 19.4648C47.4614 19.2454 47.7356 19.1296 48.0585 19.1296C48.3815 19.1296 48.6557 19.2393 48.8751 19.4648C49.0944 19.6841 49.2102 19.9584 49.2102 20.2813V36.9897Z"
            fill="#fff"
          />
          <path
            d="M77.9897 37.0505C77.9897 37.3734 77.8801 37.6476 77.6546 37.867C77.4352 38.0864 77.161 38.2022 76.8381 38.2022C76.5151 38.2022 76.2409 38.0925 76.0215 37.867C75.8022 37.6476 75.6864 37.3734 75.6864 37.0505V20.3421C75.6864 20.0191 75.7961 19.7449 76.0215 19.5256C76.2409 19.3062 76.5151 19.1904 76.8381 19.1904C77.161 19.1904 77.4352 19.3001 77.6546 19.5256C77.874 19.7449 77.9897 20.0191 77.9897 20.3421V37.0505Z"
            fill="#fff"
          />
          <path
            d="M122.28 50H123.33L120.15 43H119.16L115.99 50H117.02L117.79 48.25H121.51L122.28 50ZM118.14 47.45L119.65 44.02L121.16 47.45H118.14ZM128.374 50H131.324C133.574 50 135.104 48.58 135.104 46.5C135.104 44.42 133.574 43 131.324 43H128.374V50ZM129.374 49.13V43.87H131.264C132.994 43.87 134.104 44.93 134.104 46.5C134.104 48.07 132.994 49.13 131.264 49.13H129.374ZM148.086 50L148.076 43H147.256L144.376 47.92L141.456 43H140.636V50H141.596V44.93L144.126 49.15H144.586L147.116 44.9L147.126 50H148.086ZM154.187 50H155.187V43H154.187V50ZM166.302 43V48.22L162.102 43H161.282V50H162.282V44.78L166.482 50H167.302V43H166.302Z"
            fill="#fff"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2286_1054"
              x1="109.562"
              y1="18.636"
              x2="109.641"
              y2="38.9213"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2286_1054"
              x1="129.78"
              y1="18.563"
              x2="129.859"
              y2="38.8483"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2286_1054"
              x1="148.864"
              y1="18.4835"
              x2="148.937"
              y2="38.7687"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_2286_1054"
              x1="165.847"
              y1="18.4225"
              x2="165.926"
              y2="38.7078"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_2286_1054"
              x1="34.6954"
              y1="19.0321"
              x2="34.6954"
              y2="40.5787"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_2286_1054"
              x1="62.4208"
              y1="19.0139"
              x2="62.4208"
              y2="40.5544"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_2286_1054"
              x1="90.7681"
              y1="19.0319"
              x2="90.7681"
              y2="40.5785"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_2286_1054"
              x1="48.0585"
              y1="19.0321"
              x2="48.0585"
              y2="40.5788"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_2286_1054"
              x1="76.8381"
              y1="19.032"
              x2="76.8381"
              y2="40.5786"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </>
  )
}

export { CustomLogo }
export default CustomLogo
