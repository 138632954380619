import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import * as Yup from "yup"
import { Formik } from "formik"
import { Helmet } from "react-helmet"
import { CheckCircleIcon, Edit, Slash } from "@iconsGallery"
import {
    IconButton,
    TextField,
    Grid,
    Modal,
    Card,
    Typography,
    useMediaQuery,
    Divider,
    InputAdornment,
    Icon,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { spacing } from "@material-ui/system"
import * as Adm from "@adm"
import * as Utils from "@utils"
import { useTranslation } from "react-i18next"
import * as constantsAPI from "@constants"
import _ from 'lodash'
import useStyles from "./styles"
import CustomHeaderContainer from "../CustomHeaderContainer"
import AttributeTypeCheck from "./AttributeTypeCheck"
import ReactHtmlParser from 'react-html-parser';
import clsx from "clsx"


const AttributeLookUpTable = (props) => {
    const {
        containerProps,
        values,
        formikSetFieldValue = () => { },
        edit,
        isEditProduct = true,
        setAnyChanges,
        setAllowPrompt = () => { },
        isAccordian = true,
        handleAttributeSet = () => { },
        isLoading = false,
    } = props


    const classes = useStyles()
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertType, setAlertType] = useState("")
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const updateData = (val, ind, idx) => {
        let updateState = _.cloneDeep(values)
        let attributeFieldType = updateState[ind]['attribute_field_type']
        if (attributeFieldType === "Multi Choice") {
            let defalutcheck = _.cloneDeep(updateState[ind].values) || []
            if (defalutcheck.includes(updateState[ind].attribute_values[idx]._id)) {
                _.remove(defalutcheck, x => x === updateState[ind].attribute_values[idx]._id)
                updateState[ind].values = defalutcheck
            }
            else {
                defalutcheck.push(updateState[ind].attribute_values[idx]._id)
                updateState[ind].values = defalutcheck
            }
        }
        else {
            updateState[ind].values = val
        }

        handleAttributeSet(updateState)
    }

    return (
        <>
            {values?.length === 0 && isAccordian && !isLoading ?
                <Grid container spacing={2} justify="center" alignItems='center' ><Icon color='error'>error</Icon><Typography variant='h4' style={{ paddingBlock: 24, paddingLeft: 8, fontWeight: 'normal', fontSize: isMobile && "12px" }}>Product not yet mapped to a attribute</Typography> </Grid >
                :
                <>
                    {isEditProduct ?
                        <>
                            <Grid container direction="row" style={{ width: "100%", border: values.length === 0 ? "" : '1px solid #EEEEEE' }} item lg={12} xs={12} md={12} sm={12}>
                                {values?.map((value, index) => {
                                    let evenClsx = index % 2 === 0
                                    if (!value.isDeleted) {
                                        return (
                                            <Grid container item direction="row" key={index}
                                                className={clsx(classes.attributeList, {
                                                    [classes.accordianCss]: isAccordian,
                                                    [classes.evenClsx]: isAccordian && evenClsx,
                                                })}
                                                lg={isAccordian ? 6 : 12} xs={isAccordian ? 6 : 12} md={isAccordian ? 6 : 12} sm={isAccordian ? 6 : 12}
                                            >
                                                <Grid item lg={isAccordian ? 4 : 4} sm={isAccordian ? 6 : 6} xs={isAccordian ? 12 : ""} md={isAccordian ? 6 : 6}
                                                    className={clsx(classes.textHeader, {})}
                                                >
                                                    <Typography variant="h5" style={{ marginLeft: isAccordian ? "" : "10px", fontWeight: 600 }} >
                                                        {value.attribute_name && value.attribute_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={isAccordian ? 8 : 8} sm={isAccordian ? 6 : 6} xs={isAccordian ? 12 : 12} md={isAccordian ? 6 : 6}>
                                                    <AttributeTypeCheck
                                                        value={value}
                                                        index={index}
                                                        containerProps={containerProps}
                                                        edit={edit}
                                                        setAnyChanges={setAnyChanges}
                                                        setAllowPrompt={setAllowPrompt}
                                                        updateData={updateData}
                                                        isAccordian={isAccordian}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                })}
                            </Grid>
                        </>
                        :
                        <Grid container direction="row" item style={{ width: "100%", border: values.length === 0 ? "" : '1px solid #EEEEEE' }} lg={12} xs={12} md={12} >
                            {values?.map((value, index) => {
                                let evenClsx = index % 2 === 0
                                let displayValue = ""
                                if (value.attribute_field_type === "List" || value.attribute_field_type === "Single Choice") {
                                    displayValue = value?.values?.name
                                }
                                else if (value.attribute_field_type === "Boolean") {
                                    displayValue = value?.values ? "Yes" : "No"
                                }
                                else if (value.attribute_field_type === "Text Area") {
                                    displayValue = ReactHtmlParser(value?.values)
                                }
                                else if (value.attribute_field_type === "Multi Choice") {
                                    let temp = []
                                    value.attribute_values.map(obj => {
                                        if (value?.values?.includes(obj._id)) {
                                            temp.push(obj?.name)
                                        }
                                    })
                                    displayValue = temp.join(',')
                                }
                                else if (value.attribute_field_type === "Date") {
                                    let date = new Date(value?.values)
                                    let dateSplit = date.toString().split(' ')
                                    let dateMDY = dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[3]
                                    displayValue = dateMDY
                                }
                                else {
                                    displayValue = value?.values
                                }
                                if (!value.isDeleted) {
                                    return (
                                        <Grid container direction="row"
                                            item
                                            lg={isAccordian ? 6 : 12} md={isAccordian ? 6 : 12} xs={isAccordian ? 12 : 12} sm={isAccordian ? 6 : 12}
                                            className={clsx(classes.attributeList, {
                                                [classes.accordianCss]: isAccordian,
                                                [classes.evenClsx]: isAccordian && evenClsx,
                                            })}

                                        >
                                            <Grid item lg={isAccordian ? 4 : 5} md={isAccordian ? 4 : 5} sm={isAccordian ? 4 : 5} xs={isAccordian ? 4 : 5} style={{ width: '100%', }}>
                                                <Typography variant="h5" style={{ fontSize: '14px', fontWeight: 600 }}>
                                                    {value.attribute_name}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={isAccordian ? 8 : 7} xs={isAccordian ? 8 : 7} md={isAccordian ? 8 : 7} sm={isAccordian ? 8 : 7} style={{ width: '100%', }}>
                                                <Typography variant="h6"
                                                    className={clsx(classes.textValues, {
                                                        [classes.textAreaWrap]: value.attribute_field_type === "Text Area"
                                                    })}
                                                >
                                                    {displayValue}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            })}
                        </Grid>
                    }
                </>
            }
        </>
    )
}

export default AttributeLookUpTable