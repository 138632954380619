import { makeStyles } from "@material-ui/core/styles";

const customTableStyles = makeStyles((theme) => ({

  selectCheckList: {
    padding: "0 !important",
    '& .MuiButtonBase-root ': {
      padding: "7px 9px !important",
    }
  },
  selectCheckbox: {
    '& .MuiInputLabel-formControl ': {
      top: "-7px",
      left: "15px",
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
    '& .MuiFormLabel-root.Mui-focused': {
      "color": "var(--primaryColor)",
    }
  },
  table: {
    minWidth: 700,
  },
  tableCell: {
    fontSize: "14px",
    fontWeight: "400",
    height: "53px",
  },
  tableHead: {
    fontSize: "14px",
    fontWeight: "700",
  },
  headFont: {
    fontSize: "14px",
    fontWeight: "500",
    padding: "20px",
  },
  enabledBox: {
    borderRadius: "4px",
    backgroundColor: theme.palette.table.enabledBox.backgroundColor,
    color: theme.palette.table.enabledBox.textColor,
    textAlign: "center",
    width: "80px",
    fontSize: "14px",
    fontWeight: "400px",
    padding: "3px",
  },
  disabledBox: {
    borderRadius: "4px",
    backgroundColor: theme.palette.table.disabledBox.backgroundColor,
    color: theme.palette.table.disabledBox.textColor,
    textAlign: "center",
    width: "80px",
    padding: "3px",
    fontSize: "14px",
    fontWeight: "400px",
  },
  card: {
    borderRadius: "0px",
  },
  headerFont: {
    fontSize: "17px",
    fontWeight: "500",
  },
  tableHeader: {
    backgroundColor: theme.palette.table.tableHeadBackground,
    borderTop: theme.palette.table.tableBorder,
  },
  tableHeadFont: {
    fontSize: "14px",
    fontWeight: "700",
  },
  switchCellCss: {
    '& .MuiSwitch-switchBase': {

      color: 'rgba(158, 158, 158, 1) !important',

    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(158, 158, 158, 1) !important',
    },
    '& .Mui-checked.MuiSwitch-switchBase': {
      color: 'var(--primaryColor) !important',
    },
    '& .Mui-checked.MuiSwitch-switchBase + .MuiSwitch-track': {
      backgroundColor: 'var(--primaryColor) !important',
    },
  },
  actionDropdown: {
    '& .MuiSelect-select:focus': {
      backgroundColor: "unset"
    },
    '& .MuiSelect-nativeInput': {
    },
  }
}));

export default customTableStyles;
