import * as React from "react"
import { Card } from "@material-ui/core"

import { Formik } from "formik"
import _ from "lodash"
import * as Adm from "@adm"
import * as Utils from "@utils"
import {
  customOnSearchUpdate,
  customRenderContainer,
  customRenderHeader,
} from "./renderProps"

function GlobalSearchListFacet(props) {
  const {
    isMultipleSelect = false,
    title = "Buy Product ID",
    list = [],
    onUpdate = () => "",
    isEditMode = true,
    isOpenMode = false,
    listColumnSplit = false,
    inputField = false,
    isOptional = false,
    variable1 = "",
    data = {},
    idName = "Product Id",
    titleName = "Product Name",
    idNameAPIKey = "product_id",
    titleNameAPIKey = "product_title",
    searchAPIString = "searchBuyProducts",
    additionalButton = null
  } = props || {}

  const [isAnyChanges, setAnyChanges] = React.useState(false)
  const [isIntialCount, setIntialCount] = React.useState(0)
  const [fetchData, setFetchData] = React.useState(list)
  const [removedFetchData, setRemoveFetchData] = React.useState(
    JSON.parse(JSON.stringify(list))
  )
  React.useEffect(() => {
    setFetchData([...list])
  }, [list])

  const stateProps = {
    fetchData,
    setFetchData,
    idName,
    titleName,
    idNameAPIKey,
    titleNameAPIKey,
    searchAPIString,
    removedFetchData,
    setRemoveFetchData,
    isOptional,
    isMultipleSelect,
    isIntialCount,
    setIntialCount,
    isAnyChanges,
    setAnyChanges,
    additionalButton
  }

  const attrAccordion = {
    isOpen: isOpenMode,
    isEditMode,
    label: title,
    variable1: variable1,
    updateData: async () => {
      setRemoveFetchData(fetchData)
      await onUpdate(fetchData)
    },
    renderHeader: (accorProps) =>
      customRenderHeader({ ...accorProps, ...stateProps }),
    renderContainer: (accorProps) =>
      customRenderContainer({ ...accorProps, ...stateProps }),
  }

  return <Adm.AccordianPanel {...attrAccordion} />
}
export {
  GlobalSearchListFacet,
  customOnSearchUpdate as GlobalSearchListFacetOnSearchUpdate,
}
export default GlobalSearchListFacet
