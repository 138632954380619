import styled from "styled-components"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core"

export const Wrapper = styled.div`
  display: flex;
`

export const StepTrackWrapper = styled.div`
  margin-top: 14px;
`

const ellipsisCSS = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const AccordionTag = styled(Accordion)`
  border-radius: 10px;
  margin-bottom: 16px;
  min-width: ${(props) => props.width};
  max-width: 800px;
  width: 100%;
`

export const AccordionSummaryTag = styled(AccordionSummary)`
  background: rgb(239 239 239);
  border-radius: 10px;
  width: 100%;
  ${(props) =>
    props.status === "InProgress" ? "background-color: #7dc054;" : ""}
`

export const AccordionDetailsTag = styled(AccordionDetails)`
  background-color: rgb(245 245 245);
  border-radius: 11px;
  padding: 14px 16px;
  margin-bottom: 8px;
`

export const SummaryHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SummaryHeader = styled.div`
  display: flex;
  ${(props) => (props.status === "InProgress" ? "color: white;" : "")}
`

export const SummaryDateTime = styled.div`
  ${(props) => (props.status === "InProgress" ? "color: white;" : "")}
`

export const Heading = styled(Typography)`
  width: 150px;
  font-weight: bold;
  color: ${(props) => (props.status === "InProgress" ? "white" : "#b73683")};
  text-wrap: nowrap;
`

function stepBallFillColorMapper(status) {
  if (status === "Yet to Start") {
    return "white"
  } else {
    return "#7dc054"
  }
}

function stepBallBorderColorMapper(status) {
  if (status === "Completed") {
    return "#7dc054"
  } else if (status === "InProgress") {
    return "white"
  } else {
    return "#bdb7be"
  }
}

export const StepBall = styled.div`
  border-radius: 100%;
  height: 15px;
  width: 15px;
  background-color: ${(props) => stepBallFillColorMapper(props.status)};
  border: 2px solid ${(props) => stepBallBorderColorMapper(props.status)};
  ${(props) =>
    props.status === "InProgress"
      ? "box-shadow: 1px 0px 8px 0 rgba(0, 0, 0, 0.2), 0px 0px 20px 0 rgba(0, 0, 0, 0.19);"
      : ""}
`

function stepLineColorMapper(status) {
  if (status === "Completed") {
    return "#7dc054"
  } else {
    return "#c1c1c1"
  }
}

export const StepLine = styled.div`
  margin: 0px 6px;
  margin-top: -1px;
  height: 100%;
  width: 3px;
  border-radius: 25px;
  background-color: ${(props) => stepLineColorMapper(props.status)};
`

export const MidLine = styled.div`
  background-color: #aba4a4;
  height: 1px;
  width: 20px;
  border-radius: 100%;
  margin: 7px 4px;
`
