import React, { useState, useEffect } from "react"
import styled from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography as MuiTypography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { spacing } from "@material-ui/system"
import * as Utils from "@utils"
import * as Adm from "@adm"
import { useDispatch, useSelector } from "react-redux"
import { addOpenDate } from "@reduxHelper"
import _, { values } from "lodash"
import moment from "moment"

const Typography = styled(MuiTypography)(spacing)
const useStyles = makeStyles({
  inputFieldText: {
    marginBottom: "8px",
    paddingLeft: "0px",
    fontWeight: "800",
  },
  inputFieldText1: {
    marginBottom: "0px",
    marginTop: "25px",
    paddingLeft: "0px",
    paddingTop: "10px",
  },
  inputFieldText2: {
    marginBottom: "0px",
    marginTop: "25px",
    paddingLeft: "4px",
    paddingTop: "10px",
    fontWeight: "800",
  },
  optionalFieldText: {
    color: "#9E9E9E",
    fontSize: "12px",
    marginBottom: "0px",
    marginTop: "25px",
    paddingLeft: "0px",
    marginLeft: "10px",
  },
  smallTextInput: { width: "445px" },
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
      width: "100%",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
})
const CreateOpenDate = (props) => {
  const { isTablet, isDesktop } = props
  const { useWindowSize } = Utils || {}
  const [height, width] = useWindowSize()
  const classes = useStyles(width)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.addLocationReducer)
  const [startDateError, setStartDateError] = useState(false)
  const [timeZoneOpen, setTimeZoneOpen] = React.useState(false)
  const [formData, setFormData] = useState(
    _.get(reduxData, "openDate") !== null
      ? reduxData.openDate
      : {
        selectedOpenDate: moment(new Date()).format("yyyy-MM-DD"),
        selectedCloseDate: "",
        timeZone: Utils.JSUtils.getTimeZoneData()[0],
      }
  )
  const closingDateError =
    Boolean(
      Date.parse(formData["selectedOpenDate"]) >
      Date.parse(formData["selectedCloseDate"])
    ) === true
      ? "Select valid Close Date"
      : ""
  closingDateError !== ""
    ? props.setEndDateError(true)
    : props.setEndDateError(false)

  const handleFormChange = (val, type) => {
    const newFormData = { ...formData }
    newFormData[type] = val
    setFormData(newFormData)
    if (newFormData["selectedOpenDate"] === "") {
      setStartDateError(true)
    } else {
      setStartDateError(false)
    }
  }
  const handleTimeZoneChange = (e, val) => {
    const newFormData = { ...formData }
    newFormData["timeZone"] = val
    setFormData(newFormData)
    if (newFormData["selectedOpenDate"] === "") {
      setStartDateError(true)
    } else {
      setStartDateError(false)
    }
  }
  useEffect(() => {
    dispatch(addOpenDate(formData))
  }, [formData])

  return (
    <>
      <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="center"
        style={{
          justifyContent: "space-between",
          paddingLeft: "25px",
          height: "60px",
        }}
      >
        <Grid item>
          <Typography
            variant="h6"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {window?.globalTranslation("Open Date")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xl={12}
        lg={10}
        md={12}
        sm={12}
        xs={12}
        spacing={isDesktop ? 4 : 0}
        style={{ margin: "00px 0px 0px 0px", paddingBottom: "10px" }}
      >
        <Grid
          container
          direction="row"
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ padding: "16px 20px 20px 30px" }}
        >
          <Grid container direction="column" item lg={4} md={9} sm={9} xs={12}>
            <Typography variant="h5" className={classes.inputFieldText}>
              Time Zone <span style={{ color: "red" }}> * </span>
            </Typography>
            <Adm.DropdownFilter
              list={Utils.JSUtils.getTimeZoneData()}
              defaultValue={formData.timeZone}
              selectedValue={values?.timeZone}
              onChangeEvent={handleTimeZoneChange}
              popupOpen={timeZoneOpen}
              setPopupOpen={setTimeZoneOpen}
              disableClearable={true}
              classNameTextField={classes.autoCompleteHeight}
              placeholder={"Select Time Zone"}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ padding: "16px 30px 25px 30px", margin: "0px" }}
          spacing={isTablet ? 4 : 1}
        >
          <Grid
            container
            direction="column"
            item
            xs={12}
            sm={9}
            md={9}
            lg={4}
            style={{ margin: "0px 50px 20px 0px" }}
          >
            <Typography variant="h5" className={classes.inputFieldText}>
              Open Date <span style={{ color: "red" }}>* </span>
            </Typography>
            <Adm.Calender
              fullWidth
              style={{ width: "100%" }}
              isTimeEnable={false}
              allowFutureDate={true}
              error={startDateError ? "Select Open Date" : ""}
              date={formData.selectedOpenDate}
              onUpdateCalender={({ date }) => {
                handleFormChange(date, "selectedOpenDate")
              }}
              className="locationDateNative"
            />
          </Grid>
          <Grid
            container
            direction="column"
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            style={{ margin: "0px 50px 20px 0px" }}
          >
            <Typography variant="h5" className={classes.inputFieldText}>
              Close Date
            </Typography>
            <Adm.Calender
              fullWidth
              style={{ width: "100%" }}
              isTimeEnable={false}
              allowFutureDate={true}
              date={formData.selectedCloseDate || ""}
              error={closingDateError}
              onUpdateCalender={({ date }) => {
                handleFormChange(date, "selectedCloseDate")
              }}
              className="locationDateNative"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export { CreateOpenDate }
export default CreateOpenDate
