import * as React from "react"
import { Editor, EditorTools } from "@progress/kendo-react-editor"
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  FontSize,
  FontName,
  Link,
  Unlink,
  ViewHtml,
} = EditorTools

function RichTextWYSIWYG(props) {
  const {
    content = "",
    onUpdate = () => { },
    style = {},
  } = props || {}

  return (
    <Editor
      tools={[
        [Bold, Italic, Underline, Strikethrough],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        [ViewHtml],
      ]}
      defaultContent={content}
      onChange={onUpdate}
      style={style}
    />
  )
}

export { RichTextWYSIWYG }
export default RichTextWYSIWYG
