import { useEffect } from "react"
import { useZipCodeBox } from "./useZipCodeBox"
import * as Render from "./renderProps"
import * as StyledDOM from "./styles"
function ZipCodeBox(props) {
  const {
    renderLabel = Render?.renderLabel,
    renderZipField = Render?.renderZipField,
    classes = [],
    ...restProps
  } = props

  const stateZipCodeBox = useZipCodeBox(restProps)

  return (
    <StyledDOM.SZipWrapper className={[...classes]}>
      {renderLabel(stateZipCodeBox)}
      {renderZipField(stateZipCodeBox)}
    </StyledDOM.SZipWrapper>
  )
}

export { ZipCodeBox }
export default ZipCodeBox
