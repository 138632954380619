import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from "react-helmet"
import { useHistory, Prompt } from 'react-router-dom'
import { useTheme } from "@material-ui/core/styles"
import { Grid, Typography, FormHelperText, Button, IconButton, useMediaQuery, Card, Tooltip, Collapse, TextField, Card as MuiCard, CardMedia, Box } from "@material-ui/core"
import { Field, Form, Formik, useFormik } from 'formik';
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import { Add } from '@material-ui/icons';
import { CheckCircleIcon, ChevronUp, Trash2, ChevronDown, Save, Slash, Edit, CloseIcon, PlusCircle } from "@iconsGallery"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "@utils"
import * as ServiceAPI from "@services"
import { useDispatch, useSelector } from "react-redux"
import * as constantsAPI from "@constants"
import _, { set } from "lodash"
import * as Adm from "@adm"
import * as Yup from "yup"
import * as Features from "@features"
import { APIUrl } from "@utils"
import useStyles from "./style"
import { ConfirmationDialog } from "@features"
import { productDataApiMethods as productsAPI } from '../../../services/masterData/products';
import productCategoryApiMethods from '../../../services/masterData/products';
import MediaUpload from "./MediaUpload";
import { v4 as uuidv4 } from 'uuid';
import Resizer from "react-image-file-resizer";
import ProductAttribute from '../../../pages/masterData/products/ProductDetailedPage/productAttribute/ProductAttribute'

const initSnackbarProps = { isOpen: false, message: "", type: 'info', autoHideDuration: 2500 }
const validationSchema = Yup.object().shape({
  category_name: Yup.string().trim().required('Enter Category Name')
    .min(3, "Category Name can be of min-3 max-255 characters")
    .max(255, "Category Name can be of min-3 max-255 characters"),
  short_description: Yup.string().trim().required("Enter Description")
    .min(3, "Description can be of min-3 max-255 characters")
    .max(255, "Description can be of min-3 max-255 characters"),
  taxCode: Yup.string()
    .when('taxable',
      {
        is: (value) => value === true,
        then: Yup.string().required('Enter Tax Code')
      }),
})


function CategoryForm(props) {
  const { isWriteAllowed = false } = props
  const formRef = useRef();
  const history = useHistory();
  const [isAttDelete, setIsAttDelete] = useState(false)
  const [initialValues, setInitialValues] = useState({
    categoryId: props?.data?.categoryId || '',
    category_name: props?.data?.category_name || '',
    short_description: props?.data?.short_description || "",
    isActive: props?.data?.isActive || true,
    category_menu: props?.data?.category_menu || false,
    parent_category: props?.data?.parent_category || false,
    taxable: props?.data?.taxable || false,
    taxCode: props?.data?.taxCode || '',
    attributes: props?.data?.attributes || [],
    mapped_parent_categories: props?.formProps?.data?.parent_name || '',

    sort_order: '1',
    locationIds: props?.data.locationIds || [],
    expanded_name: props?.data?.expanded_name,
    item_images: props?.data?.item_images || [],
    parent_name: props?.data?.parent_name || '',
    childNodeAvailable: props?.data?.childNodeAvailable || false
  });
  useEffect(() => {
    setInitialValues({
      categoryId: props?.data?.categoryId,
      category_name: props?.data?.category_name || '',
      short_description: props?.data?.short_description || '',
      isActive: props?.data?.isActive || true,
      category_menu: props?.data?.category_menu || false,
      parent_category: props?.data?.parent_category || false,
      taxable: props?.data?.taxable || false,
      taxCode: props?.data?.taxCode || '',
      attributes: props?.data?.attributes || [],
      mapped_parent_categories: props?.formProps?.data?.parent_name || '',
      sort_order: '1',
      locationIds: props?.data.locationIds || [],
      expanded_name: props?.data?.expanded_name,
      item_images: props?.data?.item_images || [],
      parent_name: props?.data?.parent_name || '',
      childNodeAvailable: props?.data?.childNodeAvailable || false
    })
  }, [props.data])

  const [height, width] = useWindowSize()
  const { t } = useTranslation()
  const classes = useStyles(width)
  const [editMode, setEditMode] = React.useState(false)
  const [data, setData] = useState([])
  const formData = useSelector((state) => state.addLocationReducer)
  const [loading, setLoading] = useState(false)
  const [allowUpdate, setAllowUpdate] = useState(false)
  const [snackBarProps, setSnackBarProps] = useState(initSnackbarProps)
  const [selectedImages, setSelectedImages] = useState([])
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [open, setOpen] = useState(false)
  const [pathName, setPathName] = useState("/")
  const [allowPrompt, setAllowPrompt] = useState(true)
  const [cancel, setCancel] = useState("");
  const [openModal, setopenModal] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery('(max-width: 959px)');
  const isMobile = useMediaQuery('(max-width: 600px)')
  const handleOpen = () => {
    setopenModal(true)
  }

  const handleClose = () => {
    setopenModal(false)
  }

  const slashHandler = () => {
    setEditMode(false);
    props.setFormProps(c => ({ ...c, mode: 'view', data: props?.data || {}, isOpen: c.mode === 'create' ? false : true }))

  }

  const handleSave = (e) => {
    const displayImages = []
    selectedImages?.forEach((selectedImage) => {
      displayImages.push(selectedImage)
    })
    e?.forEach((selectedImage) => {
      displayImages.push(selectedImage)
    })
    setSelectedImages(oldArray => [...oldArray, ...e]);
    formikProps.setFieldValue('item_images', displayImages);
    setOpen(false)
  }

  const callBackLocId = (body) => {
    formikProps.setFieldValue('locationIds', body)
  }

  const handleLocationDelete = () => {
    formikProps.setFieldValue('locationIds', [])

  }
  const handledeleteAttributeItems = async () => {
    formikProps.setFieldValue("attributes", [])

  }

  const handleCustomCloseRedirect = () => {
    setOpenConfirmationDialog(false)
    if (isAttDelete) {
      handledeleteAttributeItems()
      setIsAttDelete(false)
    } else {
      setOpen(false)
      slashHandler()
      backToExistedData()
    }
  }

  const resizeFile = async (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        _.replace(file?.type, "image/", ""),
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
      );
    });

  const adopterFunction = async (data, images) => {
    const formattedImage = images.length > 0 && images?.map((file) => {
      const myRenamedFile = new File([file], uuidv4() + '___' + file.name, { type: file.type, default: file.default });
      return myRenamedFile
    })
    let formData = new FormData();


    if (formattedImage?.length > 0) {
      for (let i = 0; i < formattedImage.length; i++) {
        var file = formattedImage[i];
        var imageCompressed = await resizeFile(file);
        formData.append(`image${i + 1}`, imageCompressed);
      }
    }

    formData.append('payload', JSON.stringify(data))
    return formData
  }

  const handleAddCategory = async (data, actions) => {
    let tempAttr = []
    if (!_.isEmpty(data.attributes) && (data.attributes?.some((x) => x.values === "")
      || data.attributes?.some((x) => x.values === undefined))) {
      setSnackBarProps({ ...initSnackbarProps, isOpen: true, message: "AttributeValue is not allowed to be empty", type: 'error' })
    }
    else {
      !_.isEmpty(data.attributes) && _.map(data.attributes, (x, i) => {
        let attVal = []
        if (x.attribute_field_type === "Boolean") {
          attVal.push(x.values === true ? "true" : "false")
        } else if (x.attribute_field_type === "List" || x.attribute_field_type === "Single Choice") {
          attVal = [x.values._id]
        } else if (x.attribute_field_type === "Date" || x.attribute_field_type === "Text Area" || x.attribute_field_type === "Text Box") {
          attVal = [x.values]
        } else {
          attVal = x.values
        }
        tempAttr.push({
          isDeleted: x.isDeleted,
          attributeId: x.attributeId,
          attributeValue: attVal
        })
      })

      const body = {
        ...data,
        taxCode: formData.taxable ? formData.taxCode : '',
        default_image: `image${data.defaultImageIndex + 1}`,
        expanded_name: data['category_name'],
        item_images: data.item_images || [],
        categoryId: props?.data?.categoryId || "",
        locationIds: data.locationIds?.length > 0 ? data.locationIds?.map(x => _.get(x, 'locationId')) : [],
        mapped_parent_categories: props?.data?.mapped_parent_categories || "",
        attributes: [...tempAttr]
      }

      delete body['parent_name']
      delete body['categoryId']
      delete body['images']
      delete body['defaultImageIndex']
      delete body["childNodeAvailable"]
      const temp = await adopterFunction(body, data.item_images)
      setLoading(true);
      productsAPI.createCategory(temp || {}).then(resp => {
        setSnackBarProps({ ...initSnackbarProps, isOpen: true, message: _.get(resp, 'data.message', 'Success'), type: 'success' })
        props.setFormProps(c => ({ ...c, mode: 'view', data: { ...resp?.data?.data, parent_name: c.data.parent_name } || {} })
        )
        props?.fetchNewFlatTreeData();
        props?.handleViewOpen(resp?.data?.data, data?.parent_name);
        setLoading(false);
      }).catch(err => {
        setSnackBarProps({ ...initSnackbarProps, isOpen: true, message: _.get(err, 'response.data.message', "Product Category already exists"), type: 'error' })

        setLoading(false);
      })
    }
  }

  const handleUpdateCategory = async (formData, actions) => {
    let tempAttr = []
    if (!_.isEmpty(formData.attributes) && (formData.attributes?.some((x) => x.values === "")
      || formData.attributes?.some((x) => x.values === undefined))) {
      setSnackBarProps({ ...initSnackbarProps, isOpen: true, message: "AttributeValue is not allowed to be empty", type: 'error' })
    }

    else {
      !_.isEmpty(formData.attributes) && _.map(formData.attributes, (x, i) => {
        let attVal = ""
        if (x.attribute_field_type === "Boolean") {
          attVal = [x.values === true ? "true" : "false"]
        } else if (x.attribute_field_type === "List" || x.attribute_field_type === "Single Choice") {
          attVal = [x.values._id]
        } else if (x.attribute_field_type === "Date" || x.attribute_field_type === "Text Area" || x.attribute_field_type === "Text Box") {
          attVal = _.isArray(x.values) ? x.values : [x.values]
        }
        else {
          attVal = x.values
        }
        tempAttr.push({
          isDeleted: x.isDeleted,
          attributeId: x.attributeId,
          attributeValue: attVal
        })
      })

      const categoryId = formData.categoryId
      const newCreated = formData.item_images.length > 0 && formData.item_images?.filter((file) => !file._id) || []
      const AlreadyCreated = formData.item_images.length > 0 && formData.item_images?.filter((file) => file._id)
      let defaultImageIndex = null
      newCreated.filter((x, idx) => { if (x.default && x.default === true) defaultImageIndex = idx + 1 })
      const body = {
        ...formData,
        taxCode: formData.taxable ? formData.taxCode : '',
        default_image: `image${defaultImageIndex}`,
        expanded_name: formData['category_name'],
        item_images: AlreadyCreated || [],
        deleted_images: _.get(formData, 'deleted_images', []),
        locationIds: formData.locationIds?.length > 0 ? formData.locationIds?.map(x => _.get(x, 'locationId')) : [],
        mapped_parent_categories: props?.data?.mapped_parent_categories || "",
        attributes: [...tempAttr],
      }
      delete body['parent_name']
      delete body['images']
      delete body['defaultImageIndex']
      delete body["childNodeAvailable"]
      delete body['categoryId']
      const temp = await adopterFunction(body, newCreated)

      setLoading(true);
      productsAPI.updateCategory(categoryId, temp || {}).then(resp => {
        props?.handleViewOpen(resp?.data?.data, formData?.parent_name);
        setSnackBarProps({ ...initSnackbarProps, isOpen: true, message: _.get(resp, 'data.message', 'Success'), type: 'success' })
        props.setFormProps(c => ({ ...c, mode: 'view', data: { ...resp?.data?.data, parent_name: formData?.parent_name } || {} }))
        props?.fetchNewFlatTreeData();
        setLoading(false);
      }).catch(err => {
        setSnackBarProps({ ...initSnackbarProps, isOpen: true, message: _.get(err, 'response.data.message', "Sorry Updation operation failed"), type: 'error' })
        setLoading(false);
      })
    }
  }





  const getErrText = (name) => {
    return formikProps.touched?.[name] && formikProps.errors?.[name]
  }
  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: props.formProps?.mode === 'edit' ? (val) => handleUpdateCategory(val) : (val) => handleAddCategory(val)
  })

  const inputFields = [
    ...(props?.formProps?.mode === 'create' ? [] : [
      {
        label: 'Product Category ID', name: 'categoryId', component: (inputProps) => {
          return (
            <>
              <Typography style={{ color: '#607D8B', fontWeight: 800, padding: inputProps?.disabled ? '' : '0px 10px' }}>{inputProps.value}</Typography>
            </>
          )
        }
      }
    ]),
    {
      label: 'Name', name: 'category_name', component: (inputProps) => {
        return (
          <>
            <TextField disabled={inputProps.disabled} placeholder="Enter Category Name" variant={inputProps.disabled ? "standard" : "outlined"} name={inputProps?.name} autoComplete="off" onChange={inputProps.handleChange} onBlur={inputProps.handleBlur}  {...inputProps} size="small" className={inputProps.disabled ? classes.textInputDisabled : classes.textInput} fullWidth
              onKeyPress={(e) => {
                if (e.which === 32 && e.target.value === "") {
                  e.preventDefault()
                }
              }} />
            <FormHelperText style={{ color: "red" }}>{getErrText(inputProps.name)}</FormHelperText>
          </>
        )
      }
    },
    {
      label: 'Description', name: 'short_description', component: (inputProps) => {
        return (
          <>
            <TextField disabled={inputProps.disabled} rows={4} multiline placeholder="Enter Description" autoComplete="off" name={inputProps?.name} variant={inputProps.disabled ? "standard" : "outlined"} onChange={inputProps.handleChange} onBlur={inputProps.handleBlur}  {...inputProps} size="small" className={inputProps.disabled ? classes.textInputDisabled : classes.textInput} fullWidth
              onKeyPress={(e) => {
                if (e.which === 32 && e.target.value === "") {
                  e.preventDefault()
                }
              }} />
            <FormHelperText style={{ color: "red" }}>{getErrText(inputProps.name)}</FormHelperText>
          </>
        )
      }
    },
    {
      label: 'Parent Node', name: 'mapped_parent_categories', component: (inputProps) => {
        return (
          <>
            <TextField style={{ backgroundColor: props?.formProps?.mode === 'view' ? 'white' : "#E0E0E0" }} name={inputProps?.name} onChange={inputProps.handleChange} onBlur={inputProps.handleBlur} {...inputProps} disabled variant={inputProps.disabled ? "standard" : "outlined"} size="small" className={inputProps.disabled ? classes.textInputDisabled : classes.textInput} fullWidth />
            <FormHelperText style={{ color: "red" }}>{getErrText(inputProps.name)}</FormHelperText>
          </>
        )
      }
    },

    {
      label: 'Category Images', name: 'item_images', component: (inputProps) => <MediaUpload props={inputProps} formUIProps={formikProps} />
    },
    {
      label: 'Category Status', name: 'isActive', isMini: true, component: (inputProps) => {
        return (
          <>
            {
              inputProps.disabled
                ? <Typography style={{ marginLeft: '15px', color: "var(--primaryColor)" }} className={inputProps.value === true ? classes.toggleYes : classes.toggleNo}>{inputProps.value === true ? 'Yes' : 'No'}</Typography>
                : <Adm.ToggleButton isNoLabel name={inputProps?.name} disabled={inputProps.disabled} handleChange={inputProps.handleChange} checked={inputProps.value} onBlur={inputProps.handleBlur} className={classes.toggleBar} />
            }
          </>
        )
      }
    },
    {
      label: 'Category Menu', name: 'category_menu', isMini: true, component: (inputProps) => {
        return (
          <>
            {
              inputProps.disabled
                ? <Typography style={{ marginLeft: '15px' }} className={inputProps.value === true ? classes.toggleYes : classes.toggleNo}>{inputProps.value === true ? 'Yes' : 'No'}</Typography>
                : <Adm.ToggleButton isNoLabel name={inputProps?.name} disabled={inputProps.disabled} handleChange={inputProps.handleChange} onBlur={inputProps.handleBlur} checked={inputProps.value} className={classes.toggleBar} />
            }
          </>
        )
      }
    },
    {
      label: 'Taxable', name: 'taxable', isMini: true, component: (inputProps) => {
        return (
          <>
            {
              inputProps.disabled
                ? <Typography style={{ marginLeft: '15px' }} className={inputProps.value === true ? classes.toggleYes : classes.toggleNo}>{inputProps.value === true ? 'Yes' : 'No'}</Typography>
                : < Adm.ToggleButton isNoLabel name={inputProps?.name} disabled={inputProps.disabled} handleChange={inputProps.handleChange} onBlur={inputProps.handleBlur} checked={inputProps.value} className={classes.toggleBar} />
            }
          </>
        )
      }
    },
    {
      label: formikProps?.values?.taxable ? 'Tax code' : ' ', name: 'taxCode', isMini: true, component: (inputProps) => {
        return (
          <div>
            {
              formikProps?.values?.taxable && !inputProps.disabled ?
                <div>
                  <TextField name={inputProps?.taxCode} placeholder="Enter Tax Code" onChange={inputProps.handleChange} onBlur={inputProps.handleBlur} {...inputProps} variant={inputProps.disabled ? "standard" : "outlined"} size="small" className={inputProps.disabled ? classes.textInputDisabled : classes.textInput} fullWidth
                  />
                  <FormHelperText style={{ color: "red" }}>{getErrText(inputProps.name)}</FormHelperText>
                </div> :
                <>
                  <Typography style={{ marginLeft: '15px' }}>
                    {formikProps?.values?.taxable ? inputProps.value : ''}
                  </Typography>
                </>
            }
          </div>
        )
      }
    },
    {
      label: 'Location', name: 'locationIds', isColumn: true, component: (inputProps) => {
        return (
          <>
            <Grid item xs={12} container className={classes.location} style={{ margin: '0px -10px', }}>
              <Features.AddLocationAndLocationGroupUI edit={inputProps.disabled} isAddLocation={true} isAddLocationGroup={false}
                locationIds={formikProps.values.locationIds} callBackLocId={callBackLocId} isWriteAllowed={isWriteAllowed} showHeader={false}
                handleLocationDelete={handleLocationDelete} categoryFormdeleteFunc={true}
              />

            </Grid>
          </>
        )
      }
    },
    {
      label: !formikProps?.values?.childNodeAvailable ? 'Map Attribute' : '', name: 'attributes', isColumn: true, component: (inputProps) => {
        return (
          <>
            {
              !formikProps?.values?.childNodeAvailable ? <>
                <Grid item xs={12} container className={classes.location} style={{ display: "flex", justifyContent: "space-between" }}
                >  {!inputProps.disabled ?
                  <>
                    <Button variant="contained" className={classes.AttributeButton}
                      style={{ margin: '10px 0px 10px 0px', backgroundColor: "var(--primaryColor)" }}
                      color="primary"
                      startIcon={<PlusCircle style={{ color: "#fff", width: "16px" }} />}
                      onClick={handleOpen}>
                      {window?.globalTranslation("ATTRIBUTE LIST")}
                    </Button>

                    {inputProps.value.length > 0 ?
                      <Tooltip title="Archive" placement="top">
                        <IconButton style={{ margin: "15px", border: "1px solid grey", width: "30px", height: "30px", padding: "5px", }} onClick={() => {
                          setOpenConfirmationDialog(true); setIsAttDelete(true)
                        }}>
                          < Trash2 />
                        </IconButton>
                      </Tooltip>
                      : null}
                  </>
                  : null}


                  <ProductAttribute isAccordian={false}
                    isWriteAllowed={isWriteAllowed}
                    openAttrModal={openModal} handleClose={handleClose}
                    formikSetFieldValue={formikProps.setFieldValue}
                    attributes={inputProps.value}
                    edit={inputProps.disabled}
                    setEdit={setEditMode}
                  />
                </Grid>
              </> : <>
              </>
            }


          </>
        )
      }
    },
  ]



  const backToExistedData = () => {
    formikProps.setFieldValue('category_name', initialValues.category_name)
    formikProps.setFieldValue('short_description', initialValues.short_description)
    formikProps.setFieldValue('item_images', initialValues.item_images)
    formikProps.setFieldValue('locationIds', initialValues.locationIds)
    formikProps.setFieldValue('taxable', initialValues.taxable)
    formikProps.setFieldValue('taxCode', initialValues.taxCode)
    formikProps.setFieldValue('parent_category', initialValues.parent_category)
    formikProps.setFieldValue('category_menu', initialValues.category_menu)
    formikProps.setFieldValue('isActive', initialValues.isActive)
    formikProps.setFieldValue('attributes', initialValues.attributes)
  }

  return (
    <Grid container item lg={12} >
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        setCancel={setCancel}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
      />

      <Adm.BackdropOverlay open={loading} />
      <Grid container>
        <Grid container direction="row" item xs={12} lg={12} md={12} xl={12} sm={12} >
          <Grid container item xs={12} md={12} lg={12} xl={12} sm={12} alignContent="center" alignItems="center" style={{ paddingLeft: isMobile ? "" : "16px", height: isMobile ? "" : "60px", justifyContent: "space-between" }} >
            <Typography variant="h4" align="center"> {window?.globalTranslation("Product Category Details")} </Typography>
            <Grid item style={{ display: "flex", alignItems: "center", }} >
              {
                props?.formProps?.mode === 'view'
                  ? <>
                    <Tooltip title="Edit" placement="top">
                      <IconButton onClick={() => props?.setFormProps(c => ({ ...c, mode: 'edit' }))}
                        disabled={isWriteAllowed === true ? false : true}>
                        <Edit size={20} style={{ color: "rgba(158, 158, 158, 1)" }} />
                      </IconButton>
                    </Tooltip>
                    <IconButton onClick={props?.onClose}> <CloseIcon size={20} style={{ color: "rgba(158, 158, 158, 1)" }} /> </IconButton>
                  </>
                  : <>
                    { }
                    <Tooltip title="Cancel" placement="top">
                      <IconButton onClick={() => setOpenConfirmationDialog(true)}>
                        <Slash size={20} style={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Save" placement="top">
                      <IconButton onClick={() => formikProps.handleSubmit()} style={{ marginRight: "18px" }}>
                        <CheckCircleIcon style={{ color: allowUpdate ? "#4CAF50" : "#4CAF50", fontsize: "medium" }} />
                      </IconButton>
                    </Tooltip>
                  </>
              }
              { }

            </Grid>
          </Grid>
          { }
          <Grid container item xs={12} style={{ padding: `8px 20px 8px 20px`, }}>
            {
              inputFields.map(o => {
                if (o?.isMini) {
                  return <Grid key={o?.label} container item lg={6}><InputField key={o?.label} name={o?.name} disabled={props?.formProps?.mode === 'view'} value={formikProps?.values?.[o?.name]} handleChange={formikProps?.handleChange} handleBlur={formikProps?.handleBlur} classes={classes} label={o?.label} inputComponent={o?.component} /></Grid>
                }
                return <InputField key={o?.label} name={o?.name} disabled={props?.formProps?.mode === 'view'} value={formikProps?.values?.[o?.name]} handleChange={formikProps?.handleChange} handleBlur={formikProps?.handleBlur} classes={classes} label={o?.label} inputComponent={o?.component} isColumn={o?.isColumn} />
              })
            }
            { }
          </Grid>
        </Grid>
      </Grid>
      {snackBarProps?.isOpen && <Adm.SnackBar open={snackBarProps?.isOpen} message={snackBarProps?.message || "Uh! May be wait"} type={snackBarProps?.type || "info"} icon={snackBarProps?.icon || undefined} handleClose={() => setSnackBarProps(initSnackbarProps)} />}
      <Grid item xs={12} style={{ padding: `8px 20px 8px 20px` }}>
        {
          props?.formProps?.mode === 'view' ?
            <Features.LastUpdateMessage
              date={_.get(props.formProps.data, "updatedAt", "")}
              createdDate={_.get(props.formProps.data, "createdAt", "")}
            />
            : null
        }
      </Grid>
    </Grid>
  )
}

const InputField = ({ classes = {}, label = 'Label', isColumn = "", name = '', handleChange, handleBlur, value, inputComponent = () => null, disabled = false }) => {
  return (
    <>
      <Grid container style={{
        borderBottom: label === "" ? "" : '1px solid #EEEEEE',
        padding: '15px 0px'
      }}>
        <Grid item xs={isColumn ? 12 : 5} container>
          <Typography variant="h5" className={classes.inputFieldText}>{label}</Typography>
        </Grid>
        <Grid container item xs={isColumn ? 12 : 7}>
          {inputComponent({ handleChange, handleBlur, name, disabled, value })
          }
        </Grid>
      </Grid>
      <Divider className={classes.Divider} />
    </>

  )
}

export { CategoryForm }
export default CategoryForm