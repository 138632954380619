import React, { useState, useEffect, createContext } from "react"
import SortableTree, {
  getTreeFromFlatData,
  getNodeAtPath,
  toggleExpandedForAll,
} from "react-sortable-tree"
import { Checkbox, CircularProgress } from "@material-ui/core"
import _ from "lodash"
import { useStyles } from "./style"
import NodeRendererDefault from "./nodeContentRenderer"
import PlaceholderRendererDefault from "./placeholderRenderer"
import {
  Button,
  Icon,
  IconButton,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  Toolbar,
  AppBar,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core"
import {
  AddCircleOutline,
  DeleteOutline,
  ChevronLeft,
  ChevronRight,
  AddCircle,
  Edit,
} from "@material-ui/icons"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/core/styles"
import * as Adm from "@adm"
import { ConfirmationDialog } from "@features"
import {
  SearchOutlined,
  VisibilityIcon,
  CloseIcon,
} from "../../IconsGallery/IconsGallery"
import BlockIcon from "@material-ui/icons/Block"
import HistoryIcon from "@material-ui/icons/History"
import Slide from "@material-ui/core/Slide"
import AuditLogPopup from "../../pages/logviewer/auditLogs/AuditLogPopup"
export const TreeContext = createContext()

const getNodeKey = ({ treeIndex, node }) => treeIndex
const getParentNodeId = (n) =>
  _.isString(_.get(n, "category_level.[0]"))
    ? _.get(n, "category_level.[0]")
    : null

const defaultHandleAddNodeParamMaker = (nodeProps) => [
  nodeProps?.node?.category_id,
  ...(nodeProps?.node?.mapped_parent_categories || []),
]

const defaultHandleDeleteNodeParamMaker = (nodeProps) =>
  _.get(nodeProps, "node.category_id", "")
const paramsForHandleAddNode = (
  nodeProps,
  handleAddNodeParamMaker = defaultHandleAddNodeParamMaker
) => {
  if (_.isFunction(handleAddNodeParamMaker)) {
    return handleAddNodeParamMaker(nodeProps)
  }
  return defaultHandleAddNodeParamMaker(nodeProps)
}

const paramsForHandleDeleteNode = (
  nodeProps,
  paramMaker = defaultHandleDeleteNodeParamMaker
) => {
  if (_.isFunction(paramMaker)) {
    return paramMaker(nodeProps)
  }
  return defaultHandleAddNodeParamMaker(nodeProps)
}

const TreeView = (props) => {
  const { isWriteAllowed } = props
  const [genNode, setGenNode] = useState("")

  const {
    isViewMode,
    noIconMode = false,
    isMappingMode = false,
    classes,
    treeData,
    nodeProps,
    onChangeInTreeData,
    selectedNodeId,
    ProductHierarchyDetail,
    isComInProductMapping,
    canDragPropInProductMapping,
    showAddRootNodeButton,
    titlePath = "category_name",
    onEditClick,
    handlerParamsMaker = defaultHandleAddNodeParamMaker,
    handleNodeDelete = () => { },
    lookupType,
    style = {},
  } = props

  const initialValue = {
    query: "",
    index: 0,
    count: 0,
  }
  const [searchProps, setSearchProps] = useState(initialValue)
  const [flagOne, setFlagOne] = useState(true)
  const [flagTwo, setFlagTwo] = useState(true)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isdelete, setIsdelete] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const [isOpen, setOpen] = useState(false)
  const [scroll, setScroll] = React.useState("paper")
  const [nodeId, setNodeId] = useState()

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [isOpen])

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />
  })

  const handlePopClose = () => {
    setOpen(false)
    document.body.style.overflow = "scroll"
    document.body.style.paddingRight = "0px"
  }

  const moveSearchFocusToNextMatch = () =>
    setSearchProps((c) => ({
      ...searchProps,
      index: c?.index !== null ? (c.index + 1) % c.count : 0,
    }))
  const moveSearchFocusToPrevMatch = () =>
    setSearchProps((c) => ({
      ...searchProps,
      index:
        c?.index !== null ? (c.count + c.index - 1) % c.count : c.count - 1,
    }))
  const clearUpdateValues = (event) => {
    setSearchProps(initialValue)
  }
  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    _.get(node, titlePath, "")
      .toLowerCase()
      .indexOf(searchQuery.toLowerCase()) > -1

  const getDataFromPath = (path) => {
    const newPath = [...path]
    newPath.splice(-1, 1)

    return getNodeAtPath({ treeData, path: newPath, getNodeKey })
  }

  const openAllNodes = () => {
    onChangeInTreeData(toggleExpandedForAll({ treeData, expanded: true }))
    setFlagOne(false)
    setFlagTwo(true)
  }
  const closeAllNodes = () => {
    onChangeInTreeData(toggleExpandedForAll({ treeData, expanded: false }))
    setFlagOne(true)
    setFlagTwo(false)
  }

  const handleCustomCloseRedirect = () => {
    setOpenConfirmationDialog(false)
    props?.handleNodeDelete(
      paramsForHandleDeleteNode(genNode, handlerParamsMaker)
    )
  }
  return (
    <>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
        deleteContent={`Selected ${lookupType} Hierarchy would be archived, do you really want to continue?`}
      />
      <Grid>
        <Grid
          style={{
            paddingRight: "45px",
            display: "flex",
            flexDirection: isMobile ? "row" : "",
            paddingTop: isMobile ? "15px" : "10px",
            justifyContent: "flex-end",
          }}
        >
          {showAddRootNodeButton && showAddRootNodeButton && (
            <IconButton
              onClick={props?.onAddClick}
              size="small"
              disabled={isWriteAllowed === true ? false : true}
            >
              {loading ? (
                <CircularProgress size={100} />
              ) : (
                <AddCircle
                  fontSize="small"
                  style={{ color: "var(--primaryColor)" }}
                />
              )}
            </IconButton>
          )}
          <Button
            disableElevation
            size="small"
            variant="outlined"
            style={{
              backgroundColor: flagOne ? "#fff" : "var(--primaryColor)",
              color: flagOne ? "var(--primaryColor)" : "#FFF",
              fontSize: "10px",
              height: "38px",
              padding: "0 10px",
              minWidth: "initial",
              margin: "0 10px 0 15px",
              borderColor: "var(--primaryColor)",
            }}
            color="primary"
            onClick={openAllNodes}
            disabled={!flagOne}
          >
            ExpandAll
          </Button>
          <Button
            disableElevation
            size="medium"
            variant="outlined"
            style={{
              backgroundColor: flagTwo ? "#fff" : "var(--primaryColor)",
              color: flagTwo ? "var(--primaryColor)" : "#FFF",
              fontSize: "10px",
              minWidth: "initial",
              height: "38px",
              padding: "0 10px",
              marginRight: "10px",
              borderColor: "var(--primaryColor)",
            }}
            color="primary"
            onClick={closeAllNodes}
            disabled={!flagTwo}
          >
            CollapseAll
          </Button>
          <TextField
            size="small"
            fullWidth
            className={classes.SearchFeildBox}
            style={{ display: "flex", flexDirection: isMobile ? "column" : "" }}
            value={searchProps.query}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <SearchOutlined style={{ color: "#9E9E9E", marginRight: "" }} />
              ),
            }}
            variant="outlined"
            onChange={(e) =>
              setSearchProps({ query: e.target.value, index: 0, count: 0 })
            }
          />

          <IconButton
            onClick={moveSearchFocusToPrevMatch}
            size="small"
            style={{
              padding: "2px",
              display: "flex",
              flexDirection: isMobile ? "row" : "",
            }}
            className={classes.ChevronLeft}
          >
            <ChevronLeft />
          </IconButton>
          <Typography
            className={classes.ChevronLeft}
            style={{
              fontWeight: 400,
              fontSize: "14px",
              marginTop: "8px",
              color: "#9E9E9E",
            }}
          >
            {searchProps.count}
          </Typography>

          <IconButton
            onClick={moveSearchFocusToNextMatch}
            size="small"
            style={{ padding: "2px" }}
            className={classes.ChevronLeft}
          >
            <ChevronRight />
          </IconButton>
          <Tooltip title="Cancel">
            <BlockIcon
              style={{
                height: "25px",
                width: "25px",
                marginTop: "7px",
                color: "#f00",
              }}
              onClick={clearUpdateValues}
            />
          </Tooltip>
        </Grid>
        <SortableTree
          treeData={treeData}
          onChange={(t) => onChangeInTreeData(t)}
          className={classes.global}
          innerStyle={{ padding: 8 }}
          scaffoldBlockPxWidth={isViewMode && !isMappingMode ? 36 : 44}
          nodeContentRenderer={NodeRendererDefault}
          placeholderRenderer={PlaceholderRendererDefault}
          rowHeight={isViewMode && !isMappingMode ? 32 : 44}
          canDrag={!isViewMode && showAddRootNodeButton}
          searchQuery={searchProps?.query}
          searchMethod={customSearchMethod}
          searchFocusOffset={searchProps?.index + 0}
          searchFinishCallback={(matches) => {
            setSearchProps({
              ...searchProps,
              count: matches.length,
              index:
                matches.length > 0 ? searchProps?.index % matches.length : 0,
            })
          }}
          maxDepth={4}
          canDrop={(e, f) => {
            return false
          }}
          onMoveNode={(e) => {
            props?.handleNodeMove(getDataFromPath(e.path)?.node, e?.node)
          }}
          generateNodeProps={(nodeProps) => {
            return {
              title: _.get(nodeProps, `node.${titlePath}`, "Title"),
              isCheckable: isViewMode && !isMappingMode,
              style: {
                color:
                  nodeProps &&
                  selectedNodeId &&
                  nodeProps.node._id === selectedNodeId &&
                  "blue",
              },
              buttons:
                isViewMode ||
                  isMappingMode ||
                  noIconMode ||
                  isComInProductMapping
                  ? []
                  : [
                    _.isFunction(onEditClick) ? (
                      <Tooltip
                        title={lookupType === "Location" ? "Edit" : "View"}
                      >
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                            onEditClick(nodeProps)
                          }}
                        >
                          <IconButton
                            size="small"
                            style={{
                              color: "#9e9e9e",
                              padding: "8px",
                              borderRadius: "0",
                            }}
                            disabled={
                              lookupType === "Location" &&
                              (isWriteAllowed === true ? false : true)
                            }
                          >
                            {lookupType === "Location" ? (
                              <Edit fontSize="small" />
                            ) : (
                              <VisibilityIcon
                                fontSize="small"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  onEditClick(nodeProps)
                                }}
                              />
                            )}
                          </IconButton>
                        </div>
                      </Tooltip>
                    ) : null,
                    nodeProps?.path?.length >= 255 ? null : (
                      <Tooltip title="Add">
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                            props?.handleAddNode(
                              paramsForHandleAddNode(
                                nodeProps,
                                handlerParamsMaker
                              )
                            )
                          }}
                        >
                          <IconButton
                            size="small"
                            style={{
                              color: "#9e9e9e",
                              padding: "8px",
                              borderRadius: "0",
                            }}
                            disabled={isWriteAllowed === true ? false : true}
                          >
                            {loading ? (
                              <CircularProgress
                                size={30}
                                style={{ margin: "80px" }}
                              />
                            ) : (
                              <AddCircleOutline fontSize="small" />
                            )}
                          </IconButton>
                        </div>
                      </Tooltip>
                    ),
                    <Tooltip title="Delete">
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                          setGenNode(nodeProps)
                          setOpenConfirmationDialog(true)
                        }}
                      >
                        <IconButton
                          disabled={isWriteAllowed === true ? false : true}
                          size="small"
                          style={{
                            color: "#9e9e9e",
                            padding: "8px",
                            borderRadius: "0",
                          }}
                        >
                          <DeleteOutline fontSize="small" />
                        </IconButton>
                      </div>
                    </Tooltip>,
                    <Tooltip title={"History"} placement="top">
                      <div
                        onClick={() => {
                          setNodeId(nodeProps?.node?._id)
                          setOpen(true)
                        }}
                      >
                        <IconButton
                          style={{
                            color: "#9e9e9e",
                            padding: "8px",
                            borderRadius: "0",
                          }}
                          disabled={isWriteAllowed === true ? false : true}
                        >
                          <HistoryIcon />
                        </IconButton>
                      </div>
                    </Tooltip>,
                  ],
            }
          }}
          style={{
            height: ProductHierarchyDetail ? "300px" : "500px",
            boxSizing: "border-box",
            ...style,
          }}
        />
      </Grid>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handlePopClose}
        TransitionComponent={Transition}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{
          maxWidth: "1000px",
          width: "60%",
          left: "auto",
          right: "0",
          display: isOpen === true ? "block" : "none",
        }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ padding: "46px 0 0 0", width: "100%" }}
        >
          <AppBar
            className={classes.appBar}
            style={{ padding: "0", position: "absolute" }}
          >
            <Toolbar style={{ minHeight: "40px" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlePopClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                History List
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"} style={{ padding: "0" }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <AuditLogPopup isWriteAllowed={isWriteAllowed} recordId={nodeId} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

const DraggableTreeView = (props) => {
  const {
    isWriteAllowed,
    ProductHierarchyDetail = false,
    setLoading = () => { },
    loading,
  } = props
  const classes = useStyles({
    isViewMode: (props?.isViewMode && !props?.isMappingMode) || false,
  })
  return (
    <>
      <TreeContext.Provider
        value={{
          classes: classes,
          style: props?.style || {},
          isViewMode: props?.isViewMode || false,
          noIconMode: props?.noIconMode || false,
          isMappingMode: props?.isMappingMode || false,
          canSelectOnlyLastLevel: props?.canSelectOnlyLastLevel || false,
          handleCheckboxClick: props?.handleCheckboxClick,
          onAddClick: props?.onAddClick,
          handleClickNode: props?.handleClickNode,
          handleAddNode: props?.handleAddNode,
          handleNodeMove: props?.handleNodeMove,
          handleNodeDelete: props?.handleNodeDelete,
          noNodeText: props?.noNodeText,
          addNodeText: props?.addNodeText,
        }}
      >
        <TreeView
          {...props}
          showIcon={props?.showIcon}
          isViewMode={props?.isViewMode || false}
          classes={classes}
          isWriteAllowed={isWriteAllowed}
          ProductHierarchyDetail={ProductHierarchyDetail}
        />
      </TreeContext.Provider>
    </>
  )
}

export default DraggableTreeView

const dummyTreeData = [
  {
    title: "Non Veg",
    expanded: false,
    children: [
      { title: "Chicken", subtitle: "Its chicken you dumb" },
      { title: "Fish", subtitle: "Something Fishy!" },
    ],
  },
  {
    title: "Veg",
    expanded: false,
    children: [
      { title: "Carrot", subtitle: "Conical Cylindrical bright looking thing" },
    ],
  },
  {
    title: "Green",
    expanded: false,
    children: [
      {
        title: "Coriander",
        subtitle: "Major thing almost all of Indian recipes",
      },
      { title: "Curry Leaves", subtitle: "No NV recipe without it" },
    ],
  },
  {
    title: "Milk",
    expanded: false,
    children: [{ title: "Milk", subtitle: "Ha-ha! Basic but Essential!" }],
    isNoMoreChildren: true,
  },
]

const testData = [
  {
    category_level: [
      {
        category_level_1: {
          category_level_2: "something",
        },
      },
    ],
    product_list_in_category: [],
    item_images: [
      "http://dbdlm9dwuj161.cloudfront.net/category-images/categories-1/orange.jpg",
    ],
    _id: "5ed97e28bf5e5d24c86dbfd3",
    category_name: "Produce",
    category_id: "1",
    short_description: "Fruits and Vegetables",
    __v: 0,
    updatedAt: "2021-05-20T01:05:21.189Z",
    created_by: "person",
    last_updated_by: "person",
    parent_category: false,
    sort_order: "6",
  },
  {
    category_level: [
      {
        category_level_1: {
          category_level_2: "something",
        },
      },
    ],
    product_list_in_category: [],
    item_images: [
      "http://dbdlm9dwuj161.cloudfront.net/category-images/store-5/fresh_food.jpg",
    ],
    _id: "5eda793ebf5e5d24c86dbfd4",
    category_name: "Food",
    category_id: "2",
    short_description: "Fresh and Frozen",
    __v: 0,
    created_by: "person",
    last_updated_by: "person",
    parent_category: false,
    sort_order: "1",
    updatedAt: "2021-05-20T01:05:20.912Z",
  },
  {
    category_level: [
      {
        category_level_1: {
          category_level_2: "something",
        },
      },
    ],
    product_list_in_category: [],
    item_images: [
      "http://dbdlm9dwuj161.cloudfront.net/category-images/store-5/sodas.jpg",
    ],
    _id: "5eda7d452a9154468d5ccf0d",
    category_name: "Drinks",
    category_id: "3",
    short_description: "Water, Coffee, Sodas",
    __v: 0,
    created_by: "person",
    last_updated_by: "person",
    parent_category: false,
    sort_order: "5",
    updatedAt: "2021-05-20T01:05:21.134Z",
  },
  {
    category_level: [
      {
        category_level_1: {
          category_level_2: "something",
        },
      },
    ],
    product_list_in_category: [],
    item_images: [
      "http://dbdlm9dwuj161.cloudfront.net/category-images/store-5/beering.jpg",
    ],
    _id: "5eda7e4c2a9154468d5ccf0e",
    category_name: "Beer",
    category_id: "4",
    short_description: "Light Beer, Ales, IPAs",
    __v: 0,
    created_by: "person",
    last_updated_by: "person",
    parent_category: false,
    sort_order: "4",
    updatedAt: "2021-05-20T01:05:21.080Z",
  },
  {
    category_level: [
      {
        category_level_1: {
          category_level_2: "something",
        },
      },
    ],
    product_list_in_category: [],
    item_images: [
      "http://dbdlm9dwuj161.cloudfront.net/category-images/store-5/wines.jpg",
    ],
    _id: "5eda7ed12a9154468d5ccf0f",
    category_name: "Wine",
    category_id: "5",
    short_description: "Red and White Wine",
    __v: 0,
    created_by: "person",
    last_updated_by: "person",
    parent_category: false,
    sort_order: "3",
    updatedAt: "2021-05-20T01:05:21.028Z",
  },
  {
    category_level: [
      {
        category_level_1: {
          category_level_2: "something",
        },
      },
    ],
    product_list_in_category: [],
    item_images: [
      "http://dbdlm9dwuj161.cloudfront.net/category-images/store-5/candy.jpg",
    ],
    _id: "5f0e3a02bbe81c22d5406aa1",
    category_name: "Candy",
    category_id: "6",
    short_description: "Candy and Chocolate",
    __v: 0,
    created_by: "person",
    last_updated_by: "person",
    parent_category: false,
    sort_order: "7",
    updatedAt: "2021-05-20T01:05:21.242Z",
  },
  {
    category_level: [
      {
        category_level_1: {
          category_level_2: "something",
        },
      },
    ],
    product_list_in_category: [],
    item_images: [
      "http://dbdlm9dwuj161.cloudfront.net/category-images/store-5/snacking.jpg",
      "http://dbdlm9dwuj161.cloudfront.net/category-images/store-5/breathtaking-online-shopping-statistics-you-never-knew-625x300.png",
    ],
    _id: "5f0e3a2cbbe81c22d5406aa2",
    category_name: "Snacks",
    category_id: "7",
    short_description: "Cheetoes, Pringles, Oreos",
    __v: 0,
    created_by: "person",
    last_updated_by: "person",
    parent_category: false,
    sort_order: "2",
    updatedAt: "2021-05-20T01:05:20.976Z",
  },
  {
    category_level: [{}],
    product_list_in_category: [],
    item_images: [],
    _id: "60b79bdf9ecd3b2e437e5da2",
    category_name: "string",
    category_id: "string",
    short_description: "string",
    created_by: "string",
    last_updated_by: "string",
    parent_category: true,
    sort_order: "string",
    createdAt: "2021-06-02T14:55:27.923Z",
    updatedAt: "2021-06-02T14:55:27.923Z",
    __v: 0,
  },
  {
    category_level: [{}],
    product_list_in_category: [],
    item_images: [],
    _id: "610d9430b25c611518d1b088",
    category_name: "Books",
    category_id: "9",
    short_description: "books categories",
    category_menu: true,
    category_status: true,
    created_by: "string",
    last_updated_by: "Jon",
    parent_category: true,
    sort_order: "2",
    createdAt: "2021-08-06T19:57:36.309Z",
    updatedAt: "2021-08-06T19:57:36.309Z",
    __v: 0,
  },
  {
    category_level: [
      {
        category_level_1: "first",
        category_level_2: "second",
      },
    ],
    product_list_in_category: [],
    item_images: [],
    _id: "610d9456b25c611518d1b089",
    category_name: "comic books",
    category_id: "10",
    short_description: "books categories",
    category_menu: true,
    category_status: true,
    created_by: "string",
    last_updated_by: "Jon",
    parent_category: false,
    sort_order: "2",
    createdAt: "2021-08-06T19:58:14.438Z",
    updatedAt: "2021-08-06T20:19:50.322Z",
    __v: 0,
  },
  {
    category_level: [
      {
        category_level_1: "first",
        category_level_2: "second",
      },
    ],
    product_list_in_category: [],
    item_images: [],
    _id: "610d946db25c611518d1b08a",
    category_name: "fiction books",
    category_id: "11",
    short_description: "books categories",
    category_menu: true,
    category_status: true,
    created_by: "string",
    last_updated_by: "Jon",
    parent_category: false,
    sort_order: "2",
    createdAt: "2021-08-06T19:58:37.718Z",
    updatedAt: "2021-08-06T20:20:05.918Z",
    __v: 0,
  },
  {
    category_level: [
      {
        category_level_1: "first",
        category_level_2: "second",
      },
    ],
    product_list_in_category: [],
    item_images: [],
    _id: "610d9492b25c611518d1b08b",
    category_name: "thriller books",
    category_id: "12",
    short_description: "books categories",
    category_menu: true,
    category_status: true,
    created_by: "string",
    last_updated_by: "Jon",
    parent_category: false,
    sort_order: "2",
    createdAt: "2021-08-06T19:59:14.779Z",
    updatedAt: "2021-08-06T20:20:22.541Z",
    __v: 0,
  },
  {
    category_level: [
      {
        category_level_1: "first",
        category_level_2: "second",
      },
    ],
    product_list_in_category: [],
    item_images: [],
    _id: "610d949eb25c611518d1b08c",
    category_name: "romantic books",
    category_id: "13",
    short_description: "books categories",
    category_menu: true,
    category_status: true,
    created_by: "string",
    last_updated_by: "Jon",
    parent_category: false,
    sort_order: "2",
    createdAt: "2021-08-06T19:59:26.025Z",
    updatedAt: "2021-08-06T20:20:33.355Z",
    __v: 0,
  },
  {
    category_level: [{}],
    product_list_in_category: [],
    item_images: [],
    _id: "610d9aa38d49871f7653c83e",
    category_name: "fruit",
    category_id: "14",
    short_description: "fruits categories",
    category_menu: true,
    category_status: true,
    created_by: "string",
    last_updated_by: "Jon",
    parent_category: true,
    sort_order: "2",
    createdAt: "2021-08-06T20:25:07.459Z",
    updatedAt: "2021-08-06T20:25:07.459Z",
    __v: 0,
  },
  {
    category_level: [
      {
        category_level_1: "first",
        category_level_2: "second",
      },
    ],
    product_list_in_category: [],
    item_images: [],
    _id: "610d9ae18d49871f7653c83f",
    category_name: "Citrus",
    category_id: "15",
    short_description: "fruits categories",
    category_menu: true,
    category_status: true,
    created_by: "string",
    last_updated_by: "Ron",
    parent_category: false,
    sort_order: "2",
    createdAt: "2021-08-06T20:26:09.814Z",
    updatedAt: "2021-08-06T20:26:09.814Z",
    __v: 0,
  },
  {
    category_level: [
      {
        category_level_1: "first",
        category_level_2: "second",
      },
    ],
    product_list_in_category: [],
    item_images: [],
    _id: "610d9af38d49871f7653c840",
    category_name: "Stone fruits",
    category_id: "16",
    short_description: "fruits categories",
    category_menu: true,
    category_status: true,
    created_by: "string",
    last_updated_by: "Ron",
    parent_category: false,
    sort_order: "2",
    createdAt: "2021-08-06T20:26:27.320Z",
    updatedAt: "2021-08-06T20:26:27.320Z",
    __v: 0,
  },
  {
    category_level: [
      {
        category_level_1: "first",
        category_level_2: "second",
      },
    ],
    product_list_in_category: [],
    item_images: [],
    _id: "610d9b0d8d49871f7653c841",
    category_name: "Berries",
    category_id: "17",
    short_description: "fruits categories",
    category_menu: true,
    category_status: true,
    created_by: "Ron",
    last_updated_by: "Ron",
    parent_category: false,
    sort_order: "2",
    createdAt: "2021-08-06T20:26:53.441Z",
    updatedAt: "2021-08-06T20:26:53.441Z",
    __v: 0,
  },
  {
    category_level: [
      {
        category_level_1: "first",
        category_level_2: "second",
      },
    ],
    product_list_in_category: [],
    item_images: [],
    _id: "610d9b1c8d49871f7653c842",
    category_name: "Melons",
    category_id: "18",
    short_description: "fruits categories",
    category_menu: true,
    category_status: true,
    created_by: "Ron",
    last_updated_by: "Ron",
    parent_category: false,
    sort_order: "2",
    createdAt: "2021-08-06T20:27:08.122Z",
    updatedAt: "2021-08-06T20:27:08.122Z",
    __v: 0,
  },
  {
    category_level: [null],
    product_list_in_category: [],
    item_images: [],
    _id: "61113541c5ee0d38a9fb1974",
    category_id: "PC-1",
    category_name: "Test Category One",
    short_description: "This is test entry by J6 ",
    category_status: true,
    category_menu: true,
    parent_category: true,
    created_by: "J6",
    last_updated_by: "J6",
    sort_order: "1",
    createdAt: "2021-08-09T14:01:37.585Z",
    updatedAt: "2021-08-09T14:01:37.585Z",
    __v: 0,
  },
]
