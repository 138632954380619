import React, { useRef, useState } from "react"
import { Link, Prompt } from "react-router-dom"
import Keycloak from "keycloak-js"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { loginFlagDashBoard, profileUpdateData } from "@reduxHelper"
import { ConfirmationDialog } from "@features"
import {
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
  Typography,
  Divider,
  Grid,
  Tooltip,
} from "@material-ui/core"
import { Settings, HelpCircle, LogOut } from "@iconsGallery"
import * as Customized from "../../styles/components/UserDropdown/styles"
import { useTranslation } from "react-i18next"
import UserService from "../../services/UserService"
import {
  EmailOutlined,
  AccountCircle,
  SupervisedUserCircle,
  PowerSettingsNewOutlined,
} from "@material-ui/icons"
import SettingsIcon from "@material-ui/icons/Settings"
import _ from "lodash"
import { blue } from "@material-ui/core/colors"
import { Bold } from "react-feather"
import * as Adm from "@adm"
import { makeStyles } from "@material-ui/core"
import { ProfileUpdate } from "../../routes/RouterConfig"
import userApi from "../../services/user/api"
import { useEffect } from "react"
export const useStyles = makeStyles((theme) => ({
  listItemTextWrap: {
    "& .MuiListItemText-primary": {
      fontSize: "14px",
      fontWeight: "400",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  profileSettingTextWrap: {
    "& .MuiListItemText-primary": {
      fontSize: "14px",
      fontWeight: "400",
      margin: "10px",
    },
  },
  profileBtn: {
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  listItemWrap: {
    "& .MuiListItemText-primary": {
      fontSize: "16px",
      fontWeight: "500",
    },
    "& .MuiListItemText-secondary": {
      fontSize: "12px",
      color: "#000000",
      fontWeight: 100,
      letterSpacing: "0.2px",
    },
  },
}))

function PopupItem({
  title,
  description,
  image = "",
  icon,
  userData,
  divider,
  path,
  handleClick = () => { },
  classes = () => { },
}) {
  return (
    <Customized.ListItem
      divider={divider}
      component={Link}
      to={Link}
      style={{ height: "60px", padding: "0px" }}
    >
      {icon ? (
        <Customized.Avatar>{image}</Customized.Avatar>
      ) : (
        <ListItemAvatar style={{ padding: "10px 0px" }}>
          {image === "" ? (
            <>
              <Avatar
                src={userData?.profileImage}
                style={{
                  backgroundColor: "#F3F7FC",
                  color: "#1E88E5",
                  height: "45px",
                  width: "45px",
                  fontSize: "14px",
                }}
              >
                <Typography>
                  {localStorage.getItem("firstName") === null
                    ? ""
                    : _.upperCase(localStorage.getItem("firstName").charAt(0))}
                  {localStorage.getItem("lastName") === null
                    ? ""
                    : _.upperCase(localStorage.getItem("lastName").charAt(0))}
                </Typography>
              </Avatar>
            </>
          ) : (
            <>
              <Customized.Avatar
                src={image}
                alt="Avatar"
                style={{ height: "50px", width: "60px", marginRight: "14px" }}
              ></Customized.Avatar>
            </>
          )}
        </ListItemAvatar>
      )}
      <ListItemText
        onClick={title === "Logout" ? () => handleClick() : () => { }}
        className={classes}
        primary={
          <Tooltip title={title} arrow>
            <Typography
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </Typography>
          </Tooltip>
        }
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
        style={{ paddingLeft: image === "" ? "10px" : "10px" }}
      />
    </Customized.ListItem>
  )
}
function UserDropdown() {
  const history = useHistory()
  const { t } = useTranslation()
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [pathName, setPathName] = useState("/")
  const [allowPrompt, setAllowPrompt] = useState(false)
  const [logout, setLogout] = useState("")
  const flag = useSelector((state) => state.loginstateReducer)
  const [firstName, setFirstName] = useState(localStorage.getItem("firstName"))
  const [lastName, setLastName] = useState(localStorage.getItem("lastName"))
  const [userName, setUserName] = useState("")
  const userData = useSelector((state) => state.profileUpdateReducer)
  const userProfileDetails =
    useSelector((state) => state.adminReducer.userProfileDetails) || {}
  let keyClockUserId = localStorage.getItem("userId")
  let sassAdmin = localStorage.getItem("role")
  const classes = useStyles()
  const handleOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (
      _.includes(localStorage.getItem("sourceRole"), "SaaS-Site-Admin") ||
      _.includes(localStorage.getItem("sourceRole"), "Sub-SaaS-Admin")
    ) {
      getSubSaaSUserDetail()
    } else {
      getUserDetails()
    }
  }, [])

  const getUserDetails = () => {
    setLoading(true)
    userApi.getUserDetail(keyClockUserId).then(async (resp) => {
      dispatch(profileUpdateData(resp?.data?.data))
      await setLoading(false)
    })
  }

  const getSubSaaSUserDetail = () => {
    setLoading(true)
    userApi.getSubSaaSUserDetail(keyClockUserId).then(async (resp) => {
      dispatch(profileUpdateData(resp?.data?.data))
      await setLoading(false)
    })
  }
  useEffect(() => {
    setFirstName(localStorage.getItem("firstName"))
    setLastName(localStorage.getItem("lastName"))
    var userName =
      localStorage.getItem("firstName") + " " + localStorage.getItem("lastName")
    setUserName(userName)
  }, [localStorage.getItem("firstName"), localStorage.getItem("lastName")])

  const handleClose = () => {
    setOpen(false)
  }
  const handleLogout = () => {
    setLoading(true)
    setTimeout(() => {
      localStorage.clear()
      UserService.doLogout()
      setLoading(false)
    }, 1000)
  }
  const handleCustomCloseRedirect = () => {
    handleLogout()
    setOpenConfirmationDialog(false)
    setOpen(false)
  }
  const handleCloseButtonTrigger = () => {
    dispatch(loginFlagDashBoard(false))
  }
  return (
    <React.Fragment>
      <Adm.BackdropOverlay open={loading} />
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        handleCloseButtonTrigger={handleCloseButtonTrigger}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
        setLogout="Do you want to logout ?"
      />
      <IconButton
        color="inherit"
        ref={ref}
        onClick={() => {
          handleOpen()
        }}
        data-testid="openProfile"
        style={{ backgroundColor: "transparent", cursor: "pointer" }}
      >
        <Avatar
          src={userData?.profileImage}
          style={{
            backgroundColor: "#F3F7FC",
            color: "var(--primaryColor)",
            height: "45px",
            width: "45px",
            fontSize: "14px",
          }}
        >
          <Typography>
            {localStorage.getItem("firstName") === null
              ? ""
              : _.upperCase(localStorage.getItem("firstName").charAt(0))}
            {localStorage.getItem("lastName") === null
              ? ""
              : _.upperCase(localStorage.getItem("lastName").charAt(0))}
          </Typography>
        </Avatar>
      </IconButton>
      <Customized.Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorEl={ref.current}
        onClose={() => handleClose()}
        open={isOpen}
      >
        <React.Fragment>
          <List disablePadding p={2}>
            <PopupItem
              title={userName === null ? "" : userName}
              description={
                localStorage.getItem("role") === null
                  ? ""
                  : localStorage.getItem("role")
              }
              userData={userData}
              style={{ padding: "10px" }}
              divider={false}
              icon={false}
              classes={classes.listItemWrap}
            />

            <Grid container direction="row" style={{ display: "flex" }}>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push(
                    `/${userProfileDetails?.roleType}/profileUpdate/${keyClockUserId}`
                  )
                }}
              >
                <img
                  src="/static/img/profileSetting.svg"
                  alt="profileSetting Page"
                  style={{ margin: "8px 15px 10px 10px", cursor: "pointer" }}
                />
                Profile Settings
              </Typography>
            </Grid>
            <Divider />
            <PopupItem
              title={
                localStorage.getItem("userEmail") === null
                  ? ""
                  : localStorage.getItem("userEmail")
              }
              image={
                <EmailOutlined style={{ color: "#9E9E9E", fontSize: "26px" }} />
              }
              icon={true}
              divider={true}
              classes={classes.listItemTextWrap}
            />
            <Divider />
            <PopupItem
              classes={classes.listItemTextWrap}
              title={window?.globalTranslation("Logout")}
              image={
                <PowerSettingsNewOutlined
                  style={{ color: "#9E9E9E", fontSize: "28px" }}
                />
              }
              icon={true}
              divider={false}
              handleClick={() => {
                setLoading(true)
                dispatch(loginFlagDashBoard(true))
                setOpenConfirmationDialog(true)
                setLoading(false)
                handleClose()
              }}
            />
          </List>
        </React.Fragment>
      </Customized.Popover>
    </React.Fragment>
  )
}
export default UserDropdown

