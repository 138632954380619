import React, { useEffect, useState } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Typography,
  Paper,
  Box,
  IconButton,
  Grid,
} from "@material-ui/core"
import styled from "styled-components"

export const GlobalImportExportMainWrapper = styled(Paper)`
  text-align: center;
  cursor: pointer;
  width: 100%;
  border-radius: 20px;
  height: 130px;
  position: relative;
`
export const GlobalGridWrapper = styled(Grid)`
  height: 126px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const GlobalGridLeftWrapper = styled(Grid)`
  height: 126px;
  background: transparent;
  position: absolute;
  z-index: 99;
  left: 10px;
  .cls-dkp-progress-wrapper {
    display: flex;
    flex-direction: column;
    width: 250px;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 10px 8px 10px 8px;
  }
`
export const GlobalGridRightWrapper = styled(Grid)`
  background: transparent;
  position: absolute;
  right: 10px;
  top: 70px;
`
export const GlobalGridRightTopWrapper = styled(Grid)`
  background: transparent;
  position: absolute;
  right: 10px;
  top: 5px;
`
export const GlobalImportContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  min-width: 700px;
`

export const StyledGrid = styled(Grid)`
  align-self: self-end;
  text-align: right;
  padding: 5px;
  display: flex;
  width: 100%;
  justify-content: end;
  position: absolute;
  right: -10px;
  width: 285px;
  z-index: 999;
  .cls-dk-icon-btn {
    margin-left: 0px !important;
    margin-top: 5px !important;
    cursor: pointer;
  }
  .cls-dk-menu-1 {
    height: 26px;
    text-align: left;
    width: 122px;
  }
  .cls-dk-menu-2 {
    height: 26px;
    text-align: left;
    width: 82px;
  }
  .cls-dk-formcontrol-1 {
    margin-left: 5px;
  }
  .cls-dk-formcontrol-2 {
    margin-left: 5px;
  }
`

export const StyledDiv = styled("div")`
  display: flex;
  align-self: self-start;
  text-align: left;
  padding: 5px;
  position: absolute;
  left: 5px;
  top: 78px;
  .cls-dk-icon-btn {
    cursor: pointer;
    margin-top: -7px !important;
    padding-right: 10px;
  }
`
export const StyledErrorDiv = styled("div")`
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 5px;
  position: absolute;
  right: -10px;
  top: 3px;
  .cls-dk-error-wrapper {
    width: 150px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    text-transform: unset;
    text-align: right;
    word-wrap: break-word;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
  }
`

export const StyledTypography = styled(Typography)`
  text-align: left;
  padding-left: 10px;
`
export const StyledGridWrap = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const StyledTemplateWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 230px;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  .cls-dkph-trim {
    width: 600px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .cls-dk-link-btn {
    color: rgba(255, 0, 0, 1);
    font-weight: 400;
    padding-left: 0px !important;
    text-decoration: underline;
  }
`
export const StyledFilePreUploadDetails = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
  margin: 3px;
  .cls-dkp-file-name {
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    text-transform: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
  }
  .cls-dkp-file-size {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
  .cls-dkp-file-status {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    text-transform: unset;
    text-align: left;
    word-wrap: break-word;
    width: 200px;
  }
  .cls-dkp-word-break {
    word-break: break-all;
    width: 250px;
    text-transform: unset;
    text-align: center;
    text-align: left;
  }
`
export const StyledFileTypography = styled(Typography)`
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-transform: capitalize;
  color: #b71174;
`
export const StyledSyncWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 3px;
  .loading-bar {
    margin: 0 auto;
    height: 20px;
    width: 30%;
    border-radius: 50px;
    bottom: 10px;
  }

  span {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    background-image: linear-gradient(to bottom, #fff, #f599f2 60%);
    box-shadow: inset 0 2px 9px rgba(0, 0, 0, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    overflow: hidden;
  }

  span:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }

  .loading-bar > span:after,
  .animate > span > span {
    animation: load 1s infinite;
  }

  @keyframes load {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
  }
`
export const StyledSyncTypography = styled(Typography)`
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  color: #b71174;
  word-wrap: break-word;
  width: 250px;
  text-align: center;
`
export const StyledSpinner = styled("div")`
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  margin-left: 5px;
  margin-top: 20px;
`
