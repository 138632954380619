export const getCityFromList = (response) => {
  let cityList = []
  response.forEach((o) => {
    cityList.push(o.city)
  })

  return cityList
}

export const coloringButton = (verifiedFlag, loading, notValidFlag) => {
  var normalColor = 'rgb(244 244 244)'
  var errorColor = 'rgb(226 108 108)'
  var verifingColor = 'rgb(209 209 209)'
  var verifiedColor = 'rgb(132 199 120)'

  if (notValidFlag) {
    return errorColor
  } else if (loading) {
    return verifingColor
  } else if (verifiedFlag) {
    return verifiedColor
  } else {
    return normalColor
  }
}

export const buttonContent = (verifiedFlag, loading, notValidFlag) => {
  let normalText = 'Verify Code'
  let verifingText = 'Verifing...'
  let verifiedText = 'Verified'
  let errorText = 'Not Valid'

  if (notValidFlag) {
    return errorText
  } else if (loading) {
    return verifingText
  } else if (verifiedFlag) {
    return verifiedText
  } else {
    return normalText
  }
}