const ProductLookupFixture = [
  {
    field: "item_id",
    title: "Item ID",
    filter: false,
    border: true,
    show: window["isEnableItemId"],
    min_width: 20,
  },
  {
    field: "title",
    title: "Product Name",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "barcodeValue",
    title: "Barcode Value",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },

  {
    field: "brand",
    title: "Brand",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "manufacturer",
    title: "Manufacturer",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
]
export default ProductLookupFixture
