import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS

const initialState = {
    createPosSuccess: false,
    detailsForPosId: [],
    errorGetDetails: {},
}

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
        case types.ADD_POS_SUCCESS:
            return {
                ...state,
                createPosSuccess: actions.success,
            }
        case types.GET_DETAILS_FOR_POS:
            return {
                ...state,
                detailsForPosId: actions.obj,
            }
        case types.ERROR_DETAILS_FOR_POS:
            return {
                ...state,
                errorGetDetails: actions.obj,
            }
        default:
            return state
    }
}
