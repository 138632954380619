import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Grid, TextField } from "@material-ui/core"
import { columnsTenant, columsSassAdmin, excelExportColumns, columsPopup } from "./fixture"
import { makeStyles } from '@material-ui/core/styles';
import { AddIcon } from "@iconsGallery"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import * as Adm from "@adm"
import { Formik } from "formik"
import _, { values } from "lodash"
import * as commonStylesKit from "@commonStyles"
import SharedTable from "../../masterData/sharedComponents/Table/index"
import { ConfirmationDialog } from "@features"
import { PageHeaderBreadcrumbKit, TemplateExport } from "./styles"
import userApi from "../../../services/user/api"
import * as ServiceAPI from "@services"
import { formatISO, isValid, format } from "date-fns";
import * as constantsAPI from "@constants"
import { ImportButtonUI, ExportButtonUI } from "@features"
import excelSheet from './excelSheet';
import * as XLSX from "xlsx";
import { excelSheetValidation } from "../../../FeaturesKit/PortButton/renderProps"
import ExcelErrorTemplate from "../../masterData/sharedComponents/ExcelErrorTemplate"
import SearchByDate from "../../../pages/transactions/AllTransactions/searchComponents/SearchByDate/index"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { async } from "q";
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const { headerAxios, API_BASE_URL, bulkUploadProducts } = ServiceAPI;

const { commonStyles } = commonStylesKit || {}
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    productBtn: {
        margin: "0px",
        '@media(min-width:1200px)': {
            '& .MuiGrid-grid-lg-1': {
                maxWidth: '14%',
                flexBasis: '14%',
            }
        }
    },
    autoCompleteHeight: {
        '& .MuiAutocomplete-inputRoot': {
            height: "45px",
        },
        '& .MuiAutocomplete-input': {
            padding: "0px 4px !important"
        },

        "& .MuiFormControl-fullWidth": {
            width: "10%",
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                "borderColor": "var(--primaryColor)",
            }
        }
    },
}))

function AuditLogPopup(props) {
    const { isWriteAllowed } = props
    const commonClasses = commonStyles()
    let history = useHistory()
    const classes = useStyles()
    const { t } = useTranslation()
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
    const [reDirect, setReDirect] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState({})
    const [reRender, set_reRender] = useState(props?.reRender || false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        searchBy: "",
        searchTerm: "",
        sort: "",
        filter: [
            {
                field: "userType",
                operator: "eq",
            },
        ],
    })
    const [searchBy, setSearchBy] = useState("")
    const [datafilter, setDateFilter] = useState({})
    const [searchObj, setSearchObj] = useState(_.get(props, "params.filter", {}))
    let tenantAdmin = localStorage.getItem("role")
    const [date, setDate] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [tenantNameList, setTenantNameList] = useState([])
    const [moduleNameList, setModuleNameList] = useState([])
    const [subModuleNameList, setSubModuleNameList] = useState([])
    const [moduleCompleteList, setModuleCompleteList] = useState([])
    const [tenantOpen, setTenantOpen] = useState(false)
    const [moduleOpen, setModuleOpen] = useState(false)
    const [subModuleOpen, setSubModuleOpen] = useState(false)
    const [selectTenantName, setSelectTenantName] = useState("")
    const [selectModuleName, setSelectModuleName] = useState("")
    const [exportGridData, setExportGridData] = useState()
    const [openPopupDialog, setOpenPopupDialog] = React.useState(false);
    const [fileData, setFileData] = React.useState({
        files: [],
    })
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [isDialogOpen, setDialogOpen] = React.useState(false)

    useEffect(() => {
        fetchAuditLog();
    }, [])

    const logMassageStyle = {
        width: "300px",
        overflow: "hidden",
        display: "block",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }

    const fetchAuditLog = async () => {
        await userApi.getPopupAuditLogs(props?.recordId).then(async (resp) => {
            await setData(_.get(resp, "data.data", ""))
        })
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: "var(--gray)" }}>
                                    <TableCell align="left">User Email</TableCell>
                                    <TableCell align="left">Activity</TableCell>
                                    <TableCell align="left">Log Message</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow key={row._id}>
                                        <TableCell align="left">{row.userEmail}</TableCell>
                                        <TableCell align="left">{row.activity}</TableCell>
                                        <TableCell align="left">
                                            <Tooltip title={row.logMessage}>
                                                <span style={logMassageStyle}>{row.logMessage}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">{row.changedAt}</TableCell>
                                    </TableRow>
                                ))}
                                <>
                                    {data?.length === 0 ?
                                        <TableRow>
                                            <TableCell colspan={4} align="center" >
                                                {data?.length === 0 ? "No Record(s) Found" : ""}
                                            </TableCell>
                                        </TableRow>
                                        : ""}
                                </>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )

}

export { AuditLogPopup }
export default AuditLogPopup
