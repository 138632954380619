import React from "react"
import styled from "styled-components"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import * as Styles from "./styles"
import { Typography } from "@material-ui/core"
import _ from "lodash"
import moment from "moment"

function renderTrackerWrapper(props) {
  const { trackerList, width, handleAccordionChange } = props

  return (
    <div>
      {trackerList.map((stepObject, idx) =>
        renderOneStepOfTracker({
          stepObject,
          idx,
          lastElement: trackerList.length === idx + 1,
          handleAccordionChange,
          width,
        })
      )}
    </div>
  )
}

function renderOneStepOfTracker(props) {
  const { stepObject, idx, lastElement, handleAccordionChange, width } = props
  return (
    <Styles.Wrapper>
      <Styles.StepTrackWrapper idx={idx}>
        {renderStepTrackLine({
          status: stepObject.currentPoseStatus,
          lastElement,
        })}
      </Styles.StepTrackWrapper>
      <div style={{ width: "100%" }}>
        {renderAccordianPart({
          heading: stepObject.updated_status,
          tag: stepObject.status,
          status: stepObject.updated_status,
          isOpen: stepObject.isOpen,
          dateTimeStamp: moment(_.get(stepObject, "utc_datetime", "")).isValid()
            ? moment(_.get(stepObject, "utc_datetime", "")).format(
                "DD-MMM-YYYY hh:mm:ss A"
              )
            : "-",
          content: stepObject?.message,
          idx,
          handleAccordionChange,
          width,
        })}
      </div>
    </Styles.Wrapper>
  )
}

function renderStepTrackLine(props) {
  const { status, lastElement } = props

  return (
    <>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ height: "100%" }}>
          <Styles.StepBall status={status} />
          {!lastElement && <Styles.StepLine status={status} />}
        </div>
        <div>
          <Styles.MidLine />
        </div>
      </div>
    </>
  )
}

function renderAccordianPart(props) {
  const {
    idx,
    heading,
    status,
    isOpen,
    dateTimeStamp,
    content,
    handleAccordionChange,
    width,
  } = props

  return (
    <Styles.AccordionTag
      expanded={isOpen}
      className={"AccordianTag here"}
      onChange={(event, isExpanded) => {
        handleAccordionChange(idx, isExpanded)
      }}
      width={width}
    >
      <Styles.AccordionSummaryTag
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        status={status}
      >
        <Styles.SummaryHeaderWrapper>
          <Styles.SummaryHeader status={status}>
            <Styles.Heading>{_.upperCase(heading)}</Styles.Heading>
          </Styles.SummaryHeader>
          <Styles.SummaryDateTime status={status}>
            <Typography variant="h5">{dateTimeStamp}</Typography>
          </Styles.SummaryDateTime>
        </Styles.SummaryHeaderWrapper>
      </Styles.AccordionSummaryTag>

      <Styles.AccordionDetailsTag>
        {content && <Typography>{content}</Typography>}
      </Styles.AccordionDetailsTag>
    </Styles.AccordionTag>
  )
}

export { renderTrackerWrapper }
