export const operatingHrs  = [
  {
    day: "sunday",
    selected: false,
    times: [],
    errorsArr : []
  },
  {
    day: "monday",
    selected: false,
    times: [],
    errorsArr : []
  },
  {
    day: "tuesday",
    selected: false,
    times: [],
    errorsArr : []
  },
  {
    day: "wednesday",
    selected: false,
    times: [],
    errorsArr : []
  },
  {
    day: "thursday",
    selected: false,
    times: [],
    errorsArr : []
  },
  {
    day: "friday",
    selected: false,
    times: [],
    errorsArr : []
  },
  {
    day: "saturday",
    selected: false,
    times: [],
    errorsArr : []
  }
];