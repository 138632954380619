import styled from "styled-components/macro"
import { rgba } from "polished"
import PerfectScrollbar from "react-perfect-scrollbar"
import "../../../vendor/perfect-scrollbar.css"

import {
  Chip,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"

import { ExpandMore, ChevronRight } from "@iconsGallery"

export const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }

`

export const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12); 
`

export const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`

export const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`

export const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(9)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`

export const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  color: var(--secondaryTextColor);
   &:hover {
    background: var(--primaryColor) !important;
    color: var(--primaryTextColor) !important;
    svg { 
      color: var(--primaryTextColor) !important;
    }
  }
  svg {
    color: var(--secondaryTextColor);
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &.${(props) => props.activeclassname} {
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
    svg {
    color: var(--primaryTextColor);
    }
    span {
    color: var(--primaryTextColor);
    }
  }
`
export const SubCategory = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
   &:hover {
    background: var(--primaryColor) !important;
    color: var(--primaryTextColor) !important;
    svg {
    color: var(--primaryTextColor);
    }
  }
  svg {
    color: var(--secondaryTextColor);
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
 
  &.${(props) => props.activeclassname} {
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
  }
`

export const CategoryText = styled(ListItemText)`
  margin: 0;
  display:${props => props.showMore && "none"};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(2)}px;
  }

`

export const SubCategoryText = styled(ListItemText)`
  margin: 0;
  display:${props => props.showMore && "none"};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(2)}px;
  }

`

export const CategoryIconLess = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

export const CategoryIconMore = styled(ChevronRight)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

export const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(14)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  &:hover {
     background-color: var(--primaryColor);
     color: var(--primaryTextColor);
 }

   &.${(props) => props.activeclassname} {
      background-color: var(--primaryColor);
      color: var(--primaryTextColor);
   }
`

export const SubLink = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(18)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

   &:hover {
     background-color: var(--primaryColor);
     color: var(--primaryTextColor);
 }
}

  &:hover {
    background-color: ${(props) => props.theme.sidebar.active};
  }

  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.sidebar.active};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

export const LinkText = styled(ListItemText)`
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize};
  }

  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  &:hover {
    color: var(--primaryTextColor);
  }
`

export const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`

export const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`

export const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;

`

export const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`
