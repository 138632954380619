import styled from "styled-components"
import { Grid } from "@material-ui/core"

export const StyledCropperMainWrapper = styled(Grid)`
  width: 800px;
  height: fit-content;
  overflow: hidden;

  .cls-al-header {
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #9f9f9f;
    h2 {
      font-weight: bold !important;
    }
  }

  .cls-base-dropdown-kit {
    max-width: 250px;
  }
  .cls-base-dropdown-kit .MuiSelect-select,
  .cls-base-dropdown-kit .cls-empty-textfield {
    width: 250px;
  }
  .cls-al-container {
    padding: 10px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    width: 100%;

    textarea {
      height: 70px !important;
      width: 100% !important;
    }
    .cls-al-innr-container-l,
    .cls-al-innr-container-r {
      width: 50%;
    }
  }

  .cls-al-titl-head {
    font-weight: bold !important;
    margin-bottom: 5px;
  }

  .cls-al-image-main-wrapper {
    width: 100%;
    height: 116px;
    overflow: auto;
    padding: 4px;
    border: 1px solid#aaaaaa;
    border-radius: 5px;
    box-sizing: border-box;
  }
  .cls-al-image-main-kit {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 8px;
    height: auto;
  }

  .cls-al-image-kit {
    min-height: 50px;
    min-width: 50px;
    max-width: 100%;
    max-height: 70px;
    height: 70px;
    border-radius: 6px;
    border: 3px solid #ccc;
    padding: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    cursor: pointer;
    &.disabled-image {
      opacity: 0.5;
      pointer-events: none;
    }
    &.active {
      border: 3px solid var(--primaryColor);
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 12px #00000047;
      position: absolute;
      left: 0;
      top: 0;
    }

    img {
      width: fit-content;
      height: 100%;
      margin: auto;
      display: flex;
      max-width: 100%;
      border-radius: 5px;
    }
  }
`
