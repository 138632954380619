import React, { useEffect } from "react"
import _ from "lodash"
import { Grid } from "@material-ui/core"
import * as Adm from "@adm"
import * as Library from "@compLib"
import * as Fixture from "./fixture"
import * as ServiceAPI from "@services"
let isLocal = false
const columnData = Fixture?.defaultDigitImageTableColumnData
const isSearchSeperate = false

const getDigitShelf = (props) => {
  const { type = "post", cbk = () => "", data = {}, getIsLocal = false } =
    props || {}

  let Services =
    type === "put" ? ServiceAPI.doPutFetchAPI : ServiceAPI.doPostFetchAPI
  Services(
    data,
    null,
    getIsLocal ? getIsLocal : type === "put" ? isLocal : false
  )
    .then((response) => {
      cbk({ status: "success", response })
    })
    .catch((error) => {
      cbk({ status: "error", response: { ...error } })
    })
}

function DigitImageTable(props) {
  const {
    revertChange = () => "",
    currentList = [],
    getParamData = { locationId: "", planogramId: "" },
    getRawList = () => "",
    refreshList = [],
    searchTrigger = "",
    getStorageUnitId = "",
    getVendorId = "",
    setSelectedItem = () => { },
    productGridTableData = "",
    setProductGridTableData = () => { }
  } = props || {}


  const { hideCheckBoxAll = false, onUpdate = () => "" } = props || {}
  const defaultDataTableState = {
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [],
    take: 20,
    skip: 0,
  }

  const [gridTableState, setGridTableState] = React.useState({
    dataState: defaultDataTableState,
  })
  const [rawTableData, setRawTableData] = React.useState({})
  const [getTableLoading, setTableLoading] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState("")

  const getPostAPIfetchData = async (innerProps) => {
    setTableLoading(true)
    const { searchTerm = "" } = searchObj || {}
    const { filter = {} } = innerProps || {}
    const { filters = [] } = filter || {}
    let filterObj = {}

    if (filters.length > 0) {
      filters.forEach((itm) => {
        filterObj[itm?.field] = itm?.value
      })
    }

    let defaultFilter = [
      {
        "field": "company_id",
        "operator": "eq",
        "value": getVendorId //"COMAPNY_43"
      },
      {
        "field": "storage_unit_id",
        "operator": "eq",
        "value": getStorageUnitId //"STRUNIT_043"
      }];
    let data = {
      END_POINTS: Fixture?.EndPointAPI?.getStorageUnitMappings,
      body: {
        page: Math.floor(gridTableState.dataState.skip / gridTableState.dataState.take) + 1 || 1,
        limit: gridTableState.dataState.take,
        filter: searchTrigger.length > 0 ?
          [...defaultFilter,
          {
            field: "product_title",
            operator: "contains",
            value: searchTrigger || "",
          },
          ]
          : [...defaultFilter],
        sort: {},
      },
    }
    getDigitShelf({
      type: "post",
      data,
      cbk: (innProps) => {
        const { response = {}, status = "" } = innProps
        if (status === "success") {
          let destructure = _.get(response, "data.data.docs", [])
          if (destructure.length > 0) {
            destructure = destructure.map((itm) => {
              return {
                item_images: itm?.product_image_url || "/static/img/noproducts.png",
                ...itm
              }
            })
          }
          getRawList({ data: destructure })
          setProductGridTableData({ data: destructure })
          setRawTableData({
            totalDocs: _.get(response, "data.data.totalDocs"),
          })
        } else {
          revertChange()
        }
        setTableLoading(false)
      },
    })
  }

  React.useEffect(() => {
    setGridTableState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
    setSearchTerm(searchTerm)
  }, [searchTerm])
  React.useEffect(() => {
    getPostAPIfetchData(gridTableState.dataState)
  }, [gridTableState.dataState, refreshList, searchTrigger.length > 0])


  const handleSearchTerm = (val) => setSearchTerm(val)
  const handleClear = () => setSearchTerm("")
  let searchObj = { searchTerm: searchTerm }
  const handleConnect = (innerEvent) => {
    const {
      isConnected: currentIsConnected = false,
      currentItem = {},
    } = innerEvent
    const { dataItem = {} } = currentItem
    let data = productGridTableData?.data.map((itm) => {
      const { isConnected = false } = itm
      return {
        ...itm,
        isConnected:
          itm?.product_item_id === dataItem.product_item_id ? currentIsConnected : isConnected,
      }
    })
    let obj = {
      ...productGridTableData,
      data,
    }

    let selectedItems = []
    _.map(data, (x, i) => {
      if (x?.isConnected) selectedItems.push(x?.product_item_id)
    })
    setSelectedItem(selectedItems)
    setProductGridTableData(obj)

  }

  return (
    <Grid
      style={{
        overflowY: "auto",
        height: "calc(100% - 60px)",
      }}
    >
      <Grid
        style={{
          border: "0px solid red",
          display: "flex",
          flexGrow: 1,
          flexWrap: "wrap",
          alignContent: "flex-start",
          height: "100%",
        }}
      >
        <Adm.BackdropOverlay open={getTableLoading} />
        <Grid
          container
          style={{
            overflow: "hidden",
            height: "100%",
          }}
        >
          {isSearchSeperate && (
            <Grid
              style={{
                padding: "10px",
                borderBottom: "1px solid #EEEEEE",
                position: "relative",
              }}
            >
              <Adm.SearchLookup
                placeholder="By Name"
                searchObj={searchObj}
                handleClear={handleClear}
                handleSearchTerm={handleSearchTerm}
              />
            </Grid>
          )}
          <Grid
            style={{
              minHeight: "480px",
              overflow: "auto",
              height: "100%",
            }}
          >
            <Library.MaterialDataGrid
              otherProps={{ style: { height: "100%" } }}
              buttonCount={1}
              gridNoRecordsText={"No records found"}
              checkBoxWidth={""}
              hideCheckboxAll={hideCheckBoxAll}
              gridState={gridTableState}
              columnData={columnData}
              rowData={productGridTableData}
              number_of_items={_.toFinite(rawTableData?.totalDocs || 0)}
              onConnectedChecked={(e) => handleConnect(e)}
              setRowData={setProductGridTableData}
              setGridState={setGridTableState}
              isLookup={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DigitImageTable
export { DigitImageTable }
