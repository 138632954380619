import React from "react"

function useListPageState(props) {
  const { dataState, defaultListConfig = [] } = props || {}
  const selectionState = {
    selectedItems: [],
    store_ids: [],
    selectAll: false,
    selectedLocationRowItems: [],
  }

  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [alert, setAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [alertType, setAlertType] = React.useState("")
  const [columnData, setColumnData] = React.useState(defaultListConfig)
  const [rowData, setRowData] = React.useState()
  const [items, setItems] = React.useState(0)
  const [gridState, setGridState] = React.useState({ dataState })
  const [pageData, setPageData] = React.useState([])
  const [selection, setSelection] = React.useState(selectionState)

  return {
    pageData,
    setPageData,
    data,
    setData,
    loading,
    alert,
    alertMessage,
    alertType,
    columnData,
    rowData,
    gridState,
    items,
    selection,
    setItems,
    setSelection,
    setAlert,
    setLoading,
    setAlertMessage,
    setAlertType,
    setColumnData,
    setRowData,
    setGridState,
  }
}

export { useListPageState }
