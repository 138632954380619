import React from "react"
import styled from "styled-components/macro"
import { Link, useHistory } from "react-router-dom"

import Helmet from "react-helmet"

import { Button as MuiButton, Typography } from "@material-ui/core"
import { spacing } from "@material-ui/system"
import UserService from "../../../src/services/UserService"

const Button = styled(MuiButton)(spacing)


const Wrapper = styled.div`
  padding: 6px;
  text-align: center;
  background: transparent;  
`

function UnAuthorizedUser() {
    const history = useHistory()
    const returnWeb = () => {
        UserService.doLogout()
    }

    return (
        <>
            <Wrapper>
                <div style={{ position: "relative" }}>
                    <img src="/static/img/pageNotFound1.svg" alt="pageNotFound Page" />
                    <div style={{ position: "absolute", top: "88%", left: "52%" }}>
                        <Button
                            to="/"
                            variant="contained"
                            style={{
                                background: "#8080D2",
                                width: "150px",
                                borderRadius: "20px",
                                color: "#FFF",
                            }}
                            onClick={() => returnWeb()}
                        >
                            Log out
                        </Button>
                    </div>
                </div>
            </Wrapper>

        </>
    )
}

export default UnAuthorizedUser
