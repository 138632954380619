import * as Yup from "yup"

export const defautFixtureFormList = ({
  name = "",
  line1 = "",
  line2 = "",
  line3 = "",
  line4 = "",
  country = "",
  state = "",
  city = "",
  zip_code_5 = "",
  zip_code_4 = "",
  billing = false,
  shipping = false,
  countryList = [],
  stateList = [],
} = {}) => {

  return [
    {
      label: "Full Name",
      defaultValue: name,
      inputType: "text",
      identifer: "name",
      isOptional: false,
      validationSchema: Yup.string()
        .required(`Enter Full Name`)
        .matches(/^[aA-zZ\s]+$/, `Enter valid Full Name`),
    },
    {
      label: "Address Line 1",
      defaultValue: line1,
      inputType: "text",
      identifer: "line1",
    },
    {
      label: "Address Line 2",
      defaultValue: line2,
      inputType: "text",
      identifer: "line2",
      validationSchema: Yup.string()
    },
    {
      label: "Address Line 3",
      defaultValue: line3,
      inputType: "text",
      identifer: "line3",
      validationSchema: Yup.string()
    },
    {
      label: "Address Line 4",
      defaultValue: line4,
      inputType: "text",
      identifer: "line4",
      validationSchema: Yup.string()
    },
    {
      label: "Country",
      labelPath: "name",
      placeholder: "Select Country",
      defaultValue: country,
      list: countryList,
      inputType: "dropdown",
      identifer: "country",
      isOptional: false,
      validationSchema: Yup.object().required("Select Country"),
    },
    {
      label: "State",
      labelPath: "name",
      defaultValue: state,
      placeholder: "Select state",
      list: stateList,
      inputType: "dropdown",
      identifer: "state",
      isOptional: false,
      validationSchema: Yup.object().required("Select State"),
    },
    {
      label: "City",
      defaultValue: city,
      inputType: "text",
      identifer: "city",
      isOptional: false,
      validationSchema: Yup.string()
        .required(`Enter City`)
    },
    {
      label: "Zip Code 5",
      defaultValue: zip_code_5,
      inputType: "text",
      identifer: "zip_code_5",
      isOptional: false,
      validationSchema: Yup.string()
        .required(`Enter Zip Code 5`)
        .matches(
          '^[0-9]+$',
          "Please enter valid Zip Code",
        )
        .min(5, "Zip Code can be of min-5 max-10 characters")
        .max(10, "Zip Code can be of min-5 max-10 characters")
    },
    {
      label: "Zip Code 4",
      defaultValue: zip_code_4,
      inputType: "text",
      identifer: "zip_code_4",
      isOptional: false,
      validationSchema: Yup.string()
        .required(`Enter Zip Code 4`)
        .matches(
          '^[0-9]+$',
          "Please enter valid Zipcode",
        )
        .min(4, "Zipcode can be of min-4 max-10 characters")
        .max(10, "Zipcode can be of min-4 max-10 characters")
    },
    {
      label: "Billing",
      defaultChecked: billing,
      inputType: "checkBox",
      identifer: "billing",
    },
    {
      label: "Shipping",
      defaultChecked: shipping,
      inputType: "checkBox",
      identifer: "shipping",
    },
  ]
}
