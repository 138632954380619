import * as React from "react"
import { Card } from "@material-ui/core"

import styled from "styled-components"
import _ from "lodash"
import { useFacetUIState } from "./state"
import { useFacetUIStyles } from "./styles"
import * as Adm from "@adm"
import * as Utils from "@utils"
import { formList } from "./fixture"
import {
  customRenderAccordionHeader,
  customRenderContainer,
} from "./renderProps"

const CardWrapper = styled(Card)`
  width: 100%;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 2px rgba(63, 63, 68, 0.15);
  border-radius: 8px;
`

const DynamicFacetUI = (props) => {
  const {
    title = "facets",
    isEditMode = true,
    isOpenMode = false,
    list = formList,
    onUpdate = () => "",
    listColumnSplit = false,
    editMode = false,
    dummy = false,
    setAllowPrompt=()=>{},
    formSubmit = false,
    updateFormSubmit = () => "",
    reset = () => { },
    resetData = () => { },
    isWriteAllowed = false,
    ...remainingProps
  } = props || {}

  const classes = useFacetUIStyles()
  const facetState = useFacetUIState()
  const [isAnyChanges, setAnyChanges] = React.useState(false)
  const [initialCount, setInitialCount] = React.useState(0)
  const [isError, setError] = React.useState(false)
  const {
    edit,
    setEdit,
    data,
    setData,
    getValidationEdit,
    setValidationEdit,
  } = facetState

  React.useEffect(() => setData(list || []), [list])
  const onUpdateData = async () => {
    onUpdate({ ...facetState })
  }

  return (
    <CardWrapper>
      <Adm.AccordianPanel
        isOpen={isOpenMode}
        isEditMode={isEditMode}
        isWriteAllowed={isWriteAllowed}
        renderHeader={(containerHeaderProps) => {
          return customRenderAccordionHeader({
            facetData: data,
            facetSetData: setData,
            getValidationEdit,
            isAnyChanges,
            setAnyChanges,
            setAllowPrompt,
            isError,
            setError,
            initialCount,
            setInitialCount,
            setValidationEdit,
            defaultList: list,
            reset,
            resetData,
            isWriteAllowed,
            ...containerHeaderProps,
          })
        }}
        renderContainer={(containerProps) =>
          customRenderContainer({
            ...containerProps,
            facetData: data,
            isAnyChanges,
            setAnyChanges,
            isError,
            setAllowPrompt,
            setError,
            initialCount,
            DynamicFacetUI,
            setInitialCount,
            facetSetData: setData,
            listColumnSplit,
            headingTitle: title,
          })
        }
        label={title}
        updateData={onUpdateData}
        {...remainingProps}
      />
    </CardWrapper>
  )
}
export { DynamicFacetUI }
export default DynamicFacetUI
