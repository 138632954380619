import React, { useState } from "react"
import { SmallButton, useStyles, StyledMenu } from "./styles"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  TextField,
  Grid,
} from "@material-ui/core"
import moment from "moment"
function CustomDateFilter(props) {
  const { defaultDate = "", onDateChange = () => "" } = props

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [error, setError] = useState(null)
  const [getDifferenceInDays, setDifferenceInDays] = useState(null)
  const classes = useStyles()

  const goButtonStyles = { marginLeft: "5px", marginTop: "8px" }

  const handleStartDateChange = (event) => {
    if (moment(event.target.value).isAfter(endDate)) {
      setError("Start date cannot be after end date")
      return
    } else {
      setError(null)
    }
    setError(null)
    setStartDate(event.target.value)
  }

  const handleEndDateChange = (event) => {
    if (moment(event.target.value).isBefore(startDate)) {
      setError("End date cannot be before start date")
      return
    } else {
      setError(null)
    }

    setEndDate(event.target.value)
  }

  const handleCaptureValues = () => {
    if (moment(endDate).isBefore(startDate)) {
      setError("End date cannot be before start date")
      return
    }
    onDateChange({ value: `${startDate}to${endDate}` })
  }

  React.useEffect(() => {
    let isCheckDataFormat = defaultDate.length > 0 && defaultDate.includes("to")
    if (isCheckDataFormat) {
      let lcDate = isCheckDataFormat ? defaultDate.split("to") : []
      let lcStartDate = lcDate[0]
      let lcEndDate = lcDate[1]
      setStartDate(lcStartDate)
      setEndDate(lcEndDate)
    }
  }, [defaultDate])
  return (
    <React.Fragment key={endDate}>
      <div style={{ width: "120%", marginTop: 5 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            inputVariant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.keyboardDatePicker}
            label="Start Date"
            type="date"
            defaultValue={startDate}
            onChange={handleStartDateChange}
            style={{ marginRight: 10 }}
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            inputVariant="outlined"
            className={classes.keyboardDatePicker}
            label="End Date"
            type="date"
            defaultValue={endDate}
            onChange={handleEndDateChange}
            style={{ marginRight: 10 }}
          />

          <SmallButton
            variant="contained"
            style={goButtonStyles}
            onClick={handleCaptureValues}
            disabled={error !== null}
          >
            {`Go`}
          </SmallButton>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </React.Fragment>
  )
}

export default CustomDateFilter
export { CustomDateFilter }
