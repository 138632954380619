import React from "react"
import * as Features from "@features"
import styled from "styled-components/macro"
import {Typography} from "@material-ui/core"


const OptionaTypography = styled(Typography)`
  font-size: 12px;
  padding-left: 20px;
  padding-bottom: 10px;
  color: #676767;
`

const MultiSearchFacetUIView = (props) => {
    const { edit: isEditMode, fetchData, buttonLabel, displayTabs, tabs, tableHeadings, idName, searchUrl, tableDataUpdate, elasticSearchEndpoint, treeData, fetchTreeData } = props
  
  return <>
        <Features.MultiSearchListForm
        edit={!isEditMode}
        list={fetchData}
        buttonLabel={buttonLabel}
        displayTabs={displayTabs}
        tabs={tabs}
        treeData = {treeData}
        tableHeadings={tableHeadings}
        fetchTreeData={fetchTreeData}
        tableDataUpdate={tableDataUpdate}
        elasticSearchEndpoint = {elasticSearchEndpoint}
        idName={idName}
        searchUrl={searchUrl}
    />
        </>
}

export { MultiSearchFacetUIView }