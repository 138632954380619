import React from "react"
import * as Adm from "@adm"
import { withStyles } from "@material-ui/core/styles"
import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  DialogContent,
} from "@material-ui/core"
import { SearchIcon } from "@iconsGallery"
import { styled } from "@material-ui/core/styles"
import _ from "lodash"

const SearchBox = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "4px 0px 0px 4px",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "var(--primaryColor)",
    },
  },
}))

const SearchLookup = (props) => {
  const {
    searchObj = {},
    handleSearchTerm = () => {},
    handleClear = () => {},
    renderChildren = () => "",
    placeholder = "",
  } = props
  const [searchVal, setSearchVal] = React.useState(searchObj.searchTerm)
  const [locType, setLocType] = React.useState("")

  React.useEffect(() => {
    setSearchVal(searchVal)
  }, [searchVal])

  React.useEffect(() => {
    setSearchVal(searchObj.searchTerm)
  }, [searchObj])

  return (
    <Grid
      container
      direction="row"
      style={{ paddingLeft: 0, justifyContent: "space-between" }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
        <div style={{ display: "flex", width: "15vw" }}>
          <SearchBox
            style={{ width: "100vw" }}
            variant="outlined"
            placeholder={`Search ${placeholder}`}
            size="small"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            onKeyDown={(e) => {
              if (e.which === 13) handleSearchTerm(searchVal)
            }}
          />
          <IconButton
            style={{
              backgroundColor: "var(--primaryColor)",
              borderRadius: "0px 4px 4px 0px",
              width: "36px",
              height: "37.5px",
            }}
            size="small"
            variant="outlined"
            onClick={() => handleSearchTerm(searchVal)}
          >
            <SearchIcon style={{ color: "white" }} />
          </IconButton>
        </div>
        <Button>
          <Typography
            color="primary"
            style={{ color: "var(--primaryColor)" }}
            onClick={() => {
              setLocType("")
              handleClear()
            }}
          >
            {`Clear`}
          </Typography>
        </Button>
      </div>
      {renderChildren()}
    </Grid>
  )
}
export default SearchLookup
export { SearchLookup }
