import React from 'react'
import { QuestionBlock, AnswerBlock } from './renderProps'
import { useQuestionAnswerLayout } from './useQuestionAnswerLayout'

function FAQ(props) {

  const stateProps = useQuestionAnswerLayout(props)

  return (
    <>
      {QuestionBlock(stateProps)}
      {AnswerBlock(stateProps)}
    </>
  )
}

export { FAQ }
export default FAQ
