import React, { useEffect, useState } from "react"
import { Grid, TablePagination } from "@material-ui/core"
import ProductAdjustMapping from "./ProductAdjustMapping"

export const ProductAdjustMappingList = (props) => {
  const {
    getPackageRawData = [],
    defaultList = [],
    isStorage = true,
    onUpdateEachItemSubmit = () => "",
    identifier = "",
    getStorageIncrememt = [],
    getStorageDecrement = [],
  } = props
  const rowsPerPageOptions = [5, 10, 20]

  const [getPage, setPage] = useState(0)
  const [getRowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0])

  let page = getPage
  let rowsPerPage = getRowsPerPage
  let totalCount = defaultList?.length || 100
  let filterData =
    defaultList.length > 0
      ? defaultList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : []

  const renderPagination = () => (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={totalCount}
      page={page}
      rowsPerPage={rowsPerPage}
      onChangePage={(event, newPage) => {
        setPage(newPage)
      }}
      onRowsPerPageChange={(event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
      }}
    />
  )

  return (
    <Grid>
      {renderPagination()}
      {filterData.map((product, index) => {
        let IncrementValue =
          product?.selected === undefined
            ? product?.selected
              ? "Increment"
              : "Decrement"
            : "Increment"

        let isIncrement = IncrementValue.includes("Increment")
        let isDecrement = IncrementValue.includes("Decrement")

        let quantity_value = product?.quantity || "0"
        let inventory_on_hand_value = product?.inventory_on_hand
        let projected_inventory_Value =
          quantity_value === "0"
            ? "0"
            : isIncrement
            ? `${Number(inventory_on_hand_value) + Number(quantity_value)}`
            : `${Number(inventory_on_hand_value) - Number(quantity_value)}`
        let editData = isStorage
          ? {
              ...product,
              storeUnit: identifier,
              projected_inventory: projected_inventory_Value,
              inventoryOnHand: product?.inventory_on_hand,
              adjustment_type: IncrementValue,
              package_type: isDecrement ? "empty" : product?.package_type || "",
              adjustment_reason: isIncrement
                ? "Restock"
                : product?.adjustment_reason === undefined
                ? "Damaged - Write Off"
                : product?.adjustment_reason || "",
              package_received: isDecrement
                ? "0"
                : isDecrement
                ? "0"
                : product?.package_received || "",
              order_quantity: isDecrement ? "0" : product?.order_quantity || "",
              order_received: isDecrement ? "0" : product?.order_received || "",
              unit_price: isDecrement ? "0" : product?.unit_price || "",
              total_price: isDecrement ? "0" : product?.total_price || "",
              expiry_date: isDecrement
                ? "2025-02-12"
                : product?.expiry_date || "",
              batch_number: isDecrement ? "0" : product?.batch_number || "",
              quantity: quantity_value || "",
            }
          : {
              ...product,
              storeUnit: identifier,
            inventoryOnHand: product?.inventory_on_hand,
            }

        return (
          <ProductAdjustMapping
            isStorageId={product?.storageunit_id}
            getPackageRawData={getPackageRawData}
            getStorageIncrememt={getStorageIncrememt}
            getStorageDecrement={getStorageDecrement}
            productIndex={index}
            viewPage="create"
            isStorage={isStorage}
            onUpdateSubmit={(e) => {
              let event = e
              event["values"]["item_id"] =
                product?.product_item_id || product?.item_id
              onUpdateEachItemSubmit(event)
            }}
            editData={editData}
          />
        )
      })}
      {renderPagination()}
    </Grid>
  )
}
