import * as React from "react"
import styled from "styled-components"
import * as StyledDOM from "./styles.js"
import * as render from "./renderProps"
import * as FIXTURE from "./fixture"
import useShekelState from "./useShekelState"

function Shekel(props) {
  const {
    list = [],
    onUpdateAddCamera = () => "",
    onUpdateAddProduct = () => "",
    onUpdateAddCell = () => "",
    renderChild = () => "",
    isEdit = false,
    gondolaTheme = true,
    isLoading = false,
    getRemainingWidth = 0,
    getPlanogramDetail,
    getIsAnyEditView,
    getIsMultipleProduct = false,
  } = props || {}
  const stateShekelProps = useShekelState({
    ...props,
    gondolaTheme,
    getRemainingWidth,
    getPlanogramDetail,
    getIsMultipleProduct,
  })

  const renderLoader = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100% - 70px)",
          background: "#00000047",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          borderRadius: "0px 0px 7px 7px",
          top: "66px",
          zIndex: "90",
        }}
      >
        <div
          style={{
            width: "300px",
            height: "80px",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            boxShadow: "0px 2px 2px #00000033",
            position: "absolute",
            left: "0",
            right: "0",
            margin: "auto",
            bottom: "0",
            top: "0",
          }}
        >
          <h3 style={{ color: "#000" }}>{` Saving Contents...`}</h3>
        </div>
      </div>
    )
  }

  return (
    <StyledDOM.StyledShekelMainWrapper
      className={`${isEdit ? "editmode" : "viewmode"} ${gondolaTheme
        ? "cls-theme-1"
        : `cls-theme-2 ${getIsMultipleProduct ? "cls-theme-2-multiproduct" : ""
        } `
        }`}
    >
      {isLoading && renderLoader()}
      {stateShekelProps?.getFetchShekelList.length > 0 ? (
        stateShekelProps?.getFetchShekelList.map(
          (currentRowItem = {}, currentRowIndex) => {
            return (
              <React.Fragment key={`row_${currentRowIndex}`}>
                {render?.renderRowItem({
                  ...stateShekelProps,
                  renderChild,
                  currentRowItem,
                  currentRowIndex,
                  getIsAnyEditView,
                })}
              </React.Fragment>
            )
          }
        )
      ) : (
        <StyledDOM.StyledShekelRowEmptyWrapper>
          {FIXTURE?.defaultEmptyRow}
        </StyledDOM.StyledShekelRowEmptyWrapper>
      )}
    </StyledDOM.StyledShekelMainWrapper>
  )
}

export { Shekel }
export default Shekel
