import React from "react"
import styled from "styled-components"
import * as render from "./renderProps"
import useFullFillmentStatus from "./useFullFillmentStatus"

const FullFillmentStatus = (props) => {
  const stateProps = useFullFillmentStatus(props)
  const { defaultList } = stateProps

  return (
    <div>
      {defaultList.length > 0 ? (
        render?.renderTrackerWrapper(stateProps)
      ) : (
        <div>{`No Tracking Status Available`}</div>
      )}
    </div>
  )
}

export { FullFillmentStatus }
export default FullFillmentStatus
