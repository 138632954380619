import React from 'react'
import { CardHeader, Typography, IconButton } from "@material-ui/core"
import {
  Edit,
  Slash,
  CheckCircleIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  CloseIcon,
} from "@iconsGallery"
import * as Utils from "@utils"
import styled from "styled-components/macro"

const OptionaTypography = styled(Typography)`
  font-size: 12px;
  padding-left: 8px;
  color: #676767;
`

const CustomHeader = (props) => {
    const {
        theme,
        classes,
        edit,
        setEdit,
        collapseOpen,
        setCollapseOpen,
        expanded,
        setExpanded,
        handleExpandClick,
        updateData,
        resetData = () => "",
        isEditMode = true,
        fetchData,
        removedFetchData,
        optional = false,
        isIntialCount,
        setIntialCount,
        setRemoveFetchData,
        setFetchData,
        isAnyChanges,
        setAnyChanges,
    } = props || {}
    
  const isSelected = Utils.JSUtils.isEmpty(fetchData) === false && isAnyChanges
  const [useData,setData] = React.useState([])
  React.useEffect(() => {
    setData(removedFetchData)
  },[removedFetchData])

    return (
        <CardHeader
            className={expanded === false ? classes.dividerBottom : ""}
            title={props.edit === true
                    ? (props.label)
                : (<div style={{ display: "flex" }}>
                    {props.label}{optional
                        ? (<OptionaTypography style={{ float: "left" }}>
                            (Optional)
                        </OptionaTypography>)
                        : ("")}
                </div>)}
                  action={
                      <>
          {props.edit ? (
            <>
              {expanded === true && isEditMode === true && (
                <IconButton
                  onClick={() => {
                    setEdit(false)
                    setIntialCount(4)
                  }}
                  style={{ marginRight: "13px" }}
                >
                  <Edit className={classes.basicIconProperty} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {expanded === true ? (
                <>
                  <IconButton
                    onClick={() => {                        
                      resetData()
                      setFetchData(useData)
                      setIntialCount(0)    
                      setEdit(true)
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    <Slash
                      style={{ color: "red" }}
                      className={classes.basicIconProperty}
                    />
                  </IconButton>
                  <IconButton
                    style={{
                      marginRight: "18px",
                      pointerEvents: `${isSelected ? "unset" : "none"}`,
                    }}
                    onClick={() => {
                    if (isSelected) {
                        updateData(fetchData)
                        setEdit(true)
                      }
                    }}
                  >
                    <CheckCircleIcon
                      style={{
                        color: `${isSelected ? "#4CAF50" : "#CCC"}`,
                      }}
                    />
                  </IconButton>
                </>
              ) : (
                ""
              )}
            </>
          )}
          {expanded === true ? (
            <IconButton onClick={handleExpandClick}>
              <ExpandLessIcon className={classes.iconUpDown} />
            </IconButton>
          ) : (
            <IconButton onClick={handleExpandClick}>
              <ExpandMoreIcon className={classes.iconUpDown} />
            </IconButton>
          )}
        </>
      }
        >
        </CardHeader>
    )
}

export default CustomHeader
export{CustomHeader}
